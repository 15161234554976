import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { FeedbackButton } from '../../Components/buttons';
import { Header } from '../../Components/Header';
import NewsListItem from './NewsListItem';
import { Post } from '../../Queries/types';
import { POSTS_QUERY, Posts } from '../../Queries/queries';
import { useQuery } from 'react-apollo';
import { Progress } from '../../Components/Progress';
import { withStyles } from '@material-ui/styles';
import { styles } from '../../styles';
import { OperatingStatuses } from './OperatingStatus';

const NewsItemsContainer = styled.div`
  padding: 0px;
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  width: 100%;
  height: calc(100% - 140px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const NewsItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 50px;
  & li {
    background: white;
  }
`;

const FeedbackContainer = styled.div`
  margin: 20px;
`;

const NoNews = styled.div`
  text-align: center;
  padding: 40px;
  /* margin-bottom: 100px; */
`;

type NewsProps = RouteComponentProps & {};

const NewsView = (props: NewsProps) => {
  let { loading, error, data } = useQuery<Posts>(POSTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  
  if (!data || data.posts == null || error) {
    data = { posts: [] };
  }
  
  return (
    <>
      <Header />

      <NewsItemsContainer>
        <OperatingStatuses />
        {loading || !data ? (
          <Progress message="Laddar nyheter..." />
          ) : data!.posts.length > 0 ? (
          <NewsItems>
            {data!.posts.map((post: Post) => (
              <NewsListItem key={post.id} post={post} {...props} />
            ))}
          </NewsItems>
        ) : (
          <>
            <NoNews>
              <h1>Välkommen!</h1>
              Kul att just du har hittat hit. Du har kommit till första versionen
              av vår efterlängtade app. Vi uppskattar all feedback vi kan få och
              ser fram emot att göra eventuella förbättringar som dyker upp efter
              just dina önskemål. Väl mött!
            </NoNews>
            <FeedbackContainer>
              <FeedbackButton
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open('https://forms.gle/NvczBo1fsxNhF1KV6', '_blank')
                }
              >
                Lämna feedback här
              </FeedbackButton>
            </FeedbackContainer>
          </>
        )}
      </NewsItemsContainer>
    </>
  );
};

export const News = withStyles(styles)(withRouter(NewsView));
//export const News = withStyles(styles)(withRouter(NewsComponent));
