import * as R from 'ramda';
import { getMonthByNumber, getDateText } from '../utils/parsers';
import { getNewSetStatus, getNewRemovedStatus } from '../utils/serviceStatus';

export interface UserState {
  __typename: string;
  session: string | null;
  authRequested: boolean;
  signActivateRequested: boolean;
  signUpdateRequested: boolean;
  signCancelRequested: boolean;
  servicesForUpdate: Array<ServiceUpdateType>;
  qrCode:string | null;
  isSameDevice: boolean;
  signReason:string | null
}

export interface AppState {
  __typename: string;
  isDev: boolean;
  isMobile: boolean;
  isIOS: boolean;
  isApp: boolean;
  personalNumber: string | null;
  selectedLocationId: number | null;
  selectedOccasion: CleaningOccasion | null;
  isSameDevice: boolean
}

export interface LocalState {
  __typename: string;
  user: UserState;
  app: AppState;
}

export type MutationResult = {
  success: boolean;
};

export type CleaningOccasion = {
  cleaningObjectId: number;
  cost: number;
  year: number;
  month: number;
  week: number | null;
  period: number;
  scheduledCleaning: boolean;
  preliminaryStartDate: string;
  completedDate: string | null;
  finished: boolean;
  taxReductionCost: number;
  occasionHasPassed: boolean;
  noServices: boolean;
  changesAllowed: boolean;
  isTimeBooked: boolean;
  startTime: string;
  workOrderId: number | null;
  taxReductionDevider: number; // 1 = 0%, 2 = 100%, 4 = 50%
  invoiceNumber: string;
  locked: boolean;
  canceled: boolean;
};

export type CleaningOccasionFormatted = CleaningOccasion & {
  monthName: string;
  preliminaryStartDateString: string;
  completedDateString: string;
};

export const mapCleaningOccasion = (occasion: CleaningOccasion) => {
  return {
    ...occasion,
    monthName: getMonthByNumber(occasion.month),
    preliminaryStartDateString: getDateText(occasion.preliminaryStartDate),
    completedDateString: getDateText(occasion.completedDate),
    startTime: occasion.startTime,
  };
};

export type CleaningObject = {
  address: string;
  city: string;
  id: number;
  postalCode: string;
  cleaningOccasions: Array<CleaningOccasion>;
};

export type Category = {
  id: number;
  name: string;
  sortOrder: number;
};

export type Service = {
  cost: number;
  count: number;
  serviceId: number;
  cleaningObjectPriceId: number | null;
  name: string;
  permanent: boolean;
  status: number | null;
  supplementService: boolean;
  visibleInApp: boolean;
  taxReductionCost: number;
  unitPrice: number;
  category: Category;
  missingPrice: boolean;
  freeText: string;
  uuid: string;
};

export type DeselectedStatus = 'Never' | 'Not this';
export type SelectedStatus = 'Always' | 'Once';
export type ServiceStatus = DeselectedStatus | SelectedStatus;

export type ServiceWithStatus = Service & {
  status: ServiceStatusType | null;
  selected: boolean;
  selectedStatus: ServiceStatus;
  onSubscription: boolean;
};

export type Invoice = {
  invoiceNumber: number;
  invoiceOcr: number;
  cleaningObjectName: string;
  invoiceDate: string;
  dueDate: string;
  taxReduction: number;
  taxReductionDecided: number | null;
  taxReductionDenied: number | null;
  vat: number;
  rounding: number;
  workCost: number;
  balance: number;
  total: number;
};

export type InvoiceDetails = Invoice & {
  link: string | null;
};

export type Person = {
  id: number;
  personalNo: string;
  firstName: string;
  lastName: string;
  companyName: string | null;
  workPhone: string | null;
  mobilePhone: string | null;
  privatePhone: string | null;
  email: string;
  address: string;
  address2: string | null;
  postalCode: string;
  city: string;
  invoiceAddress: string;
  invoiceAddress2: string | null;
  invoicePostalCode: string;
  invoiceCity: string;
  customerId: number | null;
};

export type PersonUpdateType = {
  workPhone: string | null;
  mobilePhone: string | null;
  privatePhone: string | null;
  email: string;
};

export type Post = {
  id: string;
  date: string;
  title: string;
  excerpt: string;
  content: string;
  link: string;
  featuredMediaURL: string;
};

export type OperatingStatusType = {
  messageColor: string;
  messageText: string;
  activeFrom: string;
  activeTo: string;
};

export type WpResponse = {
  data: Post;
};

export enum ServiceStatusType {
  Permanent = 1,
  Removed = 2,
  Added = 3, //Just this period
  NotSet = 4, //Used when canceling a period
  Undefined = 5,
}

export type ServiceUpdateType = {
  applyOnAllPeriods: boolean;
  serviceId: number;
  cleaningObjectPriceId: number | null;
  status: number | null;
};

export const mapServiceUpdate = R.curry(
  (
    applyOnAllPeriods: boolean,
    service: ServiceWithStatus | Service
  ): ServiceUpdateType => {
    return {
      applyOnAllPeriods,
      serviceId: service.serviceId,
      cleaningObjectPriceId: service.cleaningObjectPriceId,
      status: service.status ? service.status : ServiceStatusType.Undefined,
    };
  }
);

const isServiceSelected = (service: Service) => {
  return (
    service.status === ServiceStatusType.Added ||
    service.status === ServiceStatusType.Permanent ||
    (service.permanent &&
      (service.status === ServiceStatusType.Undefined ||
        service.status === null))
  );
};

const serviceSelectedStatus = (
  service: Service,
  isSelected: boolean
): ServiceStatus => {
  if (service.permanent && service.status === null) {
    return 'Always';
  }

  if (!isSelected) {
    return service.status === ServiceStatusType.Removed ? 'Not this' : 'Never';
  }

  return service.status === ServiceStatusType.Added ? 'Once' : 'Always';
};

export const addStatusToService = R.curry(
  (service: Service): ServiceWithStatus => {
    const selected = isServiceSelected(service);
    return {
      ...service,
      selected,
      selectedStatus: serviceSelectedStatus(service, selected),
      onSubscription: service.permanent,
    };
  }
);

export const addStatusAndSelectedToService = R.curry(
  (selected: boolean, service: Service): ServiceWithStatus => {
    return {
      ...service,
      status: null,
      selected,
      selectedStatus: serviceSelectedStatus(service, selected),
      onSubscription: false,
      missingPrice: service.missingPrice,
    };
  }
);

export const mapServicesToUpdate = (
  servicesOnOccaion: Array<ServiceWithStatus>,
  selectedServices: Array<ServiceWithStatus>
) => {
  return R.zipWith(
    (prev, next) => {
      if (next.selected) {
        return {
          applyOnAllPeriods: false,
          serviceId: prev.serviceId,
          cleaningObjectPriceId: prev.cleaningObjectPriceId,
          status: getNewSetStatus(next),
        };
      } else if (prev.selected && !next.selected) {
        return {
          applyOnAllPeriods: false,
          serviceId: prev.serviceId,
          cleaningObjectPriceId: prev.cleaningObjectPriceId,
          status: getNewRemovedStatus(next),
        };
      }
      return null;
    },
    servicesOnOccaion,
    selectedServices
  ).filter((s) => s !== null) as Array<ServiceUpdateType>;
};
