import { baseUrl } from '../endpoints';

export enum Env {
  Dev,
  Test,
  Prod,
}

export const getEnv = () => {

  if (baseUrl === 'https://test.kundapp-api.eriksfonsterputs.net') {
    return Env.Test;
  }

  if (baseUrl === 'http://dev.kundapp-api.eriksfonsterputs.net') {
    return Env.Dev;
  }

  return Env.Prod;
};
