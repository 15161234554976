import styled from 'styled-components';
import { white } from '../utils/constants/colors';

export const Card = styled.div`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: ${white};
`;
