import { authEndpoint } from '../endpoints';
import { storeTokenInCookie, getCookieByName } from '../utils/cookies';

//const baseUrl = 'https://api-minasidor-dev.eriksfonsterputs.net';
//const baseUrl = 'https://localhost:5001';
//const baseUrl = 'http://192.168.164.144:5001';

export async function requestLoginSameDevice(): Promise<{
  session: string;
  autoStartToken: string;
  message: string | null;
  qrCode:string; 
}> {
  return fetch(`${authEndpoint}/login`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }).then((response) => response.json());
}

export async function requestLogin(
  personNumber: string
): Promise<{
  session: string;
  autoStartToken: string;
  message: string | null;
  qrCode:string; 
}> {
  return fetch(`${authEndpoint}/login-other-device/${personNumber}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }).then((response) => response.json());
}

export async function requestLoginDemo(
  customerNumber: string,
  personNumber: string
): Promise<Response> {
  return fetch(`${authEndpoint}/login-demo`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ customerNumber, personNumber }),
  });
}

export async function requestSignSameDevice(
  message: string,
  reason:string | null
): Promise<{
  session: string;
  autoStartToken: string;
  message: string | null;
  qrCode:string; 
}> {
  const token = getCookieByName('token', document.cookie);
  return fetch(`${authEndpoint}/sign`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({ message,reason }),
  }).then((response) => response.json());
}

export async function requestSign(
  personalNumber: string,
  message: string,
  reason: string | null 
): Promise<{
  session: string;
  autoStartToken: string;
  message: string | null;
}> {
  const token = getCookieByName('token', document.cookie);
  return fetch(`${authEndpoint}/sign-other-device`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({ personalNumber, message,reason }),
  }).then((response) => response.json());
}

export type CollectResponse = {
  id: string | null;
  token: string | null;
  status: string;
  message: string | null;
};

export async function collectAuthRequest(
  session: string
): Promise<CollectResponse> {
  return fetch(`${authEndpoint}/collect`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ session }),
  })
    .then((response) => {
      if (response.status === 403) {
        return { id: null, token: null, status: 'complete', message: null };
      }
      return response.json();
    })
    .then((response) => {
      storeTokenInCookie(response.token);
      return response;
    });
}

export async function collectSignRequest(
  session: string
): Promise<CollectResponse> {
  return fetch(`${authEndpoint}/collect-sign`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ session }),
  }).then((response) => response.json());
}

export async function cancel(session: string | null): Promise<any> {
  return fetch(`${authEndpoint}/cancel`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ session }),
  }).catch((err) => console.error(err));
}

export async function collectAuthRequestMock(
  personalNumber: string
): Promise<{
  id: string | null;
  token: string | null;
}> {
  return fetch(`${authEndpoint}/collectMock/${personalNumber}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((response) => {
      if (response.status === 403) {
        return { id: null, token: null };
      }
      return response.json();
    })
    .then((response) => {
      storeTokenInCookie(response.token);
      return response;
    });
}

export async function refreshToken(): Promise<{
  token: string;
}> {
  const token = getCookieByName('token', document.cookie);
  return fetch(`${authEndpoint}/refresh`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      storeTokenInCookie(response.token);
      return response;
    });
}
