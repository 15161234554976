import React, { Component } from 'react';
import { collectAuthRequestMock, cancel } from '../services/bankid';
import { withRouter, RouteComponentProps } from 'react-router';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from '../styles';
import { FullScreenProgress } from '../Components/FullScreenProgress';
import { pollCollect } from '../utils/bankid';
import { withLocalState } from '../utils/withLocalState';
import { ErrorMessage } from './Sign/ErrorMessage';

const DEFAULT_ERROR_MESSAGE = 'Inloggning med BankID misslyckades';

type AuthCollectProps = RouteComponentProps &
  WithStyles<typeof styles> & {
    isApp: boolean;
    isDev: boolean;
    onAuthSuccess: (token: string) => void;
    session: string | null;
    personalNumber: string | null;
    isSameDevice: boolean;
    qrCode: string;
  };

type AuthCollectState = {
  errorMessage: string | null;
};

export const LOCAL_STORAGE_SESSION = 'SESSION';

export class AuthCollectComponent extends Component<
  AuthCollectProps,
  AuthCollectState
> {
  state = { errorMessage: null };
  constructor(props: AuthCollectProps) {
    super(props);
  }


  onError = (errorMessage: string) => {
    this.setState({ errorMessage });
  };

  onCancel = () => {
    cancel(this.props.session);
  };

  componentDidMount() {
    const { session } = this.props;
    if (session) {
      if (this.props.isDev && !this.props.isSameDevice) {
        this.collectMock();
      } else if (session) {
        this.collect(session);
      }
    }
  }

  collect = (session: string) => {
    const { onAuthSuccess, history, location } = this.props;
    pollCollect(session, 0)
      .then((data) => {
        if (data.status === 'complete' && data.token) {
          onAuthSuccess(data.token);
          history.push(`/${location.search}`);
        } else if (data.status === 'complete') {
          history.push(`/notCustomer${location.search}`);
        } else {
          this.onError(data.message!);
        }
      })
      .catch(this.onError);
  };

  collectMock = () => {
    const { onAuthSuccess, history, personalNumber } = this.props;

    collectAuthRequestMock(personalNumber!)
      .then((data) => {
        if (data.token) {
          onAuthSuccess(data.token);
          history.push(`/${history.location.search}`);
        } else {
          history.push(`/notCustomer${history.location.search}`);
        }
      })
      .catch(() => this.onError(DEFAULT_ERROR_MESSAGE));
  };

  render() {
    if (this.state.errorMessage) {
      return (
        <ErrorMessage
          redirectPath=""
          message="Inloggning med BankID misslyckades"
          hideRetry={this.props.isApp}
        />
      );
    }
    return (
      <FullScreenProgress
        message={`${
          this.props.isSameDevice
            ? 'Väntar på inloggning'
            : 'Skanna QR-kod för att logga in'
        }`}
        onClick={!this.props.isSameDevice ? this.onCancel : undefined}
        qrCode={!this.props.isSameDevice ? this.props.qrCode : undefined}
      />
    );
  }
}

type StateToProps = {
  isApp: boolean;
  isDev: boolean;
  session: string | null;
  personalNumber: string | null;
  isSameDevice: boolean;
  qrCode: string | null;
};

const AuthCollectWithLocalState = withLocalState<
  StateToProps,
  AuthCollectProps
>(AuthCollectComponent, (state) => ({
  isApp: state.app.isApp,
  isDev: state.app.isDev,
  session: state.user.session,
  personalNumber: state.app.personalNumber,
  qrCode: state.user.qrCode,
  isSameDevice: state.user.isSameDevice,
}));

export const AuthCollect = withStyles(styles)(
  withRouter(AuthCollectWithLocalState)
);
