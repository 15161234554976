import React from 'react';
import styled from 'styled-components';
import Icon from '../assets/tag.svg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const IconMark = styled.img`
  width: 90%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const NotPriced = () => {
  return (
    <Container>
      <IconMark src={Icon} />
    </Container>
  );
};
