import dayjs from 'dayjs';

export type CollectType = 'SIGN_UPDATE' | 'SIGN_CANCEL' | 'SIGN_ACTIVATE';

export const OPERATING_STATUSES_SEEN = 'OPERATING_STATUSES_SEEN';

export const clearCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach((c) => {
    try {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() - 1);
      const key = c.split('=');

      if (key[0].trim() !== OPERATING_STATUSES_SEEN) {
        document.cookie = `${
          key[0]
        }=; expires=${expirationDate.toUTCString()}; path=/`;
      }
    } catch {}
  });
};

export const clearCookieByName = (name: string) => {
  const cookies = document.cookie.split(';');
  cookies.forEach((c) => {
    try {
      const key = c.split('=');
      if (key[0].trim() === name) {
        document.cookie = `${key[0]}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      }
    } catch {}
  });
};

export const storeTokenInCookie = (token: string | null | undefined) => {
  if (token) {
    const expirationDate = dayjs().add(3, 'hour');
    document.cookie = `token=${token}; expires=${new Date(
      expirationDate.valueOf()
    ).toUTCString()}; path=/`;
  }
};

export const storeCollectCookie = () => {
  const expirationDate = dayjs().add(3, 'minute');
  document.cookie = `COLLECT=COLLECT; expires=${new Date(
    expirationDate.valueOf()
  ).toUTCString()}; path=/`;
};

export const storeCookie = (
  name: string,
  value: string,
  time: number = 1,
  unit: dayjs.OpUnitType = 'month'
) => {
  const expirationDate = dayjs().add(time, unit);
  document.cookie = `${name}=${value}; expires=${new Date(
    expirationDate.valueOf()
  ).toUTCString()}; path=/`;
};

export const getCookieByName = (name: string, cookies: string) => {
  const parts = `; ${cookies}`.split(`${name}=`);
  if (parts.length == 2) {
    return parts.pop()!.split(';').shift();
  }

  return null;
};

export const getCollectCookie = (collectType: CollectType, cookies: string) => {
  return getCookieByName(collectType, cookies);
};
