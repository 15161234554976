import * as User from './userResolvers';
import * as App from './appResolvers';
import * as R from 'ramda';
import { LocalState } from '../Queries/types';

export const resolvers = {
  Mutation: R.mergeAll([User.resolvers.Mutation, App.resolvers.Mutation])
};
export const defaults = {
  __typename: 'State',
  user: User.defaults,
  app: App.defaults
} as LocalState;
