import ApolloClient from 'apollo-boost';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import { graphqlEndpoint } from '../endpoints';
import { defaults, resolvers } from '../state';
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types';
import { getCookieByName } from './cookies';

const SCHEMA_VERSION = '2'; // Inject this so it's automatically updated
const SCHEMA_VERSION_KEY = 'apollo-schema-version';
export const APOLLO_CACHE_PERSIST = 'apollo-cache-persist';

export async function setupApollo(
  onTokenExpired: () => void
): Promise<[ApolloClient<any>, CachePersistor<NormalizedCacheObject>]> {
  const cache = new InMemoryCache();

  const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

  const client = new ApolloClient({
    uri: `${graphqlEndpoint}`,
    cache,
    clientState: {
      defaults,
      resolvers,
      cache,
    },
    request: async (operation) => {
      const token = getCookieByName('token', document.cookie);

      if (!token) {
        onTokenExpired();
        return Promise.resolve();
      } else {
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      }
    },
  });

  const persistor = new CachePersistor({
    cache,
    storage: window.localStorage as PersistentStorage<
      PersistedData<NormalizedCacheObject>
    >,
  });

  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore();
  } else {
    await persistor.purge();
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  }

  return [client, persistor];
}
