import React, { Component, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import ComingEventHeader from './ComingEventHeader';
import { white } from '../../../../utils/constants/colors';
import { appWidth } from '../../../../utils/constants/sizes';
import CancelEvent from './CancelEvent';
import { GreenButton } from '../../../../Components/buttons';
import { SaveFlash } from '../../../../Components/SaveFlash';
import { Services } from './Services/Services';
import { Error } from '../../../../Views/Error';
import {
  SUBSCRIPTION_SERVICES_MUTATION,
  SubscriptionServicesVariables,
  SubscriptionServices,
} from '../../../../Queries/mutations';
import {
  CleaningOccasion,
  ServiceWithStatus,
  ServiceUpdateType,
  mapServicesToUpdate,
  CleaningObject,
} from '../../../../Queries/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { withLocalState } from '../../../../utils/withLocalState';
import SaveSubscription from './SaveSubscription';
import { ReloadButton } from '../../../../Components/ReloadButton';
import { useMutation, ExecutionResult } from 'react-apollo';
import { Progress } from '../../../../Components/Progress';
import { ConfirmServicesDialog } from '../../../../Components/ConfirmServicesDialog';

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  bottom: 0;
  height: 84px;
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  background-color: ${white};
  z-index: 2;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
`;

const EventContent = styled.div`
  height: calc(100vh - 100px);
  padding-top: 150px;
  padding-bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #fafafa;
`;

const BottomPadding = styled.div`
  padding-top: 25px;
  height: 50px;
  border: 2px solid transparent;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  padding-top: 170px;
`;

const NoDataWrapper = styled(ProgressWrapper)`
  margin: auto;
  text-align: center;
`;

const ProgressOrNoData = ({ progress }: { progress: boolean }) => {
  if (progress) {
    return (
      <ProgressWrapper>
        <Progress message="Laddar tillfälle..." />
      </ProgressWrapper>
    );
  }
  return <NoDataWrapper>Inget data</NoDataWrapper>;
};

type ComingEventState = {
  allServicesCopy: Array<ServiceWithStatus>;
  originalAllServicesCopy: Array<ServiceWithStatus>;
  saveButtonActive: boolean;
  needsSign: boolean;
  errorMessage: string | null;
  occasionSaved: boolean;
  subscriptionSaved: boolean;
};

type ComingEventPropsFromState = {
  servicesForUpdate: Array<ServiceUpdateType>;
};

type ComingEventProps = RouteComponentProps & {
  loading: boolean;
  noData: boolean;
  allServices: Array<ServiceWithStatus>;
  selectedCleaningObject: CleaningObject | undefined;
  selectedOccasion: CleaningOccasion;
  servicesForUpdate: Array<ServiceUpdateType>;
  reload: () => void;
  signReason?: string | null
};

const SaveWithoutSign = (props: {
  saveButtonActive: boolean;
  originalServices: Array<ServiceWithStatus>;
  selectedOccasion: CleaningOccasion;
  allServices: Array<ServiceWithStatus>;
  save: (saveFn: () => Promise<ExecutionResult<SubscriptionServices>>) => void;
}) => {
  const [updateSubscriptionServices, { error }] = useMutation<
    SubscriptionServices,
    SubscriptionServicesVariables
  >(SUBSCRIPTION_SERVICES_MUTATION, {
    refetchQueries: ['CleaningObjects', 'ServicesForOccasion'],
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const { cleaningObjectId, year, period } = props.selectedOccasion;
  const additionalServices = props.allServices.filter(
    (s) => s.selected && s.visibleInApp
  );

  if (error) throw error;

  const saveSubscriptions = () => {
    props.save(() => {
      return updateSubscriptionServices({
        variables: {
          subscriptionUpdate: {
            year,
            cleaningObjectId,
            currentPeriod: period,
            services: mapServicesToUpdate(
              props.originalServices,
              props.allServices
            ),
          },
        },
      });
    });
  };

  const onConfirm = () => {
    setDialogOpen(false);
    saveSubscriptions();
  };

  return (
    <ButtonWrapper>
      <GreenButton
        fullWidth={true}
        variant="contained"
        color="primary"
        disabled={!props.saveButtonActive}
        onClick={() => {
          if (additionalServices.length > 0) {
            setDialogOpen(true);
          } else {
            onConfirm();
          }
        }}
      >
        SPARA
      </GreenButton>
      <ConfirmServicesDialog
        open={dialogOpen}
        needsSign={false}
        period={period}
        services={props.allServices.filter((s) => s.selected)}
        onAbort={() => setDialogOpen(false)}
        onConfirm={onConfirm}
      />
    </ButtonWrapper>
  );
};
class ComingEvent extends Component<ComingEventProps, ComingEventState> {
  state = {
    allServicesCopy: [],
    originalAllServicesCopy: [],
    saveButtonActive: false,
    needsSign: false,
    errorMessage: null,
    occasionSaved: false,
    subscriptionSaved: false,
  };

  onError = (errorMessage: string) => {
    this.setState({ errorMessage });
  };

  saveButtonActive = (
    allServices: Array<ServiceWithStatus>,
    originalServices: Array<ServiceWithStatus>
  ) => {
    return JSON.stringify(allServices) !== JSON.stringify(originalServices);
  };

  saveCurrentSelection = (
    saveFn: () => Promise<ExecutionResult<SubscriptionServices>>
  ) => {
    this.setState({ occasionSaved: false }, () => {
      return saveFn().then(() => {
        this.setState({
          originalAllServicesCopy: Array.from(this.state.allServicesCopy),
          saveButtonActive: false,
          needsSign: false,
          occasionSaved: true,
        });
      });
    });
  };

  doNeedSign = (
    saveButtonActive: boolean,
    services: Array<ServiceWithStatus>,
    originalServices: Array<ServiceWithStatus>
  ) => {
    if (!saveButtonActive) {
      return false;
    }

    const zip = R.zipWith(
      (s, os) => {
        return (
          (s.selected &&
            !os.selected &&
            (s.missingPrice ||
              s.selectedStatus === 'Always' ||
              s.selectedStatus === 'Never')) ||
          (s.selectedStatus === 'Once' && os.selectedStatus === 'Always') ||
          (s.selectedStatus === 'Always' && os.selectedStatus === 'Once') ||
          (s.selectedStatus === 'Always' && os.selectedStatus === 'Never') ||
          (s.selectedStatus === 'Never' && os.selectedStatus === 'Always')
        );
      },
      services,
      originalServices
    );

    return R.any((needsSign) => needsSign, zip);
  };

  toggleSelected = (service: ServiceWithStatus) => {
    const allServices =
      this.state.allServicesCopy.length > 0
        ? this.state.allServicesCopy
        : Array.from(this.props.allServices);
    const originalAllServices =
      this.state.originalAllServicesCopy.length > 0
        ? this.state.originalAllServicesCopy
        : Array.from(this.props.allServices);

    const newAllServicesCopy = allServices.map((s) => {
      if (s.serviceId === service.serviceId) {
        return {
          ...service,
          selected:
            service.selectedStatus === 'Always' ||
            service.selectedStatus === 'Once',
          selectedStatus: service.selectedStatus,
        };
      }
      return s;
    });
    const saveButtonActive = this.saveButtonActive(
      newAllServicesCopy,
      originalAllServices
    );

    this.setState({
      originalAllServicesCopy: originalAllServices,
      allServicesCopy: newAllServicesCopy,
      saveButtonActive,
      needsSign: this.doNeedSign(
        saveButtonActive,
        newAllServicesCopy,
        originalAllServices
      ),
    });
  };

  setSaveSubscription = (
    subscriptionSaved: boolean,
    originalSelectedServices: Array<ServiceWithStatus> | null,
    continouation: (() => void) | null
  ) => {
    this.setState(
      {
        subscriptionSaved,
        saveButtonActive: !subscriptionSaved,
        originalAllServicesCopy: originalSelectedServices
          ? Array.from(originalSelectedServices)
          : this.state.originalAllServicesCopy,
      },
      () => {
        if (continouation) {
          continouation();
        }
      }
    );
  };

  render() {
    const {
      selectedCleaningObject,
      selectedOccasion,
      allServices,
      reload,
      loading,
      noData,
      signReason
    } = this.props;
    const {
      allServicesCopy,
      originalAllServicesCopy,
      errorMessage,
      saveButtonActive,
      needsSign,
      occasionSaved,
    } = this.state;

    if (errorMessage) {
      return <Error error={errorMessage} route="occasion" />;
    }

    const services = allServicesCopy.length > 0 ? allServicesCopy : allServices;
    const originalAllServices =
      originalAllServicesCopy.length > 0
        ? originalAllServicesCopy
        : allServices;
    return (
      <>
        <ComingEventHeader
          selectedOccasion={selectedOccasion}
          selectedServices={services.filter((s) => s.selected)}
          selectionHasChanged={saveButtonActive}
        />
        {loading || noData ? (
          <ProgressOrNoData progress={loading} />
        ) : (
          <EventContent>
            <div>
              <Services
                services={services}
                toggleSelected={this.toggleSelected}
                occasionNumber={selectedOccasion.period}
              />
              {!selectedOccasion.locked && (
                <CancelEvent
                  selectedCleaningObject={selectedCleaningObject}
                  selectedOccasion={selectedOccasion}
                  signReason={signReason}
                />
              )}
              <ReloadButton onClick={reload} text={'Ladda om sidan'} />
              <BottomPadding />
            </div>
          </EventContent>
        )}

        <FooterContainer>
          {needsSign && saveButtonActive ? (
            <SaveSubscription
              saveButtonActive={saveButtonActive}
              originalServices={originalAllServices}
              selectedCleaningObject={selectedCleaningObject}
              selectedOccasion={selectedOccasion}
              allServices={services}
              setSaveSuscirption={this.setSaveSubscription}
              onError={this.onError}
            />
          ) : (
            <SaveWithoutSign
              saveButtonActive={saveButtonActive}
              originalServices={originalAllServices}
              selectedOccasion={selectedOccasion}
              allServices={services}
              save={(saveFn) => this.saveCurrentSelection(saveFn)}
            />
          )}
        </FooterContainer>

        <SaveFlash show={occasionSaved} message="Putstillfälle sparat!" />
      </>
    );
  }
}

export default withRouter(
  withLocalState<ComingEventPropsFromState, ComingEventProps>(
    ComingEvent,
    (state) => ({
      servicesForUpdate: state.user.servicesForUpdate,
      signReason: state.user.signReason
    })
  )
);
