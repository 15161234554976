import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { PrimaryButton } from '../../Components/buttons';
import { svgGray } from '../../utils/constants/colors';
import { RouteComponentProps } from 'react-router-dom';
import { Post } from 'Queries/types';

const CardContainer = styled.li`
  margin-bottom: 20px;
  /*-webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);*/
`;

const Card = styled.div`
  padding: 20px;

  h1 {
    line-height: 30px;
    font-weight: 900;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    margin: 20px 0;
  }
`;

const NewsItemImage = styled.div`
  height: 200px;
  @media screen and (min-width: 768px) {
    height: 300px;
  }
  @media screen and (min-width: 1024px) {
    height: 400px;
  }
  background-image: url(${(props: { bg: string }) => props.bg});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  display: ${(props: { bg: string }) =>
    props.bg ? 'block' : 'none'}; /* Do not display in case of empty */
`;

const DateDiv = styled.div`
  color: ${svgGray};
  font-size: 10px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: black;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 900;
  line-height: 30px;
`;

const Excerpt = styled.div`
  color: #333;
  margin-top: 10px;
  line-height: 1.8em;
`;

type NewsListItemProps = RouteComponentProps & {
  post: Post;
};

const NewsListItem = (props: NewsListItemProps) => {
  let img: string = '';
  if (props.post != undefined && props.post != null) {
    img = props.post.featuredMediaURL;
  }

  return (
    <CardContainer>
      <NewsItemImage bg={img} />
      <Card>
        <DateDiv>
          {new Date(props.post.date).toLocaleDateString('sv-SE')}
        </DateDiv>
        <Title>{props.post.title}</Title>
        <Excerpt>{ReactHtmlParser(props.post.excerpt)}</Excerpt>
        <PrimaryButton
          onClick={() =>
            props.history.push(`/news/${props.post.id}${props.location.search}`)
          }
        >
          Läs mer
        </PrimaryButton>
      </Card>
    </CardContainer>
  );
};

export default NewsListItem;
