import React from 'react';
import styled from 'styled-components';
import { teal, white } from '../../utils/constants/colors';
import { CleaningObject } from '../../Queries/types';
import {
  SET_APP_STATE_MUTATION,
  SetAppStateVariables,
  SetAppState,
} from '../../Queries/mutations';
import { appWidth } from '../../utils/constants/sizes';
import { useMutation } from 'react-apollo';

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  z-index: 1;
`;

// Header text

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 100%;
  background-color: ${teal};
  color: ${white};
  font-size: 21px;
  letter-spacing: 1px;
`;

// Location objects

const LocationObjectsRow = styled.div`
  display: flex;
  height: 54px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow-x: auto; /* [1] */
  -webkit-overflow-scrolling: touch; /* [4] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [5] */
`;

const LocationObjectWrapper = styled.div`
  flex-basis: ${(props: { numberOfCleaningObjects: number }) =>
    props.numberOfCleaningObjects === 1
      ? '100%'
      : props.numberOfCleaningObjects === 2
      ? '50.01%'
      : '43%'};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
`;

// Topline

const ToplineRow = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
`;

const ToplineFiller = styled.div`
  height: 1px;
  width: 35%;
  background-color: ${teal};
`;

const ToplineSelected = styled.div`
  height: 1px;
  width: 30%;
  background-color: ${(props: { selected?: boolean }) =>
    props.selected ? white : teal};
  transition: background-color 100ms ease-in-out;
`;

// Location name

const LocationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  background-color: ${teal};
  color: ${white};
  padding-bottom: 5px;
`;

const LocationNameText = styled.div`
  opacity: ${(props: { selected?: boolean }) => (props.selected ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 100ms ease-in-out;
  z-index: 33;
`;

// Bottom beak

const BottomBeakWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
`;

const BottomBeak = styled.div`
  background-color: ${teal};
  width: 32px;
  height: 32px;
  transform: rotate(45deg) skew(-25deg, -25deg);
  margin-top: -21px;
  margin-bottom: -9px;
  z-index: -1;
  opacity: ${(props: { selected?: boolean }) => (props.selected ? 1 : 0)};
  z-index: 22;
`;

type LocationObjectsProps = {
  selectedLocationId: number;
  cleaningObjects: Array<CleaningObject>;
};

export const CleaningObjects = ({
  selectedLocationId,
  cleaningObjects,
}: LocationObjectsProps) => {
  const [setAppState] = useMutation<SetAppState, SetAppStateVariables>(
    SET_APP_STATE_MUTATION
  );
  const onClick = (selectedLocationId: number) => {
    setAppState({ variables: { selectedLocationId } });
  };
  return (
    <LocationObjectsRow className={'hide-scrollbar'}>
      {cleaningObjects.map((cleaningObject: CleaningObject) => {
        const selected = selectedLocationId === cleaningObject.id;
        return (
          <LocationObjectWrapper
            numberOfCleaningObjects={cleaningObjects.length}
            key={cleaningObject.id}
          >
            <ToplineRow>
              <ToplineFiller />
              <ToplineSelected selected={selected} />
              <ToplineFiller />
            </ToplineRow>
            <LocationNameWrapper
              onClick={() => {
                onClick(cleaningObject.id);
              }}
            >
              <LocationNameText selected={selected}>
                {cleaningObject.address.toUpperCase()}
              </LocationNameText>
            </LocationNameWrapper>
            <BottomBeakWrapper>
              <BottomBeak selected={selected} />
            </BottomBeakWrapper>
          </LocationObjectWrapper>
        );
      })}
    </LocationObjectsRow>
  );
};

type TimelineHeaderProps = {
  selectedObjectId: number;
  cleaningObjects: Array<CleaningObject>;
};

export const TimelineHeader = ({
  selectedObjectId,
  cleaningObjects,
}: TimelineHeaderProps) => {
  return (
    <HeaderWrapper>
      <HeaderText>Dina putstillfällen</HeaderText>
      <CleaningObjects
        selectedLocationId={selectedObjectId}
        cleaningObjects={cleaningObjects}
      />
    </HeaderWrapper>
  );
};
