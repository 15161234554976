import React from 'react';
import { IconButton } from '@material-ui/core';
import { Block } from '@material-ui/icons';
import styled from 'styled-components';
import { darkBlue, teal } from '../utils/constants/colors';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

type AbortButtonProps = {
  onClick: () => void;
  text: string;
  darkMode?:boolean
};

export const AbortButton = ({ onClick, text, darkMode }: AbortButtonProps) => {
  return (
    <Container>
      <IconButton onClick={onClick} style={{ padding: 5 }}>
        <Block color="inherit" style={{ color: darkMode ? darkBlue :  teal }} />
      </IconButton>
      <div onClick={onClick} style={{ fontSize: '12', color: darkMode ? darkBlue : teal }}>
        {text}
      </div>
    </Container>
  );
};
