import gql from 'graphql-tag';
import {
  ServiceUpdateType,
  Service,
  CleaningOccasion,
  MutationResult,
  PersonUpdateType,
} from './types';

export interface SetAppStateVariables {
  isDev?: boolean;
  isMobile?: boolean;
  isIOS?: boolean;
  isApp?: boolean;
  selectedLocationId?: number | null;
  selectedOccasion?: CleaningOccasion | null;
  isSameDevice?: boolean;
  personalNumber?: string | null;
}

export interface SetAppState {
  setAppState: (
    isDev?: boolean,
    isMobile?: boolean,
    isIOS?: boolean,
    isApp?: boolean,
    selectedLocationId?: number | null,
    selectedOccasion?: CleaningOccasion | null,
    personalNumber?: string | null,
    isSameDevice?: boolean
  ) => void;
}

export const SET_APP_STATE_MUTATION = gql`
  mutation setAppState(
    $isDev: Boolean
    $isMobile: Boolean
    $isIOS: Boolean
    $isApp: Boolean
    $selectedLocationId: Int
    $selectedOccasion: CleaningOccasion
    $personalNumber: String
    $isSameDevice: Boolean
  ) {
    setAppState(
      isDev: $isDev
      isMobile: $isMobile
      isIOS: $isIOS
      isApp: $isApp
      selectedLocationId: $selectedLocationId
      selectedOccasion: $selectedOccasion
      personalNumber: $personalNumber
      isSameDevice: $isSameDevice
    ) @client
  }
`;

export interface SetCollectVariables {
  session?: string | null;
  authRequested?: boolean;
  signActivateRequested?: boolean;
  signUpdateRequested?: boolean;
  signCancelRequested?: boolean;
  servicesForUpdate?: Array<ServiceUpdateType>;
  qrCode?: string;
  isSameDevice?: boolean;
  signReason?: string | null;
}

export interface SetCollect {
  setCollect: (
    session?: string | null,
    authRequested?: boolean,
    signActivateRequested?: boolean,
    signUpdateRequested?: boolean,
    signCancelRequested?: boolean,
    servicesForUpdate?: Array<ServiceUpdateType>,
    qrCode?: string,
    isSameDevice?: boolean,
    signReason?: string | null
  ) => void;
}

export const SET_COLLECT_MUTATION = gql`
  mutation setCollect(
    $session: String
    $authRequested: Boolean
    $signActivateRequested: Boolean
    $signUpdateRequested: Boolean
    $signCancelRequested: Boolean
    $servicesForUpdate: [ServiceUpdateType]
    $qrCode: String
    $isSameDevice: Boolean
    $signReason: String
  ) {
    setCollect(
      session: $session
      authRequested: $authRequested
      signActivateRequested: $signActivateRequested
      signUpdateRequested: $signUpdateRequested
      signCancelRequested: $signCancelRequested
      servicesForUpdate: $servicesForUpdate
      isSameDevice: $isSameDevice
      qrCode: $qrCode
      signReason: $signReason
    ) @client
  }
`;

export interface SubscriptionUpdateType {
  year: number;
  cleaningObjectId: number;
  currentPeriod: number;
  services: Array<ServiceUpdateType>;
}

export interface SubscriptionServicesVariables {
  subscriptionUpdate: SubscriptionUpdateType;
}

export interface SubscriptionServices {
  updateSubscriptionServices: (
    subscriptionUpdate: SubscriptionUpdateType
  ) => Array<Service>;
}

export const SUBSCRIPTION_SERVICES_MUTATION = gql`
  mutation updateSubscriptionServices(
    $subscriptionUpdate: SubscriptionUpdateType!
  ) {
    updateSubscriptionServices(subscriptionUpdate: $subscriptionUpdate) {
      cost
      count
      serviceId
      cleaningObjectPriceId
      name
      permanent
      status
      supplementService
      taxReductionCost
      unitPrice
    }
  }
`;

export interface ActivateOccasionVariables {
  cleaningObjectId: number;
  period: number;
  year: number;
}

export interface ActivateOccasion {
  activateOccasion: (
    cleaningObjectId: number,
    period: number,
    year: number
  ) => MutationResult;
}

export const ACTIVATE_OCCASION_MUTATION = gql`
  mutation activateOccasion(
    $cleaningObjectId: Int!
    $period: Int!
    $year: Int!
  ) {
    activateOccasion(
      cleaningObjectId: $cleaningObjectId
      period: $period
      year: $year
    ) {
      success
    }
  }
`;

export interface UpdatePersonVariables {
  person: PersonUpdateType;
}

export interface UpdatePerson {
  updatePerson: (person: PersonUpdateType) => MutationResult;
}

export const UPDATE_PERSON_MUTATION = gql`
  mutation updatePerson($person: PersonUpdateType!) {
    updatePerson(person: $person) {
      success
    }
  }
`;

//CANCEL OCCASSION
export interface CancelOccasionVariables {
  cleaningObjectId: number;
  period: number;
  year: number;
  reason: string;
}

export interface CancelOccasion {
  cancelOccasion: (
    cleaningObjectId: number,
    period: number,
    year: number,
    reason: string
  ) => MutationResult;
}

export const CANCEL_OCCASION_MUTATION = gql`
  mutation cancelOccasion(
    $cleaningObjectId: Int!
    $period: Int!
    $year: Int!
    $reason: String!
  ) {
    cancelOccasion(
      cleaningObjectId: $cleaningObjectId
      period: $period
      year: $year
      reason: $reason
    ) {
      success
    }
  }
`;
