import React from 'react';

export const Price = ({
  value,
  decimals,
  hideSuffix
}: {
  value: number;
  decimals?: number;
  hideSuffix?: boolean;
}) => {
  const v = decimals ? value.toFixed(decimals) : value;
  return (
    <div>
      <span>{v}</span>
      {hideSuffix ? '' : ' kr'}
    </div>
  );
};
