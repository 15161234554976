import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Row, Column } from './grid';
import { EFButton } from './buttons';
import { gray, teal } from '../utils/constants/colors';

const DialogContent = styled.div`
  padding: 10px;
  padding-bottom: 20px;
`;

const DialogMessageWrapper = styled.div`
  min-height: 160px;
  padding: 20px;
`;

const DialogMessage = styled.div`
  font-size: 20px;
  letter-spacing: 0.618px;
  line-height: 1.3;
`;

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none'
  }
})(Dialog);

const DialogButtonWrapper = styled.div`
  width: 100%;
`;

type SaveCancelDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  message: string;
  needsSign?: boolean;
};

export default ({
  open,
  onConfirm,
  onClose,
  message,
  needsSign
}: SaveCancelDialogProps) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogMessageWrapper>
          <DialogMessage>{message}</DialogMessage>
        </DialogMessageWrapper>
        <Row>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingRight: 5 }}>
              <EFButton color={gray} onClick={onClose}>
                AVBRYT
              </EFButton>
            </DialogButtonWrapper>
          </Column>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingLeft: 5 }}>
              <EFButton color={teal} onClick={onConfirm} needsSign={needsSign}>
                JA
              </EFButton>
            </DialogButtonWrapper>
          </Column>
        </Row>
      </DialogContent>
    </StyledDialog>
  );
};
