import * as R from 'ramda';
import { ApolloError } from 'apollo-boost';
import { Category } from '../Queries/types';

const validDate = (number: string) => {
  const datePart = number.slice(0, 8);
  const year = parseInt(datePart.slice(0, 4));
  const month = parseInt(datePart.slice(4, 6));
  const day = parseInt(datePart.slice(6, 8));
  return (
    year > 1900 && year < 2020 && month > 0 && month < 13 && day > 0 && day < 32
  );
};

export const validPersonalNumber = (number: string): boolean => {
  if (number.length === 12) {
    return validDate(number) && !number.includes('-');
  } else if (number.length === 13) {
    return validDate(number) && number.charAt(8) === '-';
  }
  return false;
};

export const isMobileDevice = (userAgent: string) => {
  return (
    /iPad|iPhone|iPod|Android/.test(userAgent) && !(window as any).MSStream
  );
};

export const isIOSDevice = (userAgent: string) => {
  return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
};

export const getMonthByNumber = (month: number) => {
  switch (month) {
    case 1:
      return 'Januari';
    case 2:
      return 'Februari';
    case 3:
      return 'Mars';
    case 4:
      return 'April';
    case 5:
      return 'Maj';
    case 6:
      return 'Juni';
    case 7:
      return 'Juli';
    case 8:
      return 'Augusti';
    case 9:
      return 'September';
    case 10:
      return 'Oktober';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
};

export const getDateText = (date: string | null) => {
  if (date) {
    const tempDate = new Date(date);
    return `${tempDate.getDate()}/${tempDate.getMonth() + 1}`;
  }
  return '';
};

export const getCurrentYear = () => new Date().getFullYear();

export const parseError = (error: ApolloError | string | Object | null) => {
  console.error(error);
  if (error instanceof ApolloError) {
    return error.message;
  }

  if (error instanceof Object) {
    if ((error as any).message) {
      return (error as any).message;
    }

    return 'Okänt fel';
  }

  if (!error) {
    return 'Okänt fel';
  }

  return error;
};

export function getCategories<
  T extends { category: Category; name: string; count: number }
>(services: Array<T>) {
  const sortedServices = R.sortBy((s) => s.category.sortOrder, services);
  const groups = R.groupBy(
    (s) => s.category.name,
    sortedServices.filter((ss) => ss.count > 0)
  );
  const categories = R.keys(groups);
  return categories.map(
    (c) =>
      [c, R.sort((a, b) => a.name.localeCompare(b.name), groups[c])] as [
        string,
        Array<T>
      ]
  );
}

export const formatPhoneNumber = (input: string | null) => {
  if (input === null || input.length <= 0) {
    return '';
  }

  if (!input.startsWith('07')) {
    return input;
  }

  return input.slice(0, 3) + '-' + input.slice(3);
};

export const cleanPhoneNumber = (input: string | null) => {
  if (input === null) {
    return null;
  }

  if (input.trim().length === 0) {
    return null;
  }

  return input.replace('-', '').replace('0046', '0').replace('+46', '0').trim();
};

export const validMobilePhoneNumber = (input: string | null) => {
  if (!input) {
    return true;
  }

  const inputWithoutDash = input.replace('-', '');
  return (
    validPhoneNumber(input) &&
    inputWithoutDash.length === 10 &&
    inputWithoutDash.startsWith('07')
  );
};

export const validPhoneNumber = (input: string | null) => {
  if (!input) {
    return true;
  }

  return Boolean(input.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g));
};

export const validEmailAddress = (input: string | null) => {
  if (!input) {
    return false;
  }

  return Boolean(
    input.match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
  );
};
