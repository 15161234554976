import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import {
  teal,
  accentBlue,
  lightRed,
  white,
  green,
} from '../utils/constants/colors';
import BankIdIcon from '../assets/bankId.svg';
import { Feedback } from '@material-ui/icons';

const commonButtonStyles = {
  borderRadius: 2,
  border: 0,
  minHeight: 45,
};

const IconWrapper = styled.div`
  padding: 5px;
  background-color: ${white};
  position: absolute;
  left: 5px;
  border-radius: 4px;
`;

const IconStyled = styled.img`
  width: 25px;
  height: 25px;
  display: block;
`;

const stylesPrimary = () =>
  createStyles({
    button: {
      ...commonButtonStyles,
      backgroundColor: '#00a9ce',
      color: '#F7F7FA',
      'text-transform': 'none',
      '&:active': {
        backgroundColor: '#3190a5',
      },
      '&:hover': {
        backgroundColor: '#3190a5',
      },
    },
  });

const stylesGreen = () =>
  createStyles({
    button: {
      ...commonButtonStyles,
      backgroundColor: green,
      color: '#F7F7FA',
    },
  });

const stylesAccent = () =>
  createStyles({
    button: {
      ...commonButtonStyles,
      backgroundColor: accentBlue,
      color: '#F7F7FA',
    },
  });

const stylesRed = () =>
  createStyles({
    button: {
      ...commonButtonStyles,
      backgroundColor: lightRed,
      color: '#F7F7FA',
    },
  });

const stylesInvertedPrimary = () =>
  createStyles({
    button: {
      ...commonButtonStyles,
      backgroundColor: '#FFFFFF',
      color: '#094B7C',
    },
  });

const stylesTextButton = () =>
  createStyles({
    button: {
      //color: '#FFFFFF'
      color: '#094B7C',
    },
  });

const button = (props: ButtonProps & WithStyles<typeof stylesPrimary>) => {
  const { classes, ...other } = props;
  return (
    <Button {...other} className={classes.button}>
      {props.children}
    </Button>
  );
};

const bankIdButton = (
  props: ButtonProps & WithStyles<typeof stylesPrimary>
) => {
  const { classes, ...other } = props;
  return (
    <Button {...other} className={classes.button}>
      <IconWrapper>
        <IconStyled src={BankIdIcon} />
      </IconWrapper>
      {props.children}
    </Button>
  );
};

export const feedbackButton = (
  props: ButtonProps & WithStyles<typeof stylesPrimary>
) => {
  const { classes, ...other } = props;
  return (
    <Button {...other} className={classes.button}>
      <Feedback style={{ marginRight: 5 }} />
      {props.children}
    </Button>
  );
};

type ExitButtonProps = ButtonProps & {
  onClick: () => void;
};

export const exitButton = (
  props: ExitButtonProps & WithStyles<typeof stylesPrimary>
) => {
  const { classes, onClick, ...other } = props;
  return (
    <Button
      {...other}
      className={classes.button}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {window.innerWidth >= 768 && <ExitToAppIcon />}Logga ut
    </Button>
  );
};

export const PrimaryButton = withStyles(stylesPrimary)(button);
export const AccentButton = withStyles(stylesAccent)(button);
export const GreenButton = withStyles(stylesGreen)(button);
export const GreenBankIdButton = withStyles(stylesGreen)(bankIdButton);
export const RedBankIdButton = withStyles(stylesRed)(bankIdButton);
export const FeedbackButton = withStyles(stylesPrimary)(feedbackButton);
export const BankIdButton = withStyles(stylesPrimary)(bankIdButton);
export const InvertedPrimaryButton = withStyles(stylesInvertedPrimary)(button);
export const TextButton = withStyles(stylesTextButton)(button);
export const LogoutButton = withStyles(stylesPrimary)(exitButton);

type EFButtonProps = {
  color?: string;
  onClick?: () => void;
  children?: JSX.Element[] | JSX.Element | string;
  needsSign?: boolean;
  disabled?: boolean;
};

export const EFButton = ({
  color,
  onClick,
  children,
  needsSign,
  disabled,
}: EFButtonProps) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      style={{
        height: 45,
        width: '100%',
        color: 'white',
        fontSize: 15,
        fontWeight: 600,
        boxShadow: 'none',
        backgroundColor: disabled ? 'gray' : color ? color : teal,
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {needsSign && (
        <IconWrapper>
          <IconStyled src={BankIdIcon} />
        </IconWrapper>
      )}
      {children}
    </Button>
  );
};
