import React from 'react';
import styled from 'styled-components';
import { teal } from '../utils/constants/colors';
import Icon from '../assets/aterkommande.svg';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${teal};
  border: 1px solid ${teal};
  border-radius: 50%;
  opacity: ${(props: { checked: boolean }) => (props.checked ? 1 : 0)};
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const IconMark = styled.img`
  width: 65%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 3%;
  left: 0;
`;

export const Reaccuring = ({ checked }: { checked: boolean }) => {
  return (
    <Container checked={checked}>
      <IconMark src={Icon} />
    </Container>
  );
};
