import React from 'react';
import { white, grayBlue, teal } from '../utils/constants/colors';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  withStyles,
} from '@material-ui/core';
import {
  HeadsetMic,
  AccountCircle,
  EventNote,
  Home,
  Receipt,
} from '@material-ui/icons';

const MyBottomNavigation = withStyles({
  root: {
    width: '100%',
    height: '70px',
    position: 'fixed',
    bottom: 0,
    'border-top': '2px solid',
    'border-color': teal,
  },
})(BottomNavigation);

const NavActionStyles = {
  root: {
    'font-family': 'Lato, Helvetica, Arial, sans-serif',
    'font-weight': 400,
    'min-width': '65px',
    color: teal,
    '&.Mui-selected': {
      background: teal,
      color: white,
    },
  },
  label: {
    'font-size': '11px',
    'letter-spacing': '1px',
    color: grayBlue,
    '&.Mui-selected': {
      color: white,
      'font-size': '11px',
    },
  },
};

type FooterProps = RouteComponentProps & {
  isMobile: boolean;
};

const MyBottomNavigationAction = withStyles(NavActionStyles)(
  BottomNavigationAction
);

const FooterView = (props: FooterProps) => {
  // Navigation
  const history = useHistory();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    //setValue(newValue);
    history.push('/' + newValue);
    event.preventDefault();
  };
  const path = props.location.pathname.split('/')[1];

  // Styling
  let dynStyle = { width: '100%', display: 'flex' };
  if (!props.isMobile) {
    dynStyle.width = '1024px';
  }

  if (path == 'authCollect') {
    dynStyle.display = 'none';
  }

  return (
    <MyBottomNavigation
      value={path}
      onChange={handleChange}
      showLabels
      style={dynStyle}
    >
      <MyBottomNavigationAction
        label="Hem"
        value={`news${props.location.search}`}
        icon={<Home />}
      />
      <MyBottomNavigationAction
        label="Tillfällen"
        value={`timeline${props.location.search}`}
        icon={<EventNote />}
      />
      <MyBottomNavigationAction
        label="Fakturor"
        value={`invoices${props.location.search}`}
        icon={<Receipt />}
      />
      <MyBottomNavigationAction
        label="Profil"
        value={`myprofile${props.location.search}`}
        icon={<AccountCircle />}
      />
      <MyBottomNavigationAction
        label="Kontakt"
        value={`contactInfo${props.location.search}`}
        icon={<HeadsetMic />}
      />
    </MyBottomNavigation>
  );
};

export const Footer = withRouter(FooterView);
