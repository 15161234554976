import React from 'react';
import styled from 'styled-components';
import { styles } from '../styles';
import { appWidth } from '../utils/constants/sizes';
import { AbortButton } from '../Components/AbortButton';
import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import { QrCode } from './QrCode';
import Logo from '../assets/logo.jpg';

const Icon = styled.img`
  max-width: 200px;
  max-height: 200px;
  margin-top:10%;
`;
const Container = styled.div`
  position: fixed;
  top: 30%;
  width: ${appWidth}px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const CancelButtonContainer = styled.div`
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const CenteredWrapper = styled.div`
  margin: auto;
  text-align: center;
`;

type FullScreenProgressProps = WithStyles<typeof styles> & {
  message: string | null;
  onClick?: () => void;
  qrCode?: string;
};

const progress = (props: FullScreenProgressProps) => {
  const { classes, message, qrCode } = props;
  return (
    <div className={classes.root}>
              <CenteredWrapper>
          <Icon src={Logo} />
        </CenteredWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CenteredWrapper>
              <h3>{message || ''}</h3>
            </CenteredWrapper>
          </Grid>
          <Grid item xs={12}>
            <CenteredWrapper>
              {qrCode ? (
                <QrCode qrCode={qrCode} />
              ) : (
                <CircularProgress
                  size={75}
                  className={classes.progressInverted}
                />
              )}
            </CenteredWrapper>
          </Grid>
        </Grid>
        {props.onClick && (
          <CancelButtonContainer>
            <AbortButton onClick={props.onClick} text="Avbryt" />
          </CancelButtonContainer>
        )}
      </Container>
    </div>
  );
};

export const FullScreenProgress = withStyles(styles)(progress);
