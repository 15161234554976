import React, { useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';
import { Row, Column } from './grid';
import { EFButton } from './buttons';
import { darkBlue, gray, red, teal } from '../utils/constants/colors';
import TextArea from '@material-ui/core/TextareaAutosize';

const DialogContent = styled.div`
  padding: 10px;
  padding-bottom: 20px;
`;

const DialogMessageWrapper = styled.div`
  min-height: 160px;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 10px;
`;

const DialogButtonWrapper = styled.div`
  width: 100%;
`;

const DialogMessage = styled.div`
  font-size: 20px;
  letter-spacing: 0.618px;
  line-height: 1.3;
`;

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none',
  },
})(Dialog);

type ConfirmDialogProps = {
  open: boolean;
  onAbort: () => void;
  onConfirm: (reason: string) => void;
  message: string;
  needsSign: boolean;
  defaultReason?: string | null
};

export const ConfirmDialog: React.SFC<ConfirmDialogProps> = ({
  open,
  onAbort,
  onConfirm,
  message,
  needsSign,
  defaultReason
}: ConfirmDialogProps) => {
  const [reason, setReason] = useState<string>(defaultReason ? defaultReason : "");
  const onConfirmHandler = () => {
    onConfirm(reason);
  };
  // label="Ange orsak för avbokning"
  return (
    <StyledDialog open={open} onClose={onAbort}>
      <DialogContent>
        <DialogMessageWrapper>
          <DialogMessage>{message}</DialogMessage>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <label htmlFor="reason" style={{ color: darkBlue }}>
              Orsak för avbokning <span style={{ color: red }}>*</span>
            </label>
            <TextArea
              id="reason"
              maxLength={200}
              defaultValue={defaultReason ? defaultReason : ""}
              placeholder='max 200 tecken'
              style={{
                outline: 'none',
                padding:"10px"
              }}
              rowsMin={3}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
        </DialogMessageWrapper>
        <Row>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingRight: 5 }}>
              <EFButton color={gray} onClick={onAbort}>
                AVBRYT
              </EFButton>
            </DialogButtonWrapper>
          </Column>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingLeft: 5 }}>
              <EFButton
                color={teal}
                onClick={onConfirmHandler}
                needsSign={needsSign}
                disabled={reason.length < 3}
              >
                JA
              </EFButton>
            </DialogButtonWrapper>
          </Column>
        </Row>
      </DialogContent>
    </StyledDialog>
  );
};
