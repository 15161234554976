import React from 'react';
import styled from 'styled-components';
import { styles } from '../styles';
import { appWidth } from '../utils/constants/sizes';
import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import { AbortButton } from '../Components/AbortButton';
import { QrCode } from './QrCode';

const Container = styled.div`
  padding-top: 10%;
  position: fixed;
  top: 0;
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    padding-top: ${(props: { paddingTop?: string }) =>
      props.paddingTop ? props.paddingTop : '30%'};
    width: 100%;
  }
  z-index: 2000;
`;

const CancelButtonContainer = styled.div`
  position: fixed;
  width: ${appWidth}px;
  margin-top:20px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const CenteredWrapper = styled.div`
  margin: auto;
  text-align: center;
`;

type FullScreenProgressOverlayProps = WithStyles<typeof styles> & {
  message: string | null;
  paddingTop?: string;
  onClick?: () => void;
  qrCode?:string
};

const progress = (props: FullScreenProgressOverlayProps) => {
  const { classes, message, paddingTop,qrCode } = props;
  return (
    <div className="dimmed">
      <Container paddingTop={paddingTop}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CenteredWrapper>
              <h3>{message || ''}</h3>
            </CenteredWrapper>
          </Grid>
          <Grid item xs={12}>
            <CenteredWrapper>
            {qrCode ? (
                <QrCode
                  qrCode={qrCode}
                />
              ) : (
                <CircularProgress
                size={75}
                className={classes.progressInverted}
              />
              )}
            </CenteredWrapper>
          </Grid>
        </Grid>

        {props.onClick && (
          <CancelButtonContainer>
            <AbortButton onClick={props.onClick} text="Avbryt" darkMode={true} />
          </CancelButtonContainer>
        )}
      </Container>
    </div>
  );
};

export const FullScreenProgressOverlay = withStyles(styles)(progress);
