import React, { Component, Fragment } from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import * as R from 'ramda';
import { Welcome } from './Views/Welcome';
import { News } from './Views/News/News';
import { NewsItem } from './Views/News/NewsItem';
import { MyProfile } from './Views/MyProfile/MyProfile';
import { AuthCollect, LOCAL_STORAGE_SESSION } from './Views/AuthCollect';
import { Error } from './Views/Error';
import { getCookieByName, clearCookies } from './utils/cookies';
import CssBaseline from '@material-ui/core/CssBaseline';
import { History, Location } from 'history';
import ApolloClient from 'apollo-boost';
import { setupApollo } from './utils/apollo';
import { CachePersistor } from 'apollo-cache-persist';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import Timeline from './Views/Timeline/Timeline';
import { Footer } from './Components/Footer';
import EventInfo from './Views/Timeline/EventInfo/EventInfo';
import ErrorBoundary from './Components/ErrorBoundary';
import { startTokenRefreshInterval } from './utils/bankid';
import { ContactInfo } from './Views/ContactInfo/ContactInfo';
import { LogOut } from './Views/LogOut';
import { Invoices } from './Views/MyProfile/Invoices';
import { InvoiceDetails } from './Views/MyProfile/InvoiceDetails';
import { NotCustomer } from './Views/NotCustomer';
import { Throttle } from './Components/Throttle';
import UpdateCollect from './Views/Sign/UpdateCollect';
import ActivateCollect from './Views/Sign/ActivateCollect';
import CancelCollect from './Views/Sign/CancelCollect';

type AppState = {
  personalNumber: string | null;
  errorMessage: string | null;
  hasFatalError: boolean;
  returnRoute: string;
  graphqlClient: ApolloClient<any> | null;
  persistor: CachePersistor<NormalizedCacheObject> | null;
  tokenRefreshInterval: any | null;
  isMobile: boolean;
};

export class App extends Component<{}, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      personalNumber: null,
      errorMessage: null,
      hasFatalError: false,
      returnRoute: '',
      graphqlClient: null,
      persistor: null,
      tokenRefreshInterval: null,
      isMobile: this.isMobile(),
    };
  }

  conditionallyStartTokenRefresh = () => {
    if (this.state.tokenRefreshInterval) {
      clearInterval(this.state.tokenRefreshInterval);
    }

    const token = getCookieByName('token', document.cookie);
    return token ? startTokenRefreshInterval() : null;
  };

  async componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);

    try {
      const [graphqlClient, persistor] = await setupApollo(() => {
        window.location.href = `/logout${window.location.search}`;
      });
      const tokenRefreshInterval = this.conditionallyStartTokenRefresh();

      this.setState({ graphqlClient, persistor, tokenRefreshInterval });
    } catch {
      localStorage.clear();
      this.onFatalError();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    if (this.state.tokenRefreshInterval) {
      clearInterval(this.state.tokenRefreshInterval);
    }
  }

  onSuccess = (session: string, personalNumber: string | null) => {
    localStorage.setItem(LOCAL_STORAGE_SESSION, session);
    this.setState({ personalNumber });
  };

  onError = R.curry(
    (
      history: History<any>,
      location: Location<any>,
      returnRoute: string,
      errorMessage: string
    ) => {
      this.setState({ errorMessage, returnRoute });
      history.push(`/error${location.search}`);
    }
  );

  onFatalError = () => {
    clearCookies();
    this.setState({ hasFatalError: true });
  };

  onRetry = () => {
    const { graphqlClient, persistor } = this.state;

    return new Promise<void>((resolve) => {
      this.setState({ hasFatalError: false }, () => {
        clearCookies();

        if (graphqlClient && persistor) {
          persistor.pause();
          persistor.purge().then(resolve);
        } else {
          resolve();
        }
      });
    });
  };

  onAuthSuccess = () => {
    const { persistor } = this.state;

    if (persistor) {
      persistor.resume();
    }
    this.setState({
      tokenRefreshInterval: this.conditionallyStartTokenRefresh(),
    });
  };

  isApp = (search: string) => {
    const searchParam = new URLSearchParams(search).get('app');

    return searchParam !== null && searchParam === 'true';
  };

  onLogout = (location: Location<any>) => {
    const { graphqlClient, persistor } = this.state;

    const doLogout = () => {
      clearCookies();
      localStorage.removeItem(LOCAL_STORAGE_SESSION);
      window.location.href = `/${location.search}`;
    };
    if (graphqlClient && persistor) {
      persistor.pause();
      persistor.purge().then(() => {
        doLogout();
      });
    } else {
      doLogout();
    }
  };

  isMobile = () => {
    return !(window.innerWidth > 768);
  };

  onWindowResize = () => {
    return new Throttle(() => {
      this.setState({ isMobile: this.isMobile() });
    }, 200);
  };

  render() {
    const { graphqlClient } = this.state;
    if (graphqlClient === null) {
      return null;
    }
    const token = getCookieByName('token', document.cookie);

    return (
      <ErrorBoundary
        onError={this.onFatalError}
        client={graphqlClient}
        hasFatalError={this.state.hasFatalError}
      >
        <ApolloProvider client={graphqlClient!}>
          <Fragment>
            <CssBaseline />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => {
                  if (
                    (token && !props.location.state) ||
                    (props.location.state &&
                      props.location.state.fromFatalError !== true)
                  ) {
                    return <Redirect to={`/news${props.location.search}`} />;
                  }

                  return (
                    <Welcome
                      onSuccess={this.onSuccess}
                      onError={this.onError(props.history, props.location, '')}
                      isApp={this.isApp(props.location.search)}
                      fromFatalError={this.state.hasFatalError}
                      onRetry={this.onRetry}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/authCollect"
                render={(props) => (
                  <AuthCollect
                    {...props}
                    onAuthSuccess={this.onAuthSuccess}
                  />
                )}
              />
              <Route
                exact
                path="/notCustomer"
                render={(props) => <NotCustomer {...props} />}
              />
              <Route
                path="/news"
                render={(props) => {
                  if (token) {
                    return (
                      <Switch>
                        <Route exact path="/news" render={() => <News />} />
                        <Route
                          exact
                          path="/news/:postId"
                          render={({ match }) => {
                            return <NewsItem postId={match.params.postId} />;
                          }}
                        />
                      </Switch>
                    );
                  }
                  return <Redirect to={`/${props.location.search}`} />;
                }}
              />
              <Route
                exact
                path="/timeline"
                render={(props) => {
                  if (token) {
                    return (
                      <Timeline
                        onError={this.onError(
                          props.history,
                          props.location,
                          'timeline'
                        )}
                      />
                    );
                  }
                  return <Redirect to={`/${props.location.search}`} />;
                }}
              />
              <Route
                path="/occasion"
                render={(props) => {
                  if (token) {
                    return <EventInfo />;
                  }
                  return <Redirect to={`/${props.location.search}`} />;
                }}
              />

              <Route
                path="/updateOccasion"
                render={(props) => {
                  if (token) {
                    return <UpdateCollect />;
                  }
                  return <Redirect to={`/${props.location.search}`} />;
                }}
              />

              <Route
                path="/activateOccasion"
                render={(props) => {
                  if (token) {
                    return <ActivateCollect />;
                  }
                  return <Redirect to={`/${props.location.search}`} />;
                }}
              />

              <Route
                path="/cancelOccasion"
                render={(props) => {
                  if (token) {
                    return <CancelCollect />;
                  }
                  return <Redirect to={`/${props.location.search}`} />;
                }}
              />

              <Route
                path="/invoices"
                render={() => {
                  if (token) {
                    return (
                      <Switch>
                        <Route
                          exact
                          path="/invoices"
                          render={() => {
                            return <Invoices />;
                          }}
                        />
                        <Route
                          exact
                          path="/invoices/:invoiceNumber"
                          render={({ match }) => {
                            return (
                              <InvoiceDetails
                                invoiceNumber={Number(match.params.invoiceNumber)}
                              />
                            );
                          }}
                        />
                      </Switch>
                    );
                  }
                }}
              />

              <Route path="/contactInfo" component={ContactInfo} />

              <Route
                exact
                path="/myprofile"
                render={() => {
                  return <MyProfile />;
                }}
              />

              <Route
                path="/logout"
                render={(props) => (
                  <LogOut onLogout={() => this.onLogout(props.location)} />
                )}
              />
              <Route
                exact
                path="/error"
                render={() => <Error error={this.state.errorMessage} />}
              />
              <Route
                exact
                path="/fatalError"
                render={(props) => (
                  <Redirect to={`/${props.location.search}`} />
                )}
              />
            </Switch>
          </Fragment>
          <Footer isMobile={this.state.isMobile} />
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}
