import * as R from 'ramda';
import { AppState } from '../Queries/types';
import { LOCAL_STATE_QUERY } from '../Queries/queries';
import { SetAppStateVariables } from '../Queries/mutations';

const setState = (app: Partial<AppState>, cache: any) => {
  const previousState = cache.readQuery({ query: LOCAL_STATE_QUERY });
  const data = {
    ...previousState,
    app: {
      ...previousState.app,
      ...app
    }
  };

  cache.writeQuery({
    query: LOCAL_STATE_QUERY,
    data
  });

  return null;
};

export const resolvers = {
  Mutation: {
    setAppState: (_: any, variables: SetAppStateVariables, { cache }: any) => {
      return setState(R.reject((v: any) => v === undefined, variables), cache);
    }
  }
};

export const defaults = {
  __typename: 'App',
  isDev: false,
  isMobile: false,
  isIOS: false,
  isApp: false,
  personalNumber: null,
  selectedLocationId: null,
  selectedOccasion: null,
  isSameDevice: false
} as AppState;
