import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { styles } from '../styles';
import { Email, Call } from '@material-ui/icons';
import { teal } from '../utils/constants/colors';
import { PrimaryButton } from '../Components/buttons';
import Logo from '../assets/logo.jpg';

const Centered = styled.div`
  text-align: center;
`;

const WelcomeMessage = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.4em;
`;

const Icon = styled.img`
  max-width: 80%;
  max-height: 200px;
`;

const OnTopWrapper = styled.div`
  z-index: 1;
`;

const MessageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  text-align: left;
`;

const ContactWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;

const Info = styled.div`
  margin-left: 5px;
`;

const StyledAnchor = styled.a`
  color: ${teal};
`;

const BackButtonWrapper = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
`;

const BackWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  width: 100%;
`;

type NotCustomerProps = RouteComponentProps & WithStyles<typeof styles>;

class NotCustomerComponent extends Component<NotCustomerProps> {
  render() {
    const { classes, location, history } = this.props;

    return (
      <OnTopWrapper className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Centered>
              <WelcomeMessage>
                <Icon src={Logo} />
              </WelcomeMessage>
              <MessageWrapper>
                <div>
                  Vi hittade inte någon användare kopplat till ditt
                  personnummer. Om du vill ha åtkomst till en befintlig adress
                  som står på en annan person, behöver den personen kontakta
                  kundservice.
                </div>
              </MessageWrapper>
              <ContactWrapper>
                <div>
                  <InfoWrapper>
                    <Email fontSize="small" color="primary" />
                    <Info>
                      <StyledAnchor href="mailto:kundtjanst@eriksfonsterputs.se">
                        kundtjanst@eriksfonsterputs.se
                      </StyledAnchor>
                    </Info>
                  </InfoWrapper>
                </div>
                <InfoWrapper>
                  <Call fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor href="tel:0771424242">
                      0771-42 42 42
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
                <BackWrapper>
                  <BackButtonWrapper>
                    <PrimaryButton
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      onClick={() => history.push(`/logout${location.search}`)}
                    >
                      Tillbaka
                    </PrimaryButton>
                  </BackButtonWrapper>
                </BackWrapper>
              </ContactWrapper>
            </Centered>
          </Grid>
        </Grid>
      </OnTopWrapper>
    );
  }
}

export const NotCustomer = withStyles(styles)(withRouter(NotCustomerComponent));
