import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';
import { Row, Column } from './grid';
import { EFButton } from './buttons';
import { gray, lightTeal, lightRed, green } from '../utils/constants/colors';
import { ServiceWithStatus } from '../Queries/types';
import { Price } from './Price';
import { Reaccuring } from './Reaccuring';
import { Once } from './Once';
import { NotPriced } from './NotPriced';

const DialogContent = styled.div`
  padding: 10px 20px;
`;

const DialogMessageWrapper = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid ${gray};
  font-family: Lato Black, Helvetica, Arial, sans-serif;
`;

const ServiceNameWrapper = styled.div`
  padding: 0px 10px;
`;

const ServicePriceWrapper = styled.div`
  padding: 0px 10px;
  text-align: right;
`;

const NotPricedText = styled.div`
  color: ${lightRed};
  font-weight: bold;
  margin: 20px 5px;
`;

const WarningMessage = styled.div`
  margin: 20px 0px;
  padding: 10px 10px;
  background-color: ${lightTeal};
`;

const BoldText = styled.span`
  font-weight: bold;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
`;

const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
`;

const NotPricedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  letter-spacing: 0.5;
`;

const DialogButtonWrapper = styled.div`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const DialogMessage = styled.div`
  font-size: 20px;
  letter-spacing: 0.618px;
  line-height: 1.3;
`;

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none'
  }
})(Dialog);

type ConfirmServicesDialogProps = {
  open: boolean;
  needsSign: boolean;
  period: number;
  services: Array<ServiceWithStatus>;
  onAbort: () => void;
  onConfirm: () => void;
};

export const ConfirmServicesDialog: React.SFC<ConfirmServicesDialogProps> = ({
  open,
  needsSign,
  services,
  onAbort,
  onConfirm
}: ConfirmServicesDialogProps) => {
  const servicesWithPrice = services.filter(
    s => !s.missingPrice && s.visibleInApp
  );
  const servicesWithoutPrice = services.filter(
    s => s.missingPrice && s.visibleInApp
  );
  return (
    <StyledDialog open={open} onClose={onAbort}>
      <DialogMessageWrapper>
        <DialogMessage>Dina tillvalstjänster</DialogMessage>
      </DialogMessageWrapper>
      <DialogContent>
        {servicesWithPrice.map(service => (
          <Row key={service.serviceId}>
            <Column size={2}>
              {service.selectedStatus === 'Always' ? (
                <IconWrapper>
                  <Reaccuring checked={true} />
                </IconWrapper>
              ) : (
                <IconWrapper>
                  <Once checked={true} />
                </IconWrapper>
              )}
            </Column>
            <Column size={6}>
              <ServiceNameWrapper>{service.name}</ServiceNameWrapper>
            </Column>
            <Column size={4}>
              <ServicePriceWrapper>
                <Price value={service.cost} />{' '}
              </ServicePriceWrapper>
            </Column>
          </Row>
        ))}
        {servicesWithoutPrice.length > 0 && (
          <NotPricedText>Ej prissatta tillvalstjänster</NotPricedText>
        )}
        {servicesWithoutPrice.map(service => (
          <Row key={service.serviceId}>
            <Column size={2}>
              {service.selectedStatus === 'Always' ? (
                <IconWrapper>
                  <Reaccuring checked={true} />
                </IconWrapper>
              ) : (
                <IconWrapper>
                  <Once checked={true} />
                </IconWrapper>
              )}
            </Column>
            <Column size={8}>
              <ServiceNameWrapper>{service.name}</ServiceNameWrapper>
            </Column>
            <Column size={2}>
              <NotPricedContainer>
                <IconWrapper>
                  <NotPriced />
                </IconWrapper>
              </NotPricedContainer>
            </Column>
          </Row>
        ))}
        {servicesWithoutPrice.length > 0 && (
          <WarningMessage>
            <BoldText>Notera!</BoldText> Du har valt{' '}
            <BoldText>{`${servicesWithoutPrice.length === 1 ? 'en' : 'flera'} ${
              servicesWithoutPrice.length === 1 ? 'tjänst' : 'tjänster'
            } som inte är ${
              servicesWithoutPrice.length === 1 ? 'prissatt' : 'prissatta'
            }.`}</BoldText>{' '}
            Priset beror på de förutsättningar som ges för din bostad. Som t ex
            fönsterstorlek och fönstrets placering. <br />
            <br />
            <BoldText>Vill du forsätta med din beställning ändå?</BoldText>
          </WarningMessage>
        )}
        <Row>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingRight: 5 }}>
              <EFButton color={gray} onClick={onAbort}>
                AVBRYT
              </EFButton>
            </DialogButtonWrapper>
          </Column>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingLeft: 5 }}>
              <EFButton color={green} onClick={onConfirm} needsSign={needsSign}>
                JA
              </EFButton>
            </DialogButtonWrapper>
          </Column>
        </Row>
      </DialogContent>
    </StyledDialog>
  );
};
