import React, { Fragment, useState, useEffect } from 'react';

import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router';
import { Grid, TextField } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from '../styles';
import { BankIdButton, PrimaryButton, TextButton } from '../Components/buttons';
import {
  SET_APP_STATE_MUTATION,
  SET_COLLECT_MUTATION,
  SetAppState,
  SetAppStateVariables,
  SetCollect,
  SetCollectVariables,
} from '../Queries/mutations';
import { requestToAuth } from '../utils/bankid';
import { getEnv, Env } from '../utils/environment';
import { requestLoginDemo } from '../services/bankid';
import { CloseButton } from '../Components/CloseButton';
import { useMutation } from '@apollo/react-hooks';
import Personnummer from 'personnummer';

//const DEFAULT_ERROR_MESSAGE = 'Inloggning med BankID misslyckades';

const LoginContainer = styled.div`
  padding: 20px;
`;

const PersonalNumberInputWrapper = styled.div`
  width: 90%;
  margin: auto;
`;

const LoginText = styled.div`
  margin: auto;
  text-align: center;
`;

const LoginButtonWrapper = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
`;

const CloseButtonWrapper = styled.div`
  margin: auto;
  text-align: right;
`;

type LoginOtherDeviceProps = RouteComponentProps &
  WithStyles<typeof styles> & {
    onSuccess: (session: string | null, personalNumber: string | null) => void;
    onError: (errorMessage: string) => void;
    isApp: boolean;
    isMobile: boolean;
    isIOS: boolean;
  };

type LoginOtherDeviceState = {
  personalNumber: string;
  demoUserName: string;
  demoPassword: string;
  valid: boolean;
  otherDevice: boolean;
  mock: boolean;
  showDemo: boolean;
  demo: boolean;
};

const loginComponent = (props: LoginOtherDeviceProps) => {
  const requestToAuthMock = () => {
    return Promise.resolve({
      session: 'dev',
      qrCode:""
    });
  };

  const showMockLogin = () => {
    const env = getEnv();
    return env === Env.Dev || env === Env.Test;
  };

  const showDemoLogin = () => {
    return process.env.REACT_APP_DEMO_MODE === 'true';
  };

  const [state, setState] = useState<LoginOtherDeviceState>({
    personalNumber: showMockLogin() ? `${process.env.REACT_APP_DEMO_SSN}` : '',
    demoUserName: '',
    demoPassword: '',
    valid: false,
    otherDevice: false,
    mock: false,
    showDemo: showDemoLogin(),
    demo: false,
  });

  useEffect(() => {
    setState({ ...state, otherDevice: false });
  }, []);

  const valid = (text: string): boolean => {
    const year = new Date().getFullYear() - 2000;
    return (
      (text.length >= 12 || parseInt(text.slice(0, 2)) >= year) &&
      Personnummer.valid(text)
    );
  };

  const showLoginFromSameDevice = () => {
    return props.isMobile;
  };

  const onInputChanged = (personalNumber: string) => {
    if (
      personalNumber.length > 0 &&
      personalNumber == process.env.REACT_APP_DEMO_SSN
    ) {
      setState({
        ...state,
        personalNumber,
        demo: true,
        otherDevice: false,
        mock: false,
      });
      return;
    }
    setState({ ...state, personalNumber });
  };

  const onInputDemoUserNameChanged = (demoUserName: string) => {
    setState({ ...state, demoUserName });
  };

  const requestAuth = (isDev: boolean, isOtherDevice: boolean) => {
    const { isApp, isMobile, isIOS } = props;
    return isDev
      ? requestToAuthMock()
      : requestToAuth(isOtherDevice, isApp, isIOS, isMobile, 'authCollect');
  };

  const requestAuthDemo = (userName: string, personalNumber: string) => {
    return requestLoginDemo(userName, personalNumber);
  };

  const demoLoginForm = () => {
    return (
      <Fragment>
        <Grid item xs={12}>
          <LoginText>Logga in demo</LoginText>
        </Grid>
        <Grid item xs={12}>
          <PersonalNumberInputWrapper>
            <TextField
              fullWidth
              type="number"
              label="Användarnamn"
              value={state.personalNumber}
              onChange={(event) => onInputChanged(event.target.value)}
              margin="normal"
            />
          </PersonalNumberInputWrapper>
        </Grid>
        <Grid item xs={12}>
          <PersonalNumberInputWrapper>
            <TextField
              fullWidth
              type="password"
              label="Lösenord"
              value={state.demoUserName}
              onChange={(event) =>
                onInputDemoUserNameChanged(event.target.value)
              }
              margin="normal"
            />
          </PersonalNumberInputWrapper>
        </Grid>
        <Grid item xs={12}>
          {loginDemoButton('Logga in demo')}
        </Grid>
      </Fragment>
    );
  };

  const otherDeviceLoginForm = () => {
    return (
      <Fragment>
        <Grid item xs={12}>
          <CloseButtonWrapper>
            <CloseButton
              onClick={() => setState({ ...state, otherDevice: false })}
            />
          </CloseButtonWrapper>
          {state.mock ? (
            <LoginText>Logga in mock</LoginText>
          ) : (
            <LoginText>Logga in med BankID på en annan enhet</LoginText>
          )}
        </Grid>
        <Grid item xs={12}>
          <PersonalNumberInputWrapper>
            <TextField
              fullWidth
              inputMode="numeric"
              type="number"
              inputProps={{ pattern: '[0-9]*' }}
              error={!valid(state.personalNumber)}
              label="Personnummer (ååååmmddxxxx)"
              value={state.personalNumber}
              onChange={(event) => onInputChanged(event.target.value)}
              placeholder="ååååmmddxxxx"
              margin="normal"
            />
          </PersonalNumberInputWrapper>
        </Grid>
        <Grid item xs={12}>
          {loginButton(
            'Logga in',
            state.otherDevice,
          )}
        </Grid>
      </Fragment>
    );
  };

  const [setAppState] = useMutation<SetAppState, SetAppStateVariables>(
    SET_APP_STATE_MUTATION
  );
  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const loginButton = (
    text: string,
    isOtherDevice: boolean,
  ) => {
    const { isMobile, isIOS, isApp } = props;
    const isDev = state.mock;
    const authRequestResponse = (authResponse: {
      session: string;
      qrCode: string 
    }) => {
      setAppState({
        variables: {
          isMobile,
          isApp,
          isIOS,
          isDev,
          selectedLocationId: -1,
          isSameDevice: !isOtherDevice,
          personalNumber: isDev ? state.personalNumber : null
        },
      }).then(() =>
       { 
        setCollect({
          variables: {
            session: authResponse.session,
            authRequested: true,
            signActivateRequested: false,
            signUpdateRequested: false,
            signCancelRequested: false,
            qrCode: authResponse.qrCode,
            isSameDevice: !isOtherDevice,
          },
        })}
      );
    };
    const onClick = () => {
      requestAuth(isDev, isOtherDevice).then(authRequestResponse);
    };
    return (
      <LoginButtonWrapper>
      {!isOtherDevice ? (
        <BankIdButton
          fullWidth={true}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          {text}
        </BankIdButton>
      ) : (
        <TextButton
          fullWidth={true}
          color="primary"
          onClick={onClick}
        >
          {text}
        </TextButton>
      )}
    </LoginButtonWrapper>
    );
  };

  const loginDemoButton = (text: string) => {
    const { isMobile, isIOS } = props;
    const { demoUserName, personalNumber, otherDevice } = state;
    const onClick = () => {
      requestAuthDemo(demoUserName, personalNumber).then(() => {
        setAppState({
          variables: {
            isMobile,
            isApp: true,
            isIOS,
            isDev: true,
            selectedLocationId: -1,
            isSameDevice: !otherDevice,
          },
        }).then(() =>
          setCollect({
            variables: {
              session: 'dev',
              authRequested: true,
              signActivateRequested: false,
              signUpdateRequested: false,
              signCancelRequested: false,
              qrCode:"",
              isSameDevice: !otherDevice,
            },
          })
        );
      });
    };

    return (
      <LoginButtonWrapper>
        {!otherDevice ? (
          <BankIdButton
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={onClick}
          >
            {text}
          </BankIdButton>
        ) : (
          <TextButton
            fullWidth={true}
            color="primary"
            onClick={onClick}
          >
            Logga in från en annan enhet
          </TextButton>
        )}
      </LoginButtonWrapper>
    );
  };

  return (
    <LoginContainer>
      <Grid container spacing={3}>
        {state.demo ? (
          demoLoginForm()
        ) : state.otherDevice ? (
          otherDeviceLoginForm()
        ) : (
          <Fragment>
            {showMockLogin() && (
              <Grid item xs={12}>
                <LoginButtonWrapper>
                  <PrimaryButton
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setState({ ...state, otherDevice: true, mock: true })
                    }
                  >
                    Logga in mock
                  </PrimaryButton>
                </LoginButtonWrapper>
              </Grid>
            )}
            {showLoginFromSameDevice() && (
              <Grid item xs={12}>
                {loginButton('Logga in', false)}
              </Grid>
            )}
            <Grid item xs={12}>
              {loginButton("Logga in från en annan enhet",true)}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </LoginContainer>
  );
};

export const Login = withStyles(styles)(withRouter(loginComponent));
