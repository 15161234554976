import React from 'react';
import { useQuery } from 'react-apollo';
import Alert from '@material-ui/lab/Alert';
import hash from 'object-hash';
import {
  OperatingStatus as OperatingStatusQuery,
  OPERATING_STATUS_QUERY,
} from '../../Queries/queries';
import {
  getCookieByName,
  storeCookie,
  OPERATING_STATUSES_SEEN,
} from '../../utils/cookies';
import { OperatingStatusType } from '../../Queries/types';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const allreadySeen = (): Array<string> => {
  const seenStatues = getCookieByName(OPERATING_STATUSES_SEEN, document.cookie);

  if (seenStatues) {
    try {
      return JSON.parse(seenStatues) as Array<string>;
    } catch {
      return [];
    }
  }

  return [];
};

const storeAsArray = (status: OperatingStatusType) => {
  const seen = allreadySeen();
  storeCookie(
    OPERATING_STATUSES_SEEN,
    JSON.stringify(seen.concat([hash(status)]))
  );
};

const OperatingStatus = ({ status }: { status: OperatingStatusType }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <Collapse in={open}>
      <Alert
        severity={status.messageColor as 'error' | 'warning' | 'info'}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              storeAsArray(status);
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <div>{status.messageText}</div>
      </Alert>
    </Collapse>
  );
};

export const OperatingStatuses = () => {
  const { loading, error, data } = useQuery<OperatingStatusQuery>(
    OPERATING_STATUS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  if (!error && !loading && data && data.operatingStatus) {
    const messages = data.operatingStatus
      .filter((v) => !allreadySeen().find((a) => hash(v) === a))
      .map((v, i) => <OperatingStatus status={v} key={i} />);

    return <>{messages}</>;
  }
  return null;
};
