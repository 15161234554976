import React, { Component } from 'react';
import { Error } from '../Views/Error';
import ApolloClient from 'apollo-boost';
import { parseError } from '../utils/parsers';
import { sendError } from '../services/error';

const DEFAULT_ERROR_MESSAGE =
  'Ooops, ett okänt fel inträffade, prova att logga in igen';

type ErrorBoundaryState = {
  didRetry: boolean;
  errorMessage: string | null;
};

type ErrorBoundaryProps = {
  onError: () => void;
  client: ApolloClient<any>;
  hasFatalError: boolean;
};

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { didRetry: false, errorMessage: null };

  componentDidMount() {
    window.onerror = (message, source, lineno, colno, error) => {
      console.error('Uncaught error', message, source, lineno, colno, error);
      sendError(message, `${source} ${lineno} ${colno} ${error}`);
      this.setState({ errorMessage: parseError(message) }, () =>
        this.props.onError()
      );
    };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
    sendError(error, info);
    this.setState({ errorMessage: parseError(error) }, () =>
      this.props.onError()
    );

    //Call error logging service
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.hasFatalError && !this.props.hasFatalError) {
      this.setState({ didRetry: false });
    }
  }

  render() {
    if (this.props.hasFatalError && !this.state.didRetry) {
      return <Error error={DEFAULT_ERROR_MESSAGE} />;
    }
    return this.props.children;
  }
}
