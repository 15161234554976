// Main colors
export const teal = '#00a9ce';
export const lightTeal = '#daeef2';
export const lightGrayBackground = '#F7F7F7';
export const white = '#FFFFFF';
export const gradientBlue = '#094B7C';
export const pink = '#F3E5F5';

// Secondary colors
export const grayBlueHighlight = '#6B7897';
export const grayBlue = '#8C96AB';
export const green = '#60A561';
export const red = '#FF6260';
export const darkRed = '#cd302d';
export const lightRed = '#D9493A';
export const gray = '#909090';
export const lineGray = '#D8D8D8';
export const lineGrayLight = '#E2E2E2';
export const lineBlue = '#90ACC3';
export const darkBlue = '#1f2a44';
export const accentBlue = '#232a43';

// SVG colors
export const svgGray = '#474747';
export const svgLightBlue = '#E5EEF9';
export const svgWhite = white;
export const svgDarkTeal = '#049AB8';
