import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { withLocalState } from '../../utils/withLocalState';
import { ServiceUpdateType } from '../../Queries/types';
import {
  SetCollect,
  SetCollectVariables,
  SET_COLLECT_MUTATION,
  SubscriptionServices,
  SubscriptionServicesVariables,
  SUBSCRIPTION_SERVICES_MUTATION,
} from '../../Queries/mutations';
import { getCookieByName, storeCollectCookie } from '../../utils/cookies';
import { requestToSign, openBankIdApp } from '../../utils/bankid';
import { cancel } from '../../services/bankid';
import { FullScreenProgressOverlay } from '../../Components/FullScreenProgressOverlay';
import { CollectPropsFromState, pollForStartCollect } from './Common';
import { ErrorMessage } from './ErrorMessage';

type UpdateCollectPropsFromState = CollectPropsFromState & {
  servicesForUpdate: Array<ServiceUpdateType>;
};

type UpdateCollectProps = UpdateCollectPropsFromState & RouteComponentProps;

const UpdateCollect = ({
  isDev,
  isApp,
  isIOS,
  isMobile,
  session,
  selectedOccasion,
  servicesForUpdate,
  location,
  isSameDevice,
  qrCode
}: UpdateCollectProps) => {
  const [updateSubscriptionServices, { error }] = useMutation<
    SubscriptionServices,
    SubscriptionServicesVariables
  >(SUBSCRIPTION_SERVICES_MUTATION, {
    refetchQueries: ['CleaningObjects', 'ServicesForOccasion'],
  });

  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const [completed, setCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (session && selectedOccasion) {
      pollForStartCollect(
        session,
        (status) => {
          const { cleaningObjectId, year, period } = selectedOccasion;
          if (status === 'complete') {
            updateSubscriptionServices({
              variables: {
                subscriptionUpdate: {
                  year,
                  cleaningObjectId,
                  currentPeriod: period,
                  services: servicesForUpdate,
                },
              },
            }).then(() => {
              setCollect({
                variables: { session: null },
              }).then(() => setCompleted(true));
            });
          } else {
            setCollect({
              variables: { session: null },
            });
          }
        },
        (errorMessage) => {
          setErrorMessage(errorMessage);
        }
      );
    }
  }, [session]);

  useEffect(() => {
    if (!getCookieByName('COLLECT', document.cookie)) {
      if (!isDev) {
        storeCollectCookie();
        requestToSign(location.state.signMessage,null).then(
          ({ session, autoStartToken, qrCode }) => 
            setCollect({
              variables: {
                session,
                signUpdateRequested: true,
                qrCode
              },
            }).then(() => {
              if (autoStartToken && isMobile && isSameDevice) {
                if (isApp) {
                  openBankIdApp(autoStartToken, isApp, isIOS, 'sign');
                } else {
                  openBankIdApp(autoStartToken, isApp, isIOS, '');
                }
              }
            })
        );
      } else {
        setCollect({ variables: { session: 'dev' } });
      }
    }
  }, []);

  const cancelBankIdSign = () => {
    if (session) {
      cancel(session).then(() =>
        setCollect({
          variables: { session: null, signUpdateRequested: false },
        })
      );
    } else {
      setCollect({
        variables: { session: null, signUpdateRequested: false },
      });
    }
  };

  if (error) {
    return (
      <ErrorMessage message="Det gick inte att spara" redirectPath="occasion" />
    );
  }

  if (completed) {
    return <Redirect to={`/occasion${location.search}`} />;
  }

  if (errorMessage) {
    return (
      <ErrorMessage
        message="Signering med BankID misslyckades"
        redirectPath="occasion"
      />
    );
  }

  return (
    <FullScreenProgressOverlay
    message={`${isSameDevice && qrCode ? 'Väntar på signering med BankID' : 'Skanna QR-kod för att signera'}`}
    onClick={!isSameDevice? cancelBankIdSign : undefined}
    qrCode={!isSameDevice && qrCode ? 
      qrCode : undefined }
    />
  );
};

const UpdateCollectWithLocalState = withLocalState<
  UpdateCollectPropsFromState,
  UpdateCollectProps
>(UpdateCollect, (state) => ({
  session: state.user.session,
  qrCode:state.user.qrCode,
  servicesForUpdate: state.user.servicesForUpdate,
  isApp: state.app.isApp,
  isIOS: state.app.isIOS,
  isMobile: state.app.isMobile,
  personalNumber: state.app.personalNumber,
  isDev: state.app.isDev,
  selectedOccasion: state.app.selectedOccasion,
  isSameDevice: state.app.isSameDevice,
  signReason:state.user.signReason
}));

export default withRouter(UpdateCollectWithLocalState);
