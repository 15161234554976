import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';
import { Row, Column } from './grid';
import { EFButton } from './buttons';
import { gray, green, teal, darkRed } from '../utils/constants/colors';
import { ServiceStatus } from '../Queries/types';
import { Reaccuring } from './Reaccuring';
import { Once } from './Once';
import { NotPriced } from './NotPriced';

const DialogContent = styled.div`
  padding-bottom: 20px;
`;

const DialogMessageWrapper = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid ${gray};
  font-family: Lato Bold, Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-weight: 900;
`;

const DialogDetailedMessageWrapper = styled.div`
  padding: 15px 30px;
`;

const DialogButtonWrapper = styled.div`
  width: 80%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const DialogMessage = styled.div`
  font-size: 20px;
  letter-spacing: 0.618px;
  line-height: 1.3;
`;

const RadioButton = styled.span`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 5px;
  border: 2px solid ${gray};
  margin-bottom: auto;
  margin-top: auto;
  display: table-cell;
  vertical-align: middle;
`;

const RadioButtonTextWrapper = styled.div`
  display: table;
  height: 100%;
  margin-left: 10px;
`;
const RadioButtonText = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Never = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${teal};
  opacity: ${(props: { checked: boolean }) => (props.checked ? 1 : 0)};
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const RowWihtMargin = styled(Row)`
  margin-left: 30px;
  margin-top: 15px;
`;

const RowWithNotPricedInfo = styled(Row)`
  margin-left: 30px;
  margin-top: 50px;
`;

const ButtonRow = styled(Row)`
  margin-top: 20px;
`;

const NotPricedContainer = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: ${darkRed};
`;

const NotPricedIconRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const NotPricedIconWrapper = styled.div`
  height: 45px;
  width: 45px;
`;

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none'
  }
})(Dialog);

type ConfigureServiceDialogProps = {
  open: boolean;
  serviceName: string;
  occasionNumber: number;
  serviceStatus: ServiceStatus;
  missingPrice: boolean;
  onAbort: () => void;
  onConfirm: (status: ServiceStatus) => void;
};

export const ConfigureServiceDialog: React.SFC<ConfigureServiceDialogProps> = ({
  open,
  serviceName,
  occasionNumber,
  serviceStatus,
  missingPrice,
  onAbort,
  onConfirm
}: ConfigureServiceDialogProps) => {
  const [status, setStatus] = useState<ServiceStatus>('Never');
  useEffect(() => {
    setStatus(serviceStatus);
  }, [open]);

  return (
    <StyledDialog open={open} onClose={onAbort}>
      <DialogContent>
        <DialogMessageWrapper>
          <DialogMessage>Tid för utförande</DialogMessage>
        </DialogMessageWrapper>
        <Row>
          <Column size={12}>
            <DialogDetailedMessageWrapper>
              {`När vill du att vi ska putsa "${serviceName}"?`}
            </DialogDetailedMessageWrapper>
          </Column>
        </Row>
        <Row>
          <Column size={12}>
            <RowWihtMargin onClick={() => setStatus('Never')}>
              <Column size={2}>
                <RadioButton>
                  <Never checked={status === 'Never'} />
                </RadioButton>
              </Column>
              <Column size={8}>
                <RadioButtonTextWrapper>
                  <RadioButtonText>Inte denna period</RadioButtonText>
                </RadioButtonTextWrapper>
              </Column>
            </RowWihtMargin>
          </Column>
          <Column size={12}>
            <RowWihtMargin onClick={() => setStatus('Once')}>
              <Column size={2}>
                <RadioButton>
                  <Once checked={status === 'Once'} />
                </RadioButton>
              </Column>
              <Column size={8}>
                <RadioButtonTextWrapper>
                  <RadioButtonText>{`Endast putstillfälle ${occasionNumber} i år`}</RadioButtonText>
                </RadioButtonTextWrapper>
              </Column>
            </RowWihtMargin>
          </Column>
          <Column size={12}>
            <RowWihtMargin onClick={() => setStatus('Always')}>
              <Column size={2}>
                <RadioButton>
                  <Reaccuring checked={status === 'Always'} />
                </RadioButton>
              </Column>
              <Column size={8}>
                <RadioButtonTextWrapper>
                  <RadioButtonText>{`Putstillfälle ${occasionNumber} varje år`}</RadioButtonText>
                </RadioButtonTextWrapper>
              </Column>
            </RowWihtMargin>
          </Column>
        </Row>
        {missingPrice && (
          <RowWithNotPricedInfo>
            <Column size={2}>
              <NotPricedIconRow>
                <NotPricedIconWrapper>
                  <NotPriced />
                </NotPricedIconWrapper>
              </NotPricedIconRow>
            </Column>
            <Column size={10}>
              <NotPricedContainer>
                <div>Denna tjänst är ej prissatt.</div>
                <div>Vänligen läs prislistan för mer information.</div>
              </NotPricedContainer>
            </Column>
          </RowWithNotPricedInfo>
        )}
        <ButtonRow>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingRight: 5 }}>
              <EFButton color={gray} onClick={onAbort}>
                AVBRYT
              </EFButton>
            </DialogButtonWrapper>
          </Column>
          <Column size={6}>
            <DialogButtonWrapper style={{ paddingLeft: 5 }}>
              <EFButton color={green} onClick={() => onConfirm(status)}>
                KLAR
              </EFButton>
            </DialogButtonWrapper>
          </Column>
        </ButtonRow>
      </DialogContent>
    </StyledDialog>
  );
};
