import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: '#ffffff',
      color: '#333',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%'
    },
    paper: {
      padding: spacing(2),
      textAlign: 'center',
      color: palette.text.secondary
    },
    link: {
      color: '#094B7C'
    },
    container: {
      backgroundColor: '#F7F7FA',
      marginTop: '30%',
      height: '60%'
    },
    centered: {
      textAlign: 'center'
    },
    dialog: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300
    },
    dialogPaper: {
      width: '90%'
    },
    progress: {
      color: 'white'
    },
    progressInverted: {
      color: '#00a9ce'
    },
    smallRadioLabel: {
      fontSize: '0.7rem',
      fontWeight: 'normal'
    }
  });
