import React, { useState } from 'react';
import { ButtonWrapper } from './ComingEvent';
import { RedBankIdButton } from '../../../../Components/buttons';
import { CleaningOccasion, CleaningObject } from '../../../../Queries/types';
import { SetCollect, SetCollectVariables } from '../../../../Queries/mutations';
import { SET_COLLECT_MUTATION } from '../../../../Queries/mutations';
import { withRouter, RouteComponentProps } from 'react-router';
import { ConfirmDialog } from '../../../../Components/ConfirmDialog';
import { useMutation } from 'react-apollo';

type CancelEventProps = RouteComponentProps & {
  selectedCleaningObject: CleaningObject | undefined;
  selectedOccasion: CleaningOccasion;
  signReason?:string | null
};

const CancelEvent = ({
  selectedCleaningObject,
  selectedOccasion,
  history,
  signReason
}: CancelEventProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const signMessage = `Jag undertecknar härmed avbokning av putstillfälle med startvecka ${
    selectedOccasion.week
  }, år ${selectedOccasion.year} på mitt abonnemang för ${
    selectedCleaningObject!.address
  }`;
  const onConfirm = (reason: string) => {
    setCollect({
      variables: {
        session: null,
        signCancelRequested: false,
        signReason: reason,
      },
    }).then(() => {
      history.push({
        pathname: '/cancelOccasion',
        search: history.location.search,
        state: { signMessage },
      });
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <ButtonWrapper style={{ alignSelf: 'flex-end' }}>
          <RedBankIdButton
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogIsOpen(true);
            }}
          >
            AVBOKA TILLFÄLLE
          </RedBankIdButton>
        </ButtonWrapper>
        <ConfirmDialog
          open={dialogIsOpen}
          onAbort={() => {
            setDialogIsOpen(false);
          }}
          onConfirm={onConfirm}
          message={`Är du säker på att du vill avboka putstillfälle
                            med startvecka ${selectedOccasion.week}, år ${selectedOccasion.year}?`}
          needsSign={true}
          defaultReason={signReason}
        />
      </div>
    </>
  );
};

export default withRouter(CancelEvent);
