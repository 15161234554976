import React, { Fragment, useState } from 'react';
import {
  white,
  teal,
  grayBlueHighlight,
  grayBlue,
  green,
  gray,
  gradientBlue,
} from '../../utils/constants/colors';
import styled from 'styled-components';
import { Row, Column } from '../../Components/grid';
import { Card } from '../../Components/Card';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import {
  CleaningObject,
  CleaningOccasion,
  mapCleaningOccasion,
  CleaningOccasionFormatted,
} from '../../Queries/types';
import Icon from '../../assets/head-icon.png';
import {
  SET_COLLECT_MUTATION,
  SET_APP_STATE_MUTATION,
  SetAppState,
  SetAppStateVariables,
  SetCollect,
  SetCollectVariables,
} from '../../Queries/mutations';
import { withRouter, RouteComponentProps } from 'react-router';
import SaveCancelDialog from '../../Components/SaveCancelDialog';
import { ReloadButton } from '../../Components/ReloadButton';
import { useMutation } from 'react-apollo';
import { ExecutionResult } from 'graphql';
import { HeadsetMic } from '@material-ui/icons';
import InfoDialog from '../../Components/InfoDialog';

// List descriptions

const EventDescriptionArrow = styled.div`
  letter-spacing: 0.75px;
  font-size: 12px;
  color: black;
`;

const EventDescriptionArrowUp = styled(EventDescriptionArrow)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -14px;
  margin-bottom: -3px;
`;

const EventDescriptionArrowDown = styled(EventDescriptionArrow)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  padding-top: 10px;
`;

const TaxLegend = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 6px;
  padding-top: 10px;
  color: ${grayBlue};
`;

// Card content

const ColumnLabel = styled.div`
  text-align: ${(props: { textAlign?: string }) =>
    props.textAlign ? props.textAlign : 'inherit'};
  font-size: 12px;
  letter-spacing: 0.5px;
  color: ${grayBlue};
`;

const ExtraCleaning = styled.div`
  text-align: ${(props: { textAlign?: string }) =>
    props.textAlign ? props.textAlign : 'inherit'};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${grayBlue};
`;

const StyledColumnLabel = styled(ColumnLabel)`
  opacity: ${(props: { unbooked: boolean }) => (props.unbooked ? 0.5 : 1)};
`;

const BookLabel = styled.div`
  text-align: ${(props: { textAlign?: string }) =>
    props.textAlign ? props.textAlign : 'inherit'};
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #004c7e;
`;

const BookLabelWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WeekNumber = styled.div`
  text-align: center;
  font-size: 30px;
  letter-spacing: 1px;
  color: ${grayBlueHighlight};
  margin-top: -3px;
  opacity: ${(props: { unbooked: boolean }) => (props.unbooked ? 0.5 : 1)};
`;

const WeekNumberAnnotation = styled.span`
  text-align: right;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${grayBlueHighlight};
  margin-top: -3px;
  opacity: ${(props: { unbooked: boolean }) => (props.unbooked ? 0.5 : 1)};
`;


const BookedTime = styled.div`
  text-align: left;
  font-size: 22px;
  letter-spacing: 1px;
  color: ${grayBlueHighlight};
  margin-top: 1px;
  margin-left: -3px;
`;

const PriceButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 4px;
  margin-left: 10%;
  margin-right: 10%;
`;

const PriceButton = styled.button`
  height: 18px;
  border-radius: 15px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${white};
  font-size: 13px;
  background-color: ${(props: { completed?: boolean }) =>
    props.completed ? gray : gradientBlue};
  border: none;
  letter-spacing: 0.5px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const CanceledButton = styled(PriceButton)`
  background-color: rgb(255, 0, 0);
`;

const PriceAfterTax = styled(PriceButton)`
  margin-top: 2px;
  background-color: ${(props: { completed?: boolean }) =>
    props.completed ? gray : green};
`;

const CardWithSetOccasionMutation: React.FunctionComponent<EventCardProps> = (
  props
) => {
  const [bookDialogIsOpen, setBookDialogIsOpen] = useState(false);
  const [contactDialogIsOpen, setContactDialogIsOpen] = useState(false);
  const { cleaningObject, occasion, completed, ongoing, history } = props;

  const onSelected = (
    mutateFn: () => Promise<ExecutionResult<SetAppState>>
  ) => {
    //const { history } = props;
    return () => {
      //const { occasion } = props;
      if (!(completed && !occasion.finished)) {
        // Unused event, no good way to handle this yet
        if (!occasion.changesAllowed && !occasion.workOrderId) {
          setContactDialogIsOpen(true);
        } else if (occasion.completedDate === null && occasion.cost === 0) {
          mutateFn().then(() => setBookDialogIsOpen(true));
        } else {
          mutateFn().then(() =>
            history.push(`/occasion${history.location.search}`)
          );
        }
      }
    };
  };

  const dialogMessage = `Vill du boka putstillfälle med start vecka ${occasion.week}, år ${occasion.year}?`;
  const signMessage = `Jag undertecknar härmed aktivering av vecka ${
    occasion.week
  } ${occasion.year} på mitt abonnemang för ${cleaningObject!.address}`;

  const [setAppState] = useMutation<SetAppState, SetAppStateVariables>(
    SET_APP_STATE_MUTATION
  );

  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const setSelected = () => {
    return setAppState({
      variables: { selectedOccasion: occasion },
    }) as Promise<ExecutionResult<SetAppState>>;
  };

  const confirmSign = () => {
    setBookDialogIsOpen(false);
    setCollect({
      variables: {
        session: null,
        signActivateRequested: false,
      },
    }).then(() =>
      props.history.push({
        pathname: '/activateOccasion',
        search: props.history.location.search,
        state: { signMessage },
      })
    );
  };

  return (
    <>
      <StyledEventCard
        unbooked={occasion.cost === 0}
        completed={completed}
        ongoing={ongoing}
        onClick={onSelected(setSelected)}
      >
        {props.children}
      </StyledEventCard>

      <SaveCancelDialog
        onClose={() => setBookDialogIsOpen(false)}
        onConfirm={confirmSign}
        open={bookDialogIsOpen}
        message={dialogMessage}
        needsSign={true}
      />
      <InfoDialog
        onClose={() => setContactDialogIsOpen(false)}
        open={contactDialogIsOpen}
        message="Det kan dessvärre vara för sent att boka detta putstillfälle. Vänligen kontakta kundservice om möjlighet att boka."
      />
    </>
  );
};

const StyledEventCard = styled(Card)`
  opacity: 1;
  border: ${(props: {
    completed: boolean;
    ongoing: boolean;
    unbooked: boolean;
  }) => (props.ongoing ? '2px solid ' + teal : null)};
`;

type EventCardProps = RouteComponentProps & {
  cleaningObject: CleaningObject;
  occasion: CleaningOccasionFormatted;
  completed: boolean;
  ongoing: boolean;
  next: boolean;
  onError: (error: string) => void;
};

const getDateLabel = (
  occasion: CleaningOccasionFormatted,
  completed: boolean
) => {
  if (completed && occasion.completedDateString !== '') {
    return (
      <Fragment>
        <StyledColumnLabel unbooked={occasion.cost === 0} textAlign="center">
          Datum
        </StyledColumnLabel>
        <WeekNumber unbooked={occasion.cost === 0}>
          {occasion.completedDateString}
        </WeekNumber>
      </Fragment>
    );
  }

  if (occasion.isTimeBooked) {
    return (
      <Fragment>
        <StyledColumnLabel unbooked={occasion.cost === 0} textAlign="center">
          Datum
        </StyledColumnLabel>
        <WeekNumber unbooked={occasion.cost === 0}>
          {occasion.preliminaryStartDateString}
        </WeekNumber>
      </Fragment>
    );
  }

  if (completed && occasion.occasionHasPassed) {
    return (
      <Fragment>
        <StyledColumnLabel unbooked={occasion.cost === 0} textAlign="center">
          Vecka
        </StyledColumnLabel>
        <WeekNumber unbooked={occasion.cost === 0}>{occasion.week}</WeekNumber>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <StyledColumnLabel unbooked={occasion.cost === 0} textAlign="center">
        Periodstart
      </StyledColumnLabel>
      <WeekNumber unbooked={occasion.cost === 0}><WeekNumberAnnotation unbooked={occasion.cost === 0}>v.</WeekNumberAnnotation>{occasion.week}</WeekNumber>
    </Fragment>
  );
};

const EventCard = withRouter((props: EventCardProps) => {
  const { occasion, completed, ongoing } = props;
  return (
    <CardWithSetOccasionMutation {...props}>
      <Row>
        <Column size={3}>{getDateLabel(occasion, completed)}</Column>
        <Column size={1} />
        <Column size={4}>
          <div style={{ paddingLeft: 10 }}>
            <StyledColumnLabel unbooked={occasion.cost === 0} textAlign="left">
              {occasion.year}
            </StyledColumnLabel>
            {occasion.isTimeBooked && !completed ? (
              <BookedTime>{occasion.startTime}</BookedTime>
            ) : (
              <StyledColumnLabel
                unbooked={occasion.cost === 0}
                textAlign="left"
              >
                {occasion.monthName}
                {!occasion.isTimeBooked && occasion.scheduledCleaning && (
                  <ExtraCleaning>Extraputs</ExtraCleaning>
                )}
              </StyledColumnLabel>
            )}
          </div>
        </Column>
        {occasion.cost === 0 && !completed ? (
          <Column size={4}>
            <BookLabelWrapper>
              {occasion.changesAllowed ? (
                <BookLabel textAlign="center">KLICKA FÖR ATT BOKA</BookLabel>
              ) : (
                <BookLabel textAlign="center">
                  <HeadsetMic htmlColor={teal} fontSize="large" />
                </BookLabel>
              )}
            </BookLabelWrapper>
          </Column>
        ) : (completed && occasion.finished) ||
          !occasion.occasionHasPassed ||
          (ongoing && !occasion.canceled) ? ( // State can be completed but not finished (unused)
          <Column size={4}>
            <ColumnLabel textAlign="center">
              <>Ditt pris</>
            </ColumnLabel>
            <PriceButtonWrapper>
              <PriceButton completed={completed}>{occasion.cost}kr</PriceButton>
              <PriceAfterTax completed={completed}>
                {occasion.taxReductionCost}kr
              </PriceAfterTax>
            </PriceButtonWrapper>
          </Column>
        ) : (
          occasion.canceled && (
            <Column size={4}>
              <PriceButtonWrapper>
                <CanceledButton>Avbokad</CanceledButton>
              </PriceButtonWrapper>
            </Column>
          )
        )}
      </Row>
    </CardWithSetOccasionMutation>
  );
});

// Status circles

const CircleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const CheckMark = styled.div`
  position: absolute;
  margin-top: -27px;
  margin-left: -16px;
  width: 6px;
  height: 12px;
  border-bottom: 2px solid ${gradientBlue};
  border-right: 2px solid ${gradientBlue};
  transform: rotate(45deg);
`;

const ComingCircle = styled.div`
  margin-left: -20px;
  margin-top: -20px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${white};
  border: 1px solid ${teal};
`;

const IconMark = styled.div`
  position: absolute;
  margin-top: -20px;
  margin-left: -24px;
  width: 30px;
  height: 30px;
  background-image: url(${Icon});
  background-size: cover;
  background-repeat: no-repeat;
`;

// Line

const LineColumnWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-left: -10px;
  position: absolute;
  top: 0;
  left: 0;
`;

type LineBoxProps = {
  borderRight?: boolean;
  borderLeft?: boolean;
};

const LineBox = styled.div`
  flex-basis: 50%;
  border-right: 1px solid
    ${(props: LineBoxProps) =>
      props.borderRight === true ? teal : 'transparent'};
  border-left: 1px solid
    ${(props: LineBoxProps) =>
      props.borderLeft === true ? teal : 'transparent'};
`;

type LineColumnProps = {
  showTopLine?: boolean;
  showBottomLine?: boolean;
  circleIcon?: JSX.Element;
};

const LineColumn = ({
  showTopLine,
  showBottomLine,
  circleIcon,
}: LineColumnProps) => {
  return (
    <LineColumnWrapper>
      <LineWrapper>
        <LineBox
          borderRight={showTopLine === true}
          style={{ height: 'calc(50% - 7.5px)' }}
        />
        <LineBox
          borderLeft={showTopLine === true}
          style={{ height: 'calc(50% - 7.5px)' }}
        />
        <LineBox
          borderRight={showBottomLine === true}
          style={{ height: 'calc(50% + 7.5px)' }}
        />
        <LineBox
          borderLeft={showBottomLine === true}
          style={{ height: 'calc(50% + 7.5px)' }}
        />
      </LineWrapper>
      <CircleWrapper>{circleIcon}</CircleWrapper>
    </LineColumnWrapper>
  );
};

// Events

type EventsProps = {
  cleaningObject: CleaningObject;
  occasions: Array<CleaningOccasionFormatted>;
  completed: boolean;
  ongoing: boolean;
  completedHasOccasions: boolean;
  comingHasOccasions: boolean;
  ongoingHasOccasions: boolean;
  onError: (error: string) => void;
};

const getNextOccasionIndex = (
  completed: boolean,
  occasions: Array<CleaningOccasion>
): number | null => {
  if (completed) {
    return null;
  }

  return occasions.reduce((result: number | null, current, index) => {
    if (result != null) {
      return result;
    }

    if (current.cost > 0) {
      return index;
    }

    return null;
  }, null);
};

const TaxLegendFirstItem = styled.span`
  margin-right: 10px;
`;

const WithoutTax = styled.span`
  display: inline-block;
  vertical-align: middle;
  background-color: ${gradientBlue};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`;

const WithTax = styled(WithoutTax)`
  background-color: ${green};
`;

const Events = ({
  cleaningObject,
  occasions,
  completed,
  ongoing,
  completedHasOccasions,
  comingHasOccasions,
  ongoingHasOccasions,
  onError,
}: EventsProps) => {
  const nextOccasionIndex = getNextOccasionIndex(completed, occasions);
  return (
    <>
      {ongoing && ongoingHasOccasions && (
        <Row>
          <Column size={1}>
            <LineColumn
              showTopLine={completedHasOccasions}
              showBottomLine={completedHasOccasions}
            />
          </Column>
          <Column size={4}>
            <EventDescriptionArrowDown>
              <ArrowDownward fontSize="inherit" style={{ fontSize: 13 }} />
              &nbsp; Pågående
            </EventDescriptionArrowDown>
          </Column>
          <Column size={7}>
            <TaxLegend>
              <TaxLegendFirstItem>
                <WithoutTax />
                <span>&nbsp;-&nbsp;</span>
                <span>Utan RUT</span>
              </TaxLegendFirstItem>
              <span>
                <WithTax />
                <span>&nbsp;-&nbsp;</span>
                <span>Med RUT</span>
              </span>
            </TaxLegend>
          </Column>
        </Row>
      )}
      {!completed && !ongoing && comingHasOccasions && (
        <Row>
          <Column size={1}>
            <LineColumn
              showTopLine={completedHasOccasions || ongoingHasOccasions}
              showBottomLine={completedHasOccasions || ongoingHasOccasions}
            />
          </Column>
          <Column size={4}>
            <EventDescriptionArrowDown>
              <ArrowDownward fontSize="inherit" style={{ fontSize: 13 }} />
              &nbsp; Kommande
            </EventDescriptionArrowDown>
          </Column>
          <Column size={7}>
            <TaxLegend>
              <TaxLegendFirstItem>
                <WithoutTax />
                <span>&nbsp;-&nbsp;</span>
                <span>Utan RUT</span>
              </TaxLegendFirstItem>
              <span>
                <WithTax />
                <span>&nbsp;-&nbsp;</span>
                <span>Med RUT</span>
              </span>
            </TaxLegend>
          </Column>
        </Row>
      )}
      {occasions.map((occasion: CleaningOccasionFormatted, index: number) => {
        const showTopLine =
          index > 0 ||
          (ongoing && completedHasOccasions) ||
          (!completed &&
            !ongoing &&
            (completedHasOccasions || ongoingHasOccasions));

        const hideBottomLine =
          ((completed === false && ongoing === false) ||
            (completed === true && !comingHasOccasions && !ongoingHasOccasions)) &&
          occasions.length === index + 1;

        let circleIcon = (() => {
          if (completed === true) {
            return (
              <Fragment>
                <ComingCircle />
                {occasion.finished && <CheckMark />}
              </Fragment>
            );
          } else if (
            completed === false &&
            index === 0 &&
            !ongoingHasOccasions
          ) {
            return (
              <Fragment>
                <IconMark id="NEXT_OCCASION" />
              </Fragment>
            );
          } else if (ongoing && index === 0 && ongoingHasOccasions) {
            return (
              <Fragment>
                <IconMark id="NEXT_OCCASION" />
              </Fragment>
            );
          } else {
            return <ComingCircle />;
          }
        })();

        return (
          <Row key={index}>
            <Column size={1}>
              <LineColumn
                showTopLine={showTopLine}
                showBottomLine={!hideBottomLine}
                circleIcon={circleIcon}
              />
            </Column>
            <Column size={11}>
              <EventCard
                cleaningObject={cleaningObject}
                occasion={occasion}
                next={index === nextOccasionIndex}
                completed={completed}
                ongoing={ongoing}
                onError={onError}
              />
            </Column>
          </Row>
        );
      })}
      {completed === true && !ongoing && occasions.length > 0 && (
        <Row>
          <Column size={1}>
            {comingHasOccasions === false && <LineColumn />}
          </Column>
          <Column size={11}>
            <EventDescriptionArrowUp>
              Genomförda&nbsp;
              <ArrowUpward fontSize="inherit" style={{ fontSize: 13 }} />
            </EventDescriptionArrowUp>
          </Column>
        </Row>
      )}
    </>
  );
};

// Event list

const EventListContainer = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 110px;
  height: calc(100% - 180px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #fafafa;
`;

const NoEvents = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

type EventListProps = {
  cleaningObject: CleaningObject | undefined;
  reload: () => void;
  onError: (error: string) => void;
};

const filterOccasions = (
  cleaningObject: CleaningObject | undefined,
  predicate: (occasion: CleaningOccasion) => boolean
) => {
  if (!cleaningObject) {
    return [];
  }
  return cleaningObject.cleaningOccasions
    .filter(predicate)
    .map(mapCleaningOccasion);
};

const EventList = (props: EventListProps) => {
  const { cleaningObject, reload, onError } = props;

  const ongoing = filterOccasions(
    cleaningObject,
    (o) =>
      !o.finished && o.occasionHasPassed && o.workOrderId != null && !o.canceled
  );
  
  let completed = filterOccasions(
    cleaningObject,
    (o) => o.finished || o.occasionHasPassed && !(!o.finished && o.occasionHasPassed && o.workOrderId != null && !o.canceled)
  );
  completed.sort((a, b) => a.month - b.month);

  const coming = filterOccasions(
    cleaningObject,
    (o) => !o.finished && !o.occasionHasPassed
  );

  return (
    <>
      <EventListContainer>
        {cleaningObject !== undefined &&
        (completed.length > 0 || coming.length > 0 || ongoing.length > 0) ? (
          <>
            <Events
              cleaningObject={cleaningObject}
              completed={true}
              ongoing={false}
              occasions={completed}
              completedHasOccasions={completed.length > 0}
              comingHasOccasions={coming.length > 0}
              ongoingHasOccasions={ongoing.length > 0}
              onError={onError}
            />
            <Events
              cleaningObject={cleaningObject}
              completed={false}
              ongoing={true}
              occasions={ongoing}
              completedHasOccasions={completed.length > 0}
              comingHasOccasions={coming.length > 0}
              ongoingHasOccasions={ongoing.length > 0}
              onError={onError}
            />
            <Events
              cleaningObject={cleaningObject}
              completed={false}
              ongoing={false}
              occasions={coming}
              completedHasOccasions={completed.length > 0}
              comingHasOccasions={coming.length > 0}
              ongoingHasOccasions={ongoing.length > 0}
              onError={onError}
            />
            <ReloadButton onClick={reload} text={'Ladda om sidan'} />
          </>
        ) : (
          <NoEvents>Ännu inget att visa.</NoEvents>
        )}
      </EventListContainer>
    </>
  );
};

export default EventList;
