import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import { ApolloError } from 'apollo-boost';
import { PersonUpdateType } from '../../Queries/types';
import {
  validMobilePhoneNumber,
  validEmailAddress,
  formatPhoneNumber,
  validPhoneNumber,
} from '../../utils/parsers';
import { Title } from '../../Components/Common';
import { Progress } from '../../Components/Progress';
import { red } from '../../utils/constants/colors';
import { withStyles } from '@material-ui/styles';

const Container = styled.div`
  width: 100%;
  margin: 100px auto 10px auto;
  height: calc(100% - 170px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #fafafa;
`;

const EditContainer = styled.div`
  padding: 20px;
`;

const Message = styled.div`
  padding: 10px 0;
  font-weight: bold;
`;

const ErrorMessage = styled.div`
  color: ${red};
`;

const ProgressContainer = styled.div`
  width: 100%;
  margin: auto;
`;

type EditProfileProps = {
  person: PersonUpdateType;
  onSave: (person: PersonUpdateType) => void;
  isUpdating: boolean;
  error?: ApolloError;
};

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none',
    width: '100%',
  },
})(Dialog);

const InputDialog: React.SFC<{
  title: string;
  open: boolean;
  valid: boolean;
  onCancel: () => void;
  onClose: () => void;
}> = ({ title, open, valid, onCancel, onClose, children }) => {
  return (
    <StyledDialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          AVBRYT
        </Button>
        <Button disabled={!valid} onClick={onClose} color="primary">
          SPARA
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const emtpyIfNull = (input: string | null) => (input ? input : '');

export const EditProfile = ({
  person,
  onSave,
  isUpdating,
  error,
}: EditProfileProps) => {
  const [personData, setPersonData] = useState(person);
  const [dialogState, setDialogState] = useState({
    privatePhone: false,
    workPhone: false,
    mobilePhone: false,
    email: false,
  });

  useEffect(() => {
    setPersonData(person);
  }, [person]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonData({ ...personData, [e.target.name]: e.target.value });
  };

  const handleCancel = (name: keyof PersonUpdateType) => {
    setPersonData({ ...personData, [name]: person[name] });
    setDialogState({ ...dialogState, [name]: false });
  };

  const handleSave = (name: keyof PersonUpdateType) => {
    setDialogState({ ...dialogState, [name]: false });
    onSave(personData);
  };

  if (isUpdating) {
    return (
      <ProgressContainer>
        <Progress message="Sparar..." />
      </ProgressContainer>
    );
  }

  return (
    <Container>
      <Title>Ändra kunduppgifter</Title>

      <EditContainer>
        {error && (
          <ErrorMessage>Det gick inte att spara ändringarna</ErrorMessage>
        )}

        <TextField
          inputProps={{
            readOnly: true,
          }}
          fullWidth
          label="Hem"
          value={formatPhoneNumber(personData.privatePhone)}
          placeholder="hem"
          margin="normal"
          onClick={() => setDialogState({ ...dialogState, privatePhone: true })}
        />

        <InputDialog
          open={dialogState.privatePhone}
          title="Ange hemnummer"
          valid={validPhoneNumber(personData.privatePhone)}
          onCancel={() => handleCancel('privatePhone')}
          onClose={() => handleSave('privatePhone')}
        >
          <TextField
            fullWidth
            type="tel"
            name="privatePhone"
            label="Hem"
            error={!validPhoneNumber(personData.privatePhone)}
            value={emtpyIfNull(personData.privatePhone)}
            onChange={handleChange}
            placeholder="hem"
            margin="normal"
            autoFocus
          />
        </InputDialog>

        <TextField
          inputProps={{
            readOnly: true,
          }}
          fullWidth
          label="Arbete"
          value={formatPhoneNumber(personData.workPhone)}
          placeholder="arbete"
          margin="normal"
          onClick={() => setDialogState({ ...dialogState, workPhone: true })}
        />

        <InputDialog
          open={dialogState.workPhone}
          title="Ange nummer till arbetet"
          valid={validPhoneNumber(personData.workPhone)}
          onCancel={() => handleCancel('workPhone')}
          onClose={() => handleSave('workPhone')}
        >
          <TextField
            fullWidth
            type="tel"
            name="workPhone"
            label="Arbete"
            error={!validPhoneNumber(personData.workPhone)}
            value={emtpyIfNull(personData.workPhone)}
            onChange={handleChange}
            placeholder="arbete"
            margin="normal"
            autoFocus
          />
        </InputDialog>

        <TextField
          inputProps={{
            readOnly: true,
          }}
          fullWidth
          label="Mobil (SMS vid utförd puts)"
          value={formatPhoneNumber(personData.mobilePhone)}
          placeholder="mobil"
          margin="normal"
          onClick={() => setDialogState({ ...dialogState, mobilePhone: true })}
        />

        <InputDialog
          open={dialogState.mobilePhone}
          title="Ange mobilnummer"
          valid={validMobilePhoneNumber(personData.mobilePhone)}
          onCancel={() => handleCancel('mobilePhone')}
          onClose={() => handleSave('mobilePhone')}
        >
          <TextField
            fullWidth
            type="tel"
            name="mobilePhone"
            error={!validMobilePhoneNumber(personData.mobilePhone)}
            label="Mobil (SMS vid utförd puts)"
            value={emtpyIfNull(personData.mobilePhone)}
            onChange={handleChange}
            placeholder="mobilnummer"
            margin="normal"
            autoFocus
          />
        </InputDialog>

        <TextField
          inputProps={{
            readOnly: true,
          }}
          fullWidth
          label="Epost"
          value={personData.email}
          placeholder="epost"
          margin="normal"
          onClick={() => setDialogState({ ...dialogState, email: true })}
        />

        <InputDialog
          open={dialogState.email}
          title="Ange epost"
          valid={validEmailAddress(personData.email)}
          onCancel={() => handleCancel('email')}
          onClose={() => handleSave('email')}
        >
          <TextField
            fullWidth
            type="email"
            name="email"
            error={!validEmailAddress(personData.email)}
            label="Epost"
            value={personData.email}
            onChange={handleChange}
            placeholder="epost"
            margin="normal"
            autoFocus
          />
        </InputDialog>
        <Message>
          Av säkerhetsorsaker kan du endast ändra telefonnummer och e-post. Om
          du vill ändra namn eller adress, vänligen kontakta kundservice
        </Message>
      </EditContainer>
    </Container>
  );
};
