import React from 'react';
import { IconButton } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import styled from 'styled-components';
import { teal } from '../utils/constants/colors';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

type ReloadButtonProps = {
  onClick: () => void;
  text: string;
};

export const ReloadButton = ({ onClick, text }: ReloadButtonProps) => {
  return (
    <Container>
      <IconButton onClick={onClick} style={{ padding: 5 }}>
        <Cached color="inherit" style={{ color: teal }} />
      </IconButton>
      <div onClick={onClick} style={{ fontSize: '12', color: teal }}>
        {text}
      </div>
    </Container>
  );
};
