import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { teal, white } from '../../../utils/constants/colors';
import { Row, Column } from '../../../Components/grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  Service,
  CleaningOccasion,
  mapCleaningOccasion,
} from '../../../Queries/types';
import { withRouter, RouteComponentProps } from 'react-router';
import { SummaryContent } from './SummaryContent';
import { withLocalState } from '../../../utils/withLocalState';
import { Progress } from '../../../Components/Progress';

const Header = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${teal};
  color: ${white};
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
`;

const HeaderDescription = styled.div`
  font-size: 19px;
  padding-top: 1px;
  letter-spacing: 0.66px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateLabel = styled.div`
  text-align: center;
  font-size: 30px;
  letter-spacing: 1px;
  margin-top: -3px;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
`;

const NoDataWrapper = styled(ProgressWrapper)`
  margin: auto;
  text-align: center;
`;

const ProgressOrNoData = ({ loading }: { loading: boolean }) => {
  if (loading) {
    return (
      <ProgressWrapper>
        <Progress message="Laddar tillfälle..." />
      </ProgressWrapper>
    );
  }
  return <NoDataWrapper>Inget data</NoDataWrapper>;
};

type CompletedEventProps = RouteComponentProps & {
  selectedOccasion: CleaningOccasion | null;
  services: Array<Service>;
  loading: boolean;
  noData: boolean;
};

const getCompletedDate = (occasion: CleaningOccasion | null) => {
  if (occasion) {
    return (
      <DateLabel>
        {mapCleaningOccasion(occasion).preliminaryStartDateString}
      </DateLabel>
    );
  }
  return null;
};

const getBookedDate = (occasion: CleaningOccasion | null) => {
  if (occasion) {
    return (
      <DateLabel>
        {mapCleaningOccasion(occasion).preliminaryStartDateString}
        <span> kl </span>
        {mapCleaningOccasion(occasion).startTime}
      </DateLabel>
    );
  }
  return null;
};

const getWorkCost = (services: Array<Service>) => {
  const costs = services
    .filter((s) => s.cost > s.taxReductionCost)
    .map((s) => s.cost * s.count);
  return R.sum(costs);
};

const CompletedEvent = ({
  selectedOccasion,
  services,
  history,
  loading,
  noData,
}: CompletedEventProps) => {
  return (
    <div>
      <Header>
        <Row>
          <Column size={2}>
            <Center>
              <IconButton
                onClick={() =>
                  history.push(`/timeline${history.location.search}`)
                }
              >
                <ArrowBack color="inherit" style={{ color: white }} />
              </IconButton>
            </Center>
          </Column>
          <Column size={8}>
            <Center>
              <HeaderDescription>
                {selectedOccasion && selectedOccasion.finished ? (
                  <>Genomförd putsning</>
                ) : (
                  <>Kommande putstillfälle</>
                )}
              </HeaderDescription>
            </Center>
            <Center>
              <HeaderDescription>
                {selectedOccasion && selectedOccasion.finished ? (
                  getCompletedDate(selectedOccasion)
                ) : (
                  <>
                    {selectedOccasion && !selectedOccasion.isTimeBooked ? (
                      <>Periodstart v.{selectedOccasion.week}</>
                    ) : (
                      <>{getBookedDate(selectedOccasion)} </>
                    )}
                  </>
                )}
              </HeaderDescription>
            </Center>
          </Column>
          <Column size={2} />
        </Row>
      </Header>
      <div>
        {loading || noData ? (
          <ProgressOrNoData loading={loading} />
        ) : (
          selectedOccasion && (
            <SummaryContent
              selectedOccasion={selectedOccasion}
              services={services}
              costs={[
                selectedOccasion!.cost,
                selectedOccasion!.taxReductionCost,
                getWorkCost(services),
              ]}
              goToInvoice={(invoiceNumber) =>
                history.push(
                  `/invoices/${invoiceNumber}${history.location.search}`
                )
              }
            />
          )
        )}
      </div>
    </div>
  );
};

export default withRouter(
  withLocalState<
    { selectedOccasion: CleaningOccasion | null },
    CompletedEventProps
  >(CompletedEvent, (state) => ({
    selectedOccasion: state.app.selectedOccasion,
  }))
);
