import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { withLocalState } from '../../utils/withLocalState';
import {
  SetCollect,
  SetCollectVariables,
  SET_COLLECT_MUTATION,
  ActivateOccasion,
  ActivateOccasionVariables,
  ACTIVATE_OCCASION_MUTATION,
} from '../../Queries/mutations';
import { getCookieByName, storeCollectCookie } from '../../utils/cookies';
import { requestToSign, openBankIdApp } from '../../utils/bankid';
import { cancel } from '../../services/bankid';
import { FullScreenProgressOverlay } from '../../Components/FullScreenProgressOverlay';
import {
  CollectPropsFromState,
  CollectProps,
  pollForStartCollect,
} from './Common';
import { ErrorMessage } from './ErrorMessage';

const ActivateCollect = ({
  isDev,
  isApp,
  isIOS,
  isMobile,
  session,
  selectedOccasion,
  location,
  isSameDevice,
  qrCode
}: CollectProps) => {
  const [activateOccasion, { error }] = useMutation<
    ActivateOccasion,
    ActivateOccasionVariables
  >(ACTIVATE_OCCASION_MUTATION, { refetchQueries: ['CleaningObjects'] });

  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const [completed, setCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  useEffect(() => {
    if (session && selectedOccasion) {
      pollForStartCollect(
        session,
        (status) => {
          const { cleaningObjectId, year, period } = selectedOccasion;
          if (status === 'complete') {
            activateOccasion({
              variables: {
                year,
                cleaningObjectId,
                period,
              },
            }).then(() => {
              setCollect({
                variables: { session: null },
              }).then(() => setCompleted(true));
            });
          } else {
            setCollect({
              variables: { session: null },
            });
          }
        },
        (errorMessage) => {
          setErrorMessage(errorMessage);
        }
      );
    }
  }, [session]);

  useEffect(() => {
    if (!getCookieByName('COLLECT', document.cookie)) {
      if (!isDev) {
        storeCollectCookie();
        requestToSign(location.state.signMessage,null).then(
          ({ session, autoStartToken,qrCode }) =>
            setCollect({
              variables: {
                session,
                signActivateRequested: true,
                qrCode
              },
            }).then(() => {
              if (autoStartToken && isMobile && isSameDevice) {
                if (isApp) {
                  openBankIdApp(autoStartToken, isApp, isIOS, 'sign');
                } else {
                  openBankIdApp(autoStartToken, isApp, isIOS, '');
                }
              }
            })
        );
      } else {
        setCollect({ variables: { session: 'dev' } });
      }
    }
  }, []);

  const cancelBankIdSign = () => {
    if (session) {
      cancel(session).then(() =>
        setCollect({
          variables: {
            session: null,
            signActivateRequested: false,
          },
        })
      );
    } else {
      setCollect({
        variables: {
          session: null,
          signActivateRequested: false,
        },
      });
    }
  };

  if (error) {
    return (
      <ErrorMessage message="Det gick inte att spara" redirectPath="timeline" />
    );
  }

  if (completed) {
    return <Redirect to={`/timeline${location.search}`} />;
  }

  if (errorMessage) {
    return (
      <ErrorMessage
        message="Signering med BankID misslyckades"
        redirectPath="timeline"
      />
    );
  }

  return (
    <FullScreenProgressOverlay
      message={`${isSameDevice && qrCode ? 'Väntar på signering med BankID' : 'Skanna QR-kod för att signera'}`}
      onClick={!isSameDevice && qrCode? cancelBankIdSign : undefined}
      qrCode={!isSameDevice && qrCode ? 
        qrCode : undefined }
    />
  );
};

const ActivateCollectWithLocalState = withLocalState<
  CollectPropsFromState,
  CollectProps
>(ActivateCollect, (state) => ({
  session: state.user.session,
  qrCode: state.user.qrCode,
  isApp: state.app.isApp,
  isIOS: state.app.isIOS,
  isMobile: state.app.isMobile,
  personalNumber: state.app.personalNumber,
  isDev: state.app.isDev,
  selectedOccasion: state.app.selectedOccasion,
  isSameDevice: state.app.isSameDevice,
  signReason:state.user.signReason
}));

export default withRouter(ActivateCollectWithLocalState);
