import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Row, Column } from './grid';
import { EFButton } from './buttons';
import { teal } from '../utils/constants/colors';
import { Email, Call } from '@material-ui/icons';

const DialogContent = styled.div`
  padding: 10px;
  padding-bottom: 20px;
`;

const DialogMessageWrapper = styled.div`
  min-height: 160px;
  padding: 20px;
`;

const DialogMessage = styled.div`
  font-size: 20px;
  letter-spacing: 0.618px;
  line-height: 1.3;
`;

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none'
  }
})(Dialog);

const DialogButtonWrapper = styled.div`
  width: 50%;
  margin: auto;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const Info = styled.div`
  margin-left: 5px;
`;

const StyledAnchor = styled.a`
  color: ${teal};
`;

type InfoDialogProps = {
  open: boolean;
  onClose: () => void;
  message: string;
};

export default ({ open, onClose, message }: InfoDialogProps) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogMessageWrapper>
          <DialogMessage>{message}</DialogMessage>
        </DialogMessageWrapper>
        <InfoWrapper>
          <Email fontSize="small" color="primary" />
          <Info>
            <StyledAnchor href="mailto:kundtjanst@eriksfonsterputs.se">
              kundtjanst@eriksfonsterputs.se
            </StyledAnchor>
          </Info>
        </InfoWrapper>
        <InfoWrapper>
          <Call fontSize="small" color="primary" />
          <Info>
            <StyledAnchor href="tel:0771424242">0771-42 42 42</StyledAnchor>
          </Info>
        </InfoWrapper>
        <Row>
          <Column size={12}>
            <DialogButtonWrapper style={{ paddingRight: 5 }}>
              <EFButton color={teal} onClick={onClose}>
                OK
              </EFButton>
            </DialogButtonWrapper>
          </Column>
        </Row>
      </DialogContent>
    </StyledDialog>
  );
};
