import { LOCAL_STATE_QUERY } from '../Queries/queries';
import { UserState } from '../Queries/types';
import { SetCollectVariables } from '../Queries/mutations';
import * as R from 'ramda';

const setState = (user: Partial<UserState>, cache: any) => {
  const previousState = cache.readQuery({ query: LOCAL_STATE_QUERY });

  const data = {
    ...previousState,
    user: {
      ...previousState.user,
      ...user,
    },
  };

  cache.writeQuery({
    query: LOCAL_STATE_QUERY,
    data,
  });

  return null;
};

export const resolvers = {
  Mutation: {
    setCollect: (_: any, variables: SetCollectVariables, { cache }: any) => {
      return setState(
        R.reject((v: any) => v === undefined, variables),
        cache
      );
    },
  },
};

export const defaults = {
  __typename: 'User',
  session: null,
  authRequested: false,
  signActivateRequested: false,
  signUpdateRequested: false,
  signCancelRequested: false,
  servicesForUpdate: [],
  qrCode: null,
  isSameDevice:false,
  signReason:null
} as UserState;
