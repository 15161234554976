import React, { Component, useState } from 'react';
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import { Row, Column } from '../../../../../Components/grid';
import {
  white,
  lineGrayLight,
  grayBlue,
  gray,
} from '../../../../../utils/constants/colors';
import { ServiceWithStatus } from '../../../../../Queries/types';
import { TextButton } from '../../../../../Components/buttons';
import NewWindowIcon from '../../../../../assets/new_window.svg';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from '../../../../../styles';
import { Reaccuring } from '../../../../../Components/Reaccuring';
import { Once } from '../../../../../Components/Once';
import { NotPriced } from '../../../../../Components/NotPriced';
import { ConfigureServiceDialog } from '../../../../../Components/ConfigureServiceDialog';
import { Lock } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

type ServiceRowProps = {
  first?: boolean;
  last?: boolean;
  editable: boolean;
};

const ServiceRow = styled.div`
  background-color: ${white};
  border-top: 1px solid
    ${(props: ServiceRowProps) =>
      props.first === true ? white : lineGrayLight};
  border-top-left-radius: ${(props: ServiceRowProps) =>
    props.first === true ? '6px' : 0};
  border-top-right-radius: ${(props: ServiceRowProps) =>
    props.first === true ? '6px' : 0};
  border-bottom-left-radius: ${(props: ServiceRowProps) =>
    props.last === true ? '6px' : 0};
  border-bottom-right-radius: ${(props: ServiceRowProps) =>
    props.last === true ? '6px' : 0};
  box-shadow: ${(props: ServiceRowProps) => {
    if (props.first === true) return '0 -2px 2px rgba(0,0,0,0.05)';
    if (props.last === true) return '0 2px 2px rgba(0,0,0,0.05)';
    return 'none';
  }};
  cursor: ${(props: ServiceRowProps) => (props.editable ? 'pointer' : 'none')};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PriceListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin: 10px;
`;

const ServiceLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 17px;
`;

const ServicePrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${grayBlue};
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  font-size: 18px;
  letter-spacing: 0.5;
`;

type ServiceListProps = WithStyles<typeof styles> & {
  services: Array<ServiceWithStatus>;
  toggleSelected: (service: ServiceWithStatus) => void;
  occasionNumber: number;
};

export type ServiceForList = ServiceWithStatus & {
  onSubscription: boolean;
};

const openPricesList = () =>
  window.open('https://eriksfonsterputs.se/prislista/', '_blank');

const newCategory = (
  service: ServiceWithStatus,
  services: Array<ServiceWithStatus>,
  index: number
) => {
  if (service.selected) {
    return false;
  }

  if (services.length - 1 >= index - 1) {
    if (
      (services[index - 1] &&
        services[index - 1].category &&
        service.category.id !== services[index - 1].category.id) ||
      (services[index - 1] && services[index - 1].selected)
    ) {
      return true;
    }
  }

  return false;
};

const startOfUnselected = (
  service: ServiceWithStatus,
  services: Array<ServiceWithStatus>,
  index: number
) => {
  if (service.selected) {
    return false;
  }

  if (services.length - 1 >= index - 1) {
    if (services[index - 1].selected) {
      return true;
    }
  }

  return false;
};

const ServiceList = withStyles(styles)(
  ({ services, toggleSelected, occasionNumber }: ServiceListProps) => {
    if (services.length <= 0) return null;

    const [dialogState, setDialogOpen] = useState<{
      open: boolean;
      service: ServiceWithStatus | null;
    }>({ open: false, service: null });

    const firstIndex = 0;
    const lastIndex = services.length - 1 >= 0 ? services.length - 1 : 0;

    const serviceRow = (service: ServiceWithStatus, index: number) => {
      return (
        <ServiceRow
          key={service.serviceId}
          first={index === firstIndex}
          last={index === lastIndex}
          editable={service.visibleInApp}
          onClick={() => {
            if (service.visibleInApp) {
              setDialogOpen({ open: true, service });
            }
          }}
        >
          <Row>
            <Column size={service.selected ? 2 : 1}>
              <CheckboxWrapper>
                <div
                  style={{
                    height: 25,
                    width: 25,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!service.visibleInApp ? (
                    <Lock htmlColor={gray} />
                  ) : service.selectedStatus === 'Always' ? (
                    <Reaccuring checked={true} />
                  ) : service.selectedStatus === 'Once' ? (
                    <Once checked={true} />
                  ) : (
                    <div />
                  )}
                </div>
              </CheckboxWrapper>
            </Column>

            <Column size={service.selected ? 7 : 8}>
              <ServiceLabel>
                {service.name}
                {service.freeText && service.freeText.trim() !== '' && ` | ${service.freeText}`}
              </ServiceLabel>
            </Column>
            <Column size={3}>
              {!service.missingPrice ? (
                <ServicePrice>{service.cost} kr</ServicePrice>
              ) : (
                <ServicePrice>
                  <div style={{ height: 30, width: 30 }}>
                    {' '}
                    <NotPriced />{' '}
                  </div>
                </ServicePrice>
              )}
            </Column>
          </Row>
        </ServiceRow>
      );
    };

    return (
      <div>
        <SectionLabel>Valda tjänster</SectionLabel>
        <FlipMove>
          {services.map((service: ServiceWithStatus, index: number) => {
            return index === 0 && service.selected ? (
              serviceRow(service, index)
            ) : newCategory(service, services, index) ? (
              <div key={service.category.name}>
                {startOfUnselected(service, services, index) && (
                  <SectionLabel>Tjänster att välja till</SectionLabel>
                )}
                <ServiceCategoryLabel>
                  {service.category.name}
                </ServiceCategoryLabel>{' '}
                {serviceRow(service, index)}
              </div>
            ) : (
              <div key={service.serviceId}>{serviceRow(service, index)} </div>
            );
          })}
        </FlipMove>

        <ConfigureServiceDialog
          open={dialogState.open}
          serviceName={dialogState.service ? dialogState.service.name : ''}
          occasionNumber={occasionNumber}
          serviceStatus={
            dialogState.service ? dialogState.service.selectedStatus : 'Never'
          }
          missingPrice={
            dialogState.service ? dialogState.service.missingPrice : false
          }
          onAbort={() => setDialogOpen({ open: false, service: null })}
          onConfirm={(selectedStatus) => {
            if (dialogState.service) {
              toggleSelected({ ...dialogState.service, selectedStatus });
            }
            setDialogOpen({ open: false, service: null });
          }}
        />
      </div>
    );
  }
);

const SectionLabel = styled.div`
  margin: 20px 0 15px 25px;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.618px;
`;

const ServiceCategoryLabel = styled.div`
  margin: 15px 0 15px 25px;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.618px;
`;

const ServicesContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 30px;
`;

const IconStyled = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  display: block;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

type ServicesProps = {
  toggleSelected: (service: ServiceWithStatus) => void;
  services: Array<ServiceWithStatus>;
  occasionNumber: number;
};

type ServicesState = {
  showMissingPriceServices: boolean;
};

export class Services extends Component<ServicesProps, ServicesState> {
  // Init
  constructor(props: ServicesProps) {
    super(props);

    this.state = { showMissingPriceServices: false };
  }

  // Render
  render() {
    const { services, toggleSelected, occasionNumber } = this.props;

    const servicesSorted = services
      .map((s) => { s.uuid = uuidv4(); return s; })
      .filter((s) => s.visibleInApp || s.selected)
      .reduce((uniqueServices:ServiceWithStatus[], service) => {
        const existingService = uniqueServices.find(
          (s) => s.serviceId === service.serviceId
        );
    
        if (!existingService) {
          uniqueServices.push(service);
        } else {
          // Checks if the existing service is selected and the new one isn't.
          if (existingService.selected && !service.selected) {
            uniqueServices = uniqueServices.filter(
              (s) => s.serviceId !== existingService.serviceId
            );
            uniqueServices.push(service);
          }
        }
    
        return uniqueServices;
      }, [])
      .sort((a, b) => {
        return (
          (a.selected ? 0 : 1) - (b.selected ? 0 : 1) ||
          (a.visibleInApp ? 1 : 0) - (b.visibleInApp ? 1 : 0) ||
          (a.selected && b.selected ? a.name.localeCompare(b.name) : 0) ||
          a.category.sortOrder - b.category.sortOrder ||
          a.name.localeCompare(b.name) &&
          (a.unitPrice > 0 ? 1 : 0) - (b.unitPrice > 0 ? 1 : 0)
        );
      });

    return (
      <ServicesContainer>
        <ServiceList
          services={servicesSorted}
          toggleSelected={toggleSelected}
          occasionNumber={occasionNumber}
        />
        <PriceListContainer>
          <TextButton onClick={openPricesList}>
            <IconStyled src={NewWindowIcon} />
            <UnderlinedText>Öppna prislistan</UnderlinedText>
          </TextButton>
        </PriceListContainer>
      </ServicesContainer>
    );
  }
}
