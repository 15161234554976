import React from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { Header } from '../../Components/Header';
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery } from 'react-apollo';
import {
  InvoiceDetails as InvoiceDetailsQuery,
  INVOICE_DETAILS_QUERY
} from '../../Queries/queries';
import { InvoiceDetails as InvoiceDetailsType } from '../../Queries/types';
import { Progress } from '../../Components/Progress';
import { Price } from '../../Components/Price';
import { Row, Column } from '../../Components/grid';
import { getStatusAndColor, StatusText, prettyAddress } from './Invoices';
import { CloudDownload, FileCopy } from '@material-ui/icons';
import { PrimaryButton } from '../../Components/buttons';
import { IconButton } from '@material-ui/core';
import { lightTeal } from '../../utils/constants/colors';
import { Title, SubHeader } from '../../Components/Common';

const Container = styled.div`
  width: 100%;
  margin-top: 70px;
  height: calc(100% - 140px);
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const InnerWrapper = styled.div`
  padding: 0 20px;
`;

const Details = styled.div`
  margin-bottom: 10px;
`;

const AlternateColor = styled.div`
  .alternate:nth-child(odd) {
    background-color: ${lightTeal};
  }

  .alternate {
    padding: 5px;
  }
`;

const InvoiceLink = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 90%;
`;

const ButtonText = styled.span`
  margin-right: 5px;
`;

const ColumnRight = styled(Column)`
  text-align: right;
`;

const StatusTextSmall = styled(StatusText)`
  background: #60a561;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 12px;
  float: right;
  line-height: 15px;
  color: white;
  padding: 8px;
  text-transform: capitalize;
  font-weight: 900;
  border-radius: 2px;
  margin-top: 3px;
`;

const ExtSubHeder = styled(SubHeader)`
    margin:20px 0;
`;

const MyRow = styled(Row)`
  margin-bottom: 10px;
`;

type InvoiceDetailsPops = RouteComponentProps & {
  invoiceNumber: number;
};

const getStatusText = (invoiceDetails: InvoiceDetailsType) => {
  const [status, , color] = getStatusAndColor(invoiceDetails);
  return <StatusTextSmall color={color}>{status}</StatusTextSmall>;
};

const getToPay = (invoiceDetails: InvoiceDetailsType) => {
  return (
    invoiceDetails.total +
    invoiceDetails.rounding -
    invoiceDetails.taxReduction
  )
    .toFixed(2)
    .toString();
};

const BANK_ACCOUNT = '5787-1030';

const TextWithCopy = ({
  value,
  suffix
}: {
  value: string;
  suffix?: string;
}) => {
  return (
    <>
      <span onClick={() => copy(value)}>{`${value}${
        suffix ? ` ${suffix}` : ''
      }`}</span>
      <IconButton
        style={{ padding: 0, paddingLeft: 5 }}
        onClick={() => copy(value)}
      >
        <FileCopy color="inherit" fontSize="small" />
      </IconButton>
    </>
  );
};

export const InvoiceDetailsView = (props: InvoiceDetailsPops) => {
  const { loading, error, data } = useQuery<InvoiceDetailsQuery>(
    INVOICE_DETAILS_QUERY,
    {
      variables: { invoiceNumber: props.invoiceNumber },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (error) {
    throw error;
  }

  return (
    <>
      <Header onBack={() => props.history.goBack()} />
      <Container>
        {loading || !data ? (
          <Progress message="Laddar faktura..." />
        ) : data.invoiceDetails === null ? (
          <SubHeader>Hittade inte fakturan</SubHeader>
        ) : (
          <>
            <Title>Fakturadetaljer {getStatusText(data.invoiceDetails)}</Title>
            <InnerWrapper>
              <Details>
                <Row>
                  <Column size={6}>Fakturanummer</Column>
                  <ColumnRight size={6}>
                    {data.invoiceDetails.invoiceNumber}{' '}
                  </ColumnRight>
                </Row>
              </Details>

              <ExtSubHeder>Betalningsinformation</ExtSubHeder>
              <Details>
                <MyRow>
                  <Column size={6}>Bankgiro</Column>
                  <ColumnRight size={6}>
                    <TextWithCopy value={BANK_ACCOUNT} />
                  </ColumnRight>
                </MyRow>
                <MyRow>
                  <Column size={6}>OCR-nummer</Column>
                  <ColumnRight size={6}>
                    <TextWithCopy
                      value={data.invoiceDetails!.invoiceOcr.toString()}
                    />
                  </ColumnRight>
                </MyRow>
                <MyRow>
                  <Column size={6}>Att betala</Column>
                  <ColumnRight size={6}>
                    <TextWithCopy
                      value={getToPay(data.invoiceDetails!)}
                      suffix="kr"
                    />
                  </ColumnRight>
                </MyRow>
              </Details>

              <ExtSubHeder>Sammanställning</ExtSubHeder>

              <Details>
                <AlternateColor>
                  <Row className="alternate">
                    <Column size={6}>Leveransadress</Column>
                    <ColumnRight size={6}>
                      {prettyAddress(data.invoiceDetails!.cleaningObjectName)}
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Fakturadatum</Column>
                    <ColumnRight size={6}>
                      {data.invoiceDetails!.invoiceDate}
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Förfallodatum</Column>
                    <ColumnRight size={6}>
                      {data.invoiceDetails!.dueDate}
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Totalt belopp</Column>
                    <ColumnRight size={6}>
                      <Price value={data.invoiceDetails!.total} decimals={2} />
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Varav moms</Column>
                    <ColumnRight size={6}>
                      <Price value={data.invoiceDetails!.vat} decimals={2} />
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Avrundning</Column>
                    <ColumnRight size={6}>
                      <Price
                        value={data.invoiceDetails!.rounding}
                        decimals={2}
                      />
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>
                      <div>Varav arbetskostnad</div>
                    </Column>
                    <ColumnRight size={6}>
                      <Price
                        value={data.invoiceDetails!.workCost}
                        decimals={2}
                      />
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Skatteredukt.</Column>
                    <ColumnRight size={6}>
                      <Price
                        value={data.invoiceDetails!.taxReduction}
                        decimals={2}
                      />
                    </ColumnRight>
                  </Row>
                  <Row className="alternate">
                    <Column size={6}>Skatteredukt. beslutat</Column>
                    <ColumnRight size={6}>
                      <Price
                        value={
                          data.invoiceDetails!.taxReductionDecided
                            ? data.invoiceDetails!.taxReductionDecided
                            : 0
                        }
                        decimals={2}
                      />
                    </ColumnRight>
                  </Row>
                  {data.invoiceDetails!.taxReductionDenied !== null &&
                    data.invoiceDetails!.taxReductionDenied > 0 && (
                      <Row className="alternate">
                        <Column size={6}>Skatteredukt. nekat</Column>
                        <ColumnRight size={6}>
                          <Price
                            value={
                              data.invoiceDetails!.taxReductionDenied
                                ? data.invoiceDetails!.taxReductionDenied
                                : 0
                            }
                            decimals={2}
                          />
                        </ColumnRight>
                      </Row>
                    )}
                  <Row className="alternate">
                    <Column size={6}>Totalsaldo</Column>
                    <ColumnRight size={6}>
                      <Price
                        value={data.invoiceDetails!.balance}
                        decimals={2}
                      />
                    </ColumnRight>
                  </Row>
                </AlternateColor>
              </Details>

              {data.invoiceDetails!.link !== null && (
                <InvoiceLink>
                  <PrimaryButton
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      window.open(data.invoiceDetails!.link!, '_blank')
                    }
                  >
                    <ButtonText>Ladda ner faktura</ButtonText>
                    <CloudDownload />
                  </PrimaryButton>
                </InvoiceLink>
              )}
            </InnerWrapper>
          </>
        )}
      </Container>
    </>
  );
};

export const InvoiceDetails = withRouter(InvoiceDetailsView);
