import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import ResetCollect from '../../Components/ResetCollect';
import { EFButton } from '../../Components/buttons';
import { clearCookieByName } from '../../utils/cookies';
import { Header } from '../../Components/Header';

type ErrorMessageProps = RouteComponentProps & {
  message: string;
  redirectPath: string;
  hideRetry?: boolean;
};

const Container = styled.div`
  margin: 120px auto;
`;

const MessageWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
`;

export const ErrorMessage = withRouter(
  ({ message, redirectPath, hideRetry, location }: ErrorMessageProps) => {
    const [redirect, setRedirect] = useState(false);
    if (redirect) {
      return <Redirect to={`/${redirectPath}${location.search}`} />;
    }

    if (hideRetry) {
      return (
        <ResetCollect>
          <Header />
          <Container>
            <MessageWrapper>{message}</MessageWrapper>
          </Container>
        </ResetCollect>
      );
    }

    return (
      <ResetCollect>
        <Header />
        <Container>
          <MessageWrapper>{message}</MessageWrapper>
          <ButtonWrapper>
            <EFButton
              onClick={() => {
                clearCookieByName('COLLECT');
                setRedirect(true);
              }}
            >
              Försök igen
            </EFButton>
          </ButtonWrapper>
        </Container>
      </ResetCollect>
    );
  }
);
