import React from 'react';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from '../styles';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

const Container = styled.div`
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

type QrCodeProps = WithStyles<typeof styles> & {
  qrCode:string
};

export const QrCodeComponent = (props: QrCodeProps) => {

  return (
      <Container>
        <QRCode value={props.qrCode} />
      </Container>
  );
};

export const QrCode = withStyles(styles)(QrCodeComponent);
