import {
  collectAuthRequest,
  CollectResponse,
  requestSignSameDevice,
  requestLoginSameDevice,
  refreshToken,
  collectSignRequest,
} from '../services/bankid';

const DEFAULT_ERROR_MESSAGE = 'Signering med BankID misslyckades';

export type CollectSuccess = 'complete' | 'canceled';

export const isDev = () => localStorage.getItem('SESSION') === 'dev';

export const openBankIdApp = (
  autoStartToken: string,
  isApp: boolean,
  isIOS: boolean,
  redirect: string
) => {
  const webUri = encodeURI(`${window.location.href}${redirect}`);
  const appUri = encodeURI(`efcustomer://${redirect}`);
  const uri = isApp ? appUri : webUri;

  if (isIOS && isApp) {
    window.open(
      `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${uri}`,
      '_self'
    );
  } else {
    window.open(
      `bankid:///?autostarttoken=${autoStartToken}&redirect=${
        isIOS || isApp ? uri : null
      }`,
      '_self'
    );
  }
};

export const shouldOpenBankIdApp = (
  isOtherDevice: boolean,
  isMobile: boolean
) => {
  return !isOtherDevice && isMobile;
};

export const requestToAuth = (
  isOtherDevice: boolean,
  isApp: boolean,
  isIOS: boolean,
  isMobile: boolean,
  redirect: string
): Promise<{ session: string; qrCode: string }> => {
  return requestLoginSameDevice().then(
    ({ autoStartToken, session, message, qrCode }) => {
      if (message) {
        return Promise.reject(message);
      } else {
        if (autoStartToken && shouldOpenBankIdApp(isOtherDevice, isMobile)) {
          if (isApp) {
            openBankIdApp(autoStartToken, isApp, isIOS, '/');
          } else {
            openBankIdApp(autoStartToken, isApp, isIOS, redirect);
          }
        }
        return Promise.resolve({ session, qrCode });
      }
    }
  );
};

export const requestToSign = (
  message: string,
  reason:string | null
): Promise<{
  session: string;
  autoStartToken: string | null;
  qrCode: string;
}> => {
  return requestSignSameDevice(message,reason).then(
    ({ autoStartToken, session, message, qrCode }) => {
      if (message) {
        return Promise.reject(message);
      } else {
        return Promise.resolve({ session, autoStartToken, qrCode });
      }
    }
  );
};

export const pollCollect = (
  session: string,
  numberOfTries: number,
  auth = true
): Promise<CollectResponse> => {
  return (auth ? collectAuthRequest(session) : collectSignRequest(session))
    .then((data) => {
      return new Promise<CollectResponse>((resolve) => {
        if (data.status === 'pending') {
          setTimeout(
            () => resolve(pollCollect(session, numberOfTries, auth)),
            1000
          );
        } else if (data.status === 'failed') {
          resolve({
            message: DEFAULT_ERROR_MESSAGE,
            status: 'failed',
            token: null,
            id: null,
          });
        } else if (data.status === 'complete') {
          resolve(data);
        } else {
          resolve({
            message: DEFAULT_ERROR_MESSAGE,
            status: 'unknown',
            token: null,
            id: null,
          });
        }
      });
    })
    .catch(() => {
      return new Promise((resolve, reject) => {
        if (numberOfTries < 5) {
          setTimeout(
            () => resolve(pollCollect(session, numberOfTries + 1, auth)),
            1000
          );
        } else {
          reject(DEFAULT_ERROR_MESSAGE);
        }
      });
    });
};

const TEN_MINUTES = 600000;

export const startTokenRefreshInterval = () => {
  const refresh = () => refreshToken().catch((err) => console.error(err));
  return setInterval(refresh, TEN_MINUTES);
};
