export const PageTitleStyle = {
  family: 'Lato Black, Helvetica, Arial, sans-serif',
  size: '26px',
  weight: 900,
  flex: 1,
  color: '#FFF'
};

export const SubHeaderStyle = {
  family: 'Lato Black, Helvetica, Arial, sans-serif',
  size: '16px',
  weight: 900
};

export const BodyTextStyle = {
  family: 'Lato Black, Helvetica, Arial, sans-serif',
  size: '14px',
  weight: 400
};
