import { RouteComponentProps } from 'react-router-dom';
import * as R from 'ramda';
import { CleaningOccasion } from '../../Queries/types';
import { getCookieByName, clearCookieByName } from '../../utils/cookies';
import { pollCollect } from '../../utils/bankid';

export type CollectPropsFromState = {
  session: string | null;
  isApp: boolean;
  isMobile: boolean;
  isIOS: boolean;
  isDev: boolean;
  personalNumber: string | null;
  selectedOccasion: CleaningOccasion | null;
  isSameDevice: boolean;
  qrCode:string | null
  signReason: string | null
};

export type CollectProps = CollectPropsFromState & RouteComponentProps;

export const pollForStartCollect = (
  session: string,
  onSuccess: (status: string) => void,
  onError: (err: string) => void
) => {
  if (session === 'dev') {
    onSuccess('complete');
  } else {
    const intervalHandle = setInterval(() => {
      if (getCookieByName('COLLECT', document.cookie)) {
        collect(session, onSuccess, onError);
        clearInterval(intervalHandle);
      }
    }, 2000);
  }
};

const collect = R.memoizeWith(
  R.identity,
  (
    session: string,
    onSuccess: (status: string) => void,
    onError: (err: string) => void
  ) => {
    pollCollect(session, 0, false)
      .then((data) => {
        if (data.status === 'complete') {
          clearCookieByName('COLLECT');
          onSuccess(data.status);
          return;
        } 
        if(data.status === "canceled") {
          onError("Signering avbruten");
        }
        else {
          onError(data.message!);
        }
      })
      .catch((err) => {
        onError(err);
      });
  }
);
