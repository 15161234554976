import gql from 'graphql-tag';
import {
  CleaningObject,
  Service,
  Person,
  Invoice,
  InvoiceDetails as InvoiceDetailsType,
  Post,
  OperatingStatusType,
} from './types';

// User
export interface User {
  person: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export const USER_QUERY = gql`
  query Person {
    person {
      id
      firstName
      lastName
    }
  }
`;

export const POSTS_QUERY = gql`
  query Posts {
    posts {
      id
      date
      title
      excerpt
      content
      link
      featuredMediaURL
    }
  }
`;
export interface Posts {
  posts: Array<Post>;
}

export const SINGLE_POST_QUERY = gql`
  query SinglePost($postId: String!) {
    singlePost(postId: $postId) {
      id
      date
      title
      excerpt
      content
      link
      featuredMediaURL
    }
  }
`;

export interface SinglePost {
  singlePost: Post | null;
}

export interface CleaningObjectVariables {
  years: Array<number> | null;
}

export interface CleaningObjects {
  cleaningObjects: Array<CleaningObject>;
}

export const CLEANING_OBJECTS_QUERY = gql`
  query CleaningObjects($years: [Int]) {
    cleaningObjects(years: $years) {
      id
      address
      city
      postalCode
      cleaningOccasions {
        cleaningObjectId
        cost
        year
        month
        week
        period
        completedDate
        preliminaryStartDate
        scheduledCleaning
        finished
        taxReductionCost
        occasionHasPassed
        changesAllowed
        isTimeBooked
        startTime
        workOrderId
        taxReductionDevider
        invoiceNumber
        locked
        canceled
      }
    }
  }
`;

export const PERSON_QUERY = gql`
  query Person {
    person {
      personalNo
      firstName
      lastName
      companyName
      workPhone
      mobilePhone
      privatePhone
      email
      customerId
      address
      address2
      postalCode
      city
      invoiceAddress
      invoiceAddress2
      invoicePostalCode
      invoiceCity
    }
  }
`;

export interface PersonsVariables {}

export interface Persons {
  person: Person;
}

export interface Invoices {
  invoices: Array<Invoice>;
}

export const INVOICES_QUERY = gql`
  query Invoices {
    invoices {
      invoiceNumber
      cleaningObjectName
      dueDate
      invoiceDate
      balance
      total
      taxReduction
      taxReductionDecided
      taxReductionDenied
    }
  }
`;

export interface InvoiceDetails {
  invoiceDetails: InvoiceDetailsType | null;
}

export const INVOICE_DETAILS_QUERY = gql`
  query InvoiceDetails($invoiceNumber: Int!) {
    invoiceDetails(invoiceNumber: $invoiceNumber) {
      invoiceNumber
      invoiceOcr
      cleaningObjectName
      invoiceDate
      dueDate
      balance
      total
      vat
      taxReduction
      taxReductionDecided
      taxReductionDenied
      rounding
      link
      workCost
    }
  }
`;

export interface OperatingStatus {
  operatingStatus: Array<OperatingStatusType>;
}

export const OPERATING_STATUS_QUERY = gql`
  query OperatingStatus {
    operatingStatus {
      messageColor
      messageText
      activeFrom
      activeTo
    }
  }
`;

export interface OptionalServicesVariables {
  year: number;
  cleaningObjectId: number;
}

export interface OptionalServices {
  optionalServices: Array<Service>;
}

export const OPTIONAL_SERVICES_QUERY = gql`
  query OptionalServices($year: Int!, $cleaningObjectId: Int!) {
    optionalServices(year: $year, cleaningObjectId: $cleaningObjectId) {
      cost
      count
      serviceId
      cleaningObjectPriceId,
      name
      permanent
      status
      supplementService
      visibleInApp
      taxReductionCost
      unitPrice
      category {
        id
        name
        sortOrder
      }
      missingPrice
      freeText
    }
  }
`;

export interface ServicesForOccasionVariables {
  year: number;
  cleaningObjectId: number;
  period: number;
  workOrderId: number | null;
}

export interface ServicesForOccasion {
  servicesForOccasion: Array<Service>;
}

export const SERVICES_FOR_OCCASION_QUERY = gql`
  query ServicesForOccasion(
    $year: Int!
    $cleaningObjectId: Int!
    $period: Int!
    $workOrderId: Int
  ) {
    servicesForOccasion(
      year: $year
      cleaningObjectId: $cleaningObjectId
      period: $period
      workOrderId: $workOrderId
    ) {
      cost
      count
      serviceId
      cleaningObjectPriceId
      name
      permanent
      status
      supplementService
      visibleInApp
      taxReductionCost
      unitPrice
      category {
        id
        name
        sortOrder
      }
      missingPrice
      freeText
    }
  }
`;

export const LOCAL_STATE_QUERY = gql`
  query State {
    user @client {
      __typename
      session
      authRequested
      signActivateRequested
      signUpdateRequested
      signCancelRequested
      servicesForUpdate
      isSameDevice
      qrCode
      signReason
    }
    app @client {
      __typename
      isDev
      isMobile
      isIOS
      isApp
      personalNumber
      selectedOccasion
      selectedLocationId
      isSameDevice
    }
  }
`;
