import React from 'react';
import { LOCAL_STATE_QUERY } from '../Queries/queries';
import { LocalState } from '../Queries/types';
import { useQuery } from 'react-apollo';

export const withLocalState = <TStateProps, TOwnProps extends TStateProps>(
  Component: React.ComponentType<TOwnProps>,
  mapStateToProps: (state: LocalState) => TStateProps
) => {
  type WrappedComponentPropsExceptProvided = Exclude<
    keyof TOwnProps,
    keyof TStateProps
  >;

  type ForwardedProps = Pick<TOwnProps, WrappedComponentPropsExceptProvided>;

  const WithLocalState: React.FunctionComponent<ForwardedProps> = (
    props: ForwardedProps
  ) => {
    const { data } = useQuery<LocalState>(LOCAL_STATE_QUERY);
    const stateProps = data ? mapStateToProps(data) : {};
    return <Component {...(props as any)} {...(stateProps as TStateProps)} />;
  };

  return WithLocalState;
};
