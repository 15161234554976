import styled from 'styled-components';

import {
  PageTitleStyle,
  SubHeaderStyle,
  BodyTextStyle,
} from './TextualElements';

export const Title = styled.div`
  font-family: ${PageTitleStyle.family};
  font-size: ${PageTitleStyle.size};
  font-weight: ${PageTitleStyle.weight};
  color: black;
  padding: 20px;
`;

export const SubHeader = styled.div`
  font-size: ${SubHeaderStyle.size};
  font-family: ${SubHeaderStyle.family};
  font-weight: ${SubHeaderStyle.weight};
  margin: 0;
`;

export const SubLine = styled.div`
  font-size: 14px;
  font-weight: ${BodyTextStyle.weight};
  display: block;
  margin-bottom: 5px;
`;

export const TextNormal = styled.div`
  font-family: ${BodyTextStyle.family};
  font-size: ${BodyTextStyle.size};
  font-weight: ${BodyTextStyle.weight};
  color: black;
`;

export const Ingress = styled(TextNormal)`
  margin: 10px 0 30px 0;
  letter-spacing: 0.5px;
  display: flex;
  & svg {
    margin-right: 10px;
    object-fit: contain;
    align-self: flex-start;
  }
  & text {
    flex: 1 1 auto;
  }
`;
