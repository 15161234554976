import { ServiceStatusType, ServiceWithStatus } from '../Queries/types';

export const getNewSetStatus = (service: ServiceWithStatus) => {
  if (service.selectedStatus === 'Always') {
    return ServiceStatusType.Permanent;
  } else if (service.selectedStatus === 'Once') {
    return ServiceStatusType.Added;
  }
  return service.status;
};

export const getNewRemovedStatus = (service: ServiceWithStatus) => {
  if (service.selectedStatus === 'Never') {
    return ServiceStatusType.NotSet;
  } else if (service.selectedStatus === 'Not this') {
    return ServiceStatusType.Removed;
  }
  return service.status;
};
