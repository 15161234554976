import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { styles } from '../styles';
import Logo from '../assets/logo.jpg';
import { Login } from './Login';
import { isMobileDevice, isIOSDevice } from '../utils/parsers';
import { withLocalState } from '../utils/withLocalState';

const Centered = styled.div`
  text-align: center;
`;

const OnTopWrapper = styled.div`
  z-index: 1;
  overflow-y: scroll;
`;

const WelcomeMessage = styled.div`
  margin-top: 100px;
  margin-bottom: 30px;
  font-size: 1.4em;
`;

const Icon = styled.img`
  max-width: 80%;
  max-height: 200px;
`;

const LogInWrapper = styled.div`
  width: 100%;
`;

type WelcomeProps = RouteComponentProps &
  WithStyles<typeof styles> & {
    onSuccess: (session: string, personalNumber: string | null) => void;
    onError: (errorMessage: string) => void;
    isApp: boolean;
    authRequested: boolean;
    signActivateRequested: boolean;
    signUpdateRequested: boolean;
    signCancelRequested: boolean;
    fromFatalError: boolean;
    onRetry: () => void;
  };

type WelcomeState = {
  dialogOpen: boolean;
};

class WelcomeComponent extends Component<WelcomeProps, WelcomeState> {
  constructor(props: WelcomeProps) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.fromFatalError) {
      this.props.onRetry();
    }
  }

  componentDidUpdate(prevProps: WelcomeProps) {
    if (!prevProps.fromFatalError && this.props.fromFatalError) {
      this.props.onRetry();
    }
  }

  onSuccess = (session: string | null, personalNumber: string | null) => {
    if (session) {
      this.props.onSuccess(session, personalNumber);
    }
    this.setState({ dialogOpen: false });
  };

  onError = (errorMessage: string) => {
    this.props.onError(errorMessage);
    this.setState({ dialogOpen: false });
  };
  render() {
    const {
      classes,
      isApp,
      authRequested,
      signActivateRequested,
      signCancelRequested,
      signUpdateRequested,
      fromFatalError,
      location,
    } = this.props;
    const isMobile = isMobileDevice(navigator.userAgent);
    const isIOS = isIOSDevice(navigator.userAgent);

    if (authRequested && !fromFatalError) {
      return <Redirect to={`/authCollect${location.search}`} />;
    }

    if (isApp && signActivateRequested && !fromFatalError) {
      return <Redirect to={`/activateOccasion${location.search}`} />;
    }

    if (isApp && signCancelRequested && !fromFatalError) {
      return <Redirect to={`/cancelOccasion${location.search}`} />;
    }

    if (isApp && signUpdateRequested && !fromFatalError) {
      return <Redirect to={`/updateOccasion${location.search}`} />;
    }

    return (
      <OnTopWrapper className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Centered>
              <WelcomeMessage>
                <div>Välkommen till</div>
                <Icon src={Logo} />
              </WelcomeMessage>
              <LogInWrapper>
                <Login
                  onSuccess={this.onSuccess}
                  onError={this.onError}
                  isApp={isApp}
                  isMobile={isMobile}
                  isIOS={isIOS}
                />
                <a
                  className={classes.link}
                  href="https://eriksfonsterputs.se/bestall-via-app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bli kund hos oss
                </a>
              </LogInWrapper>
            </Centered>
          </Grid>
        </Grid>
      </OnTopWrapper>
    );
  }
}

type StateToProps = {
  authRequested: boolean;
  signActivateRequested: boolean;
  signUpdateRequested: boolean;
  signCancelRequested: boolean;
};

const WelcomeWithLocalState = withLocalState<StateToProps, WelcomeProps>(
  WelcomeComponent,
  (state) => state.user
);

export const Welcome = withStyles(styles)(withRouter(WelcomeWithLocalState));
