import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useQuery, useMutation } from 'react-apollo';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { PhoneIphone, Call, Email } from '@material-ui/icons';
import styled from 'styled-components';
import * as R from 'ramda';
import { styles } from '../../styles';
import { teal, white } from '../../utils/constants/colors';
import { appWidth } from '../../utils/constants/sizes';
import { PERSON_QUERY, Persons, PersonsVariables } from '../../Queries/queries';
import { Progress } from '../../Components/Progress';
import { Row, Column } from '../../Components/grid';
import { Header } from '../../Components/Header';
import {
  UPDATE_PERSON_MUTATION,
  UpdatePersonVariables,
  UpdatePerson,
} from '../../Queries/mutations';
import { PersonUpdateType } from '../../Queries/types';
import { lowerCaseTail } from '../../utils/textHelpers';
import { formatPhoneNumber, cleanPhoneNumber } from '../../utils/parsers';
import { Title, TextNormal } from '../../Components/Common';
import { EditProfile } from './EditProfile';
import { PrimaryButton } from '../../Components/buttons';
import CreateIcon from '@material-ui/icons/Create';

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  z-index: 1;
  height: 100px;
  background-color: ${teal};
`;

// Header text

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: ${teal};
  color: ${white};
  font-size: 21px;
  letter-spacing: 1px;
`;

export const HeaderName = styled.div`
  width: 100%;
  color: ${white};
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 80px;
  position: absolute;
`;

const ContactDetails = styled.div`
  margin-top: 15px;
`;

const ContactInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContactInfo = styled(TextNormal)`
  margin-left: 5px;
`;

const SmallLabel = styled.div`
  font-size: 12px;
  margin-top: 10px;
  font-weight: 900;
`;

const SmallLabelLeftMargin = styled(SmallLabel)`
  margin-left: 25px;
  margin-top: 5px;
`;

const ProfileContainer = styled.div`
  height: calc(100% - 140px);
  width: 100%;
  color: black;
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #fafafa;
`;

const InnerContainer = styled.div`
  padding: 0 20px 0 20px;
`;

const LeftColumn = styled(Column)``;

const ProfileSection = styled.div`
  margin-bottom: 20px;
`;

const IdentityDetails = styled.div``;

const editPosition = () => {
  const w = window as any;
  if (w.innerWidth <= appWidth) {
    return '10px';
  }
  return `${(w.innerWidth - 1024) / 2 + 10}px`;
};

const MyProfileComponent = () => {
  const [edit, isEditing] = useState(false);
  const [updating, isUpdating] = useState(false);

  const { loading, error, data } = useQuery<Persons, PersonsVariables>(
    PERSON_QUERY
  );

  const [updatePerson, { error: updateError }] = useMutation<
    UpdatePerson,
    UpdatePersonVariables
  >(UPDATE_PERSON_MUTATION, {
    refetchQueries: ['Person'],
  });

  if (error) throw error;

  var person = data ? data.person : undefined;

  const onSave = (person: PersonUpdateType) => {
    isUpdating(true);
    updatePerson({
      variables: {
        person: {
          privatePhone: cleanPhoneNumber(person.privatePhone),
          mobilePhone: cleanPhoneNumber(person.mobilePhone),
          workPhone: cleanPhoneNumber(person.workPhone),
          email: person.email,
        },
      },
    }).finally(() => isUpdating(false));
  };

  return (
    <>
      <Header onBack={edit ? () => isEditing(false) : undefined} />
      {edit && person ? (
        <EditProfile
          person={R.pick(
            ['mobilePhone', 'privatePhone', 'workPhone', 'email'],
            person
          )}
          onSave={onSave}
          isUpdating={updating}
          error={updateError}
        />
      ) : (
        <ProfileContainer>
          <Title>Dina kunduppgifter</Title>

          <InnerContainer>
            {loading || !person ? (
              <Progress message="Laddar profil..." />
            ) : (
              <ProfileSection>
                <IdentityDetails>
                  <div>
                    {person.firstName} {person.lastName}
                  </div>
                  <Row>
                    <Column size={7}>
                      <SmallLabel>Adress</SmallLabel>
                      {person.address ? (
                        <>
                          <TextNormal>
                            {lowerCaseTail(person.address)}
                          </TextNormal>
                          {person.address2 && (
                            <TextNormal>
                              {lowerCaseTail(person.address2)}
                            </TextNormal>
                          )}

                          <TextNormal>
                            {person.postalCode ? person.postalCode : '-'}{' '}
                            {person.city ? lowerCaseTail(person.city) : '-'}
                          </TextNormal>
                        </>
                      ) : (
                        <div>
                          <i>Uppgifter saknas</i>
                        </div>
                      )}
                    </Column>
                    <Column size={5}>
                      <SmallLabel>Personnummer</SmallLabel>
                      <TextNormal>{person.personalNo}</TextNormal>
                    </Column>
                  </Row>
                  <Row>
                    <Column size={7}>
                      <SmallLabel>Faktureringsadress</SmallLabel>
                      {person.invoiceAddress ? (
                        <>
                          <TextNormal>
                            {lowerCaseTail(person.invoiceAddress)}
                          </TextNormal>
                          {person.invoiceAddress2 && (
                            <div>{lowerCaseTail(person.invoiceAddress2)}</div>
                          )}

                          <TextNormal>
                            {person.invoicePostalCode
                              ? person.invoicePostalCode
                              : '-'}{' '}
                            {person.invoiceCity
                              ? lowerCaseTail(person.invoiceCity)
                              : '-'}
                          </TextNormal>
                        </>
                      ) : (
                        <div>
                          <i>Uppgifter saknas</i>
                        </div>
                      )}
                    </Column>
                    <Column size={5}>
                      <SmallLabel>Kundnr</SmallLabel>
                      <TextNormal>{person.customerId}</TextNormal>
                    </Column>
                  </Row>
                </IdentityDetails>
                <ContactDetails>
                  <Row>
                    <LeftColumn size={7}>
                      <SmallLabelLeftMargin>Hem</SmallLabelLeftMargin>
                      <ContactInfoWrapper>
                        <Call fontSize="small" color="primary" />
                        <ContactInfo>
                          {person.privatePhone
                            ? formatPhoneNumber(person.privatePhone)
                            : '-'}
                        </ContactInfo>
                      </ContactInfoWrapper>
                    </LeftColumn>
                    <Column size={5}>
                      <SmallLabelLeftMargin>Mobil</SmallLabelLeftMargin>
                      <ContactInfoWrapper>
                        <PhoneIphone fontSize="small" color="primary" />
                        <ContactInfo>
                          {person.mobilePhone
                            ? formatPhoneNumber(person.mobilePhone)
                            : '-'}
                        </ContactInfo>
                      </ContactInfoWrapper>
                    </Column>
                    <LeftColumn size={12}>
                      <SmallLabelLeftMargin>Arbete</SmallLabelLeftMargin>
                      <ContactInfoWrapper>
                        <Call fontSize="small" color="primary" />
                        <ContactInfo>
                          {person.workPhone
                            ? formatPhoneNumber(person.workPhone)
                            : '-'}
                        </ContactInfo>
                      </ContactInfoWrapper>
                    </LeftColumn>
                    <LeftColumn size={12}>
                      <SmallLabelLeftMargin>E-post</SmallLabelLeftMargin>
                      <ContactInfoWrapper>
                        <Email fontSize="small" color="primary" />
                        <ContactInfo>
                          {person.email ? person.email : '-'}
                        </ContactInfo>
                      </ContactInfoWrapper>
                    </LeftColumn>
                  </Row>
                </ContactDetails>
              </ProfileSection>
            )}
          </InnerContainer>
          <Box position="fixed" bottom="80px" right={editPosition()}>
            <PrimaryButton onClick={() => isEditing(true)}><CreateIcon />Ändra kunduppgifter</PrimaryButton>
          </Box>
        </ProfileContainer>
      )}
    </>
  );
};

export const MyProfile = withStyles(styles)(withRouter(MyProfileComponent));
