import React, { Component } from 'react';
import styled from 'styled-components';
import {
  teal,
  white,
  grayBlue,
  lightGrayBackground,
  gray,
} from '../../../../utils/constants/colors';
import { appWidth } from '../../../../utils/constants/sizes';
import * as R from 'ramda';
import { Row, Column } from '../../../../Components/grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Card } from '../../../../Components/Card';
import Collapse from '@material-ui/core/Collapse';
import Backdrop from '@material-ui/core/Backdrop';
import {
  SummaryContent,
  TotalPriceText,
  TotalPriceNumber,
} from '../SummaryContent';
import { CleaningOccasion, ServiceWithStatus } from '../../../../Queries/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { EFButton } from '../../../../Components/buttons';

const Header = styled.div`
  position: fixed;
  top: 0;
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  height: 100px;
  background-color: ${teal};
  color: ${white};
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const HeaderDescription = styled.div`
  font-size: 18px;
  padding-top: 1px;
  letter-spacing: 0.66px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderCard = styled(Card)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  height: auto;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
  background: linear-gradient(
    ${(props: { cardIsCollapsed?: boolean }) =>
      props.cardIsCollapsed === true ? lightGrayBackground : white},
    ${(props: { cardIsCollapsed?: boolean }) =>
        props.cardIsCollapsed === true ? lightGrayBackground : white}
      60px,
    ${white} 10%,
    ${white} 90%
  );
`;

const TotalPriceContainer = styled.div`
  display: flex;
  line-height: 20px;
  justify-content: space-around;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const TotalPriceLabel = styled.div`
  color: ${grayBlue};
  font-size: 12px;
  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
  margin-right: ${(props: { marginRight?: boolean }) =>
    props.marginRight ? '10px' : 0};

  padding-top: 3px;
  letter-spacing: 0.5;
`;

const DialogButtonWrapper = styled.div`
  width: 100%;
`;

const DialogContent = styled.div`
  padding: 10px;
  padding-bottom: 20px;
`;

const DialogMessageWrapper = styled.div`
  min-height: 160px;
  padding: 20px;
`;

const DialogMessage = styled.div`
  font-size: 20px;
  letter-spacing: 0.618px;
  line-height: 1.3;
`;

const StyledDialog = withStyles({
  paper: {
    margin: 15,
    boxShadow: 'none',
  },
})(Dialog);

const TotalPriceTextMargin = styled(TotalPriceText)`
  margin-right: 10px;
`;

type ComingEventHeaderProps = RouteComponentProps & {
  selectedOccasion: CleaningOccasion | null;
  selectedServices: Array<ServiceWithStatus>;
  selectionHasChanged: boolean;
};

type ComingEventHeaderState = {
  cardIsCollapsed: boolean;
  zIndex: number;
  warningDialogIsOpen: boolean;
};

const getDateLabel = (occasion: CleaningOccasion | null) => {
  if (occasion) {
    return occasion.isTimeBooked
      ? occasion.preliminaryStartDate
      : `Periodstart v.${occasion.week}`;
  }
  return '';
};

const Costs = (
  selectedOccasion: CleaningOccasion | null,
  selectedServices: Array<ServiceWithStatus>
): [number, number, number] => {
  if (!selectedOccasion) {
    return [0, 0, 0];
  }

  const fullCost = R.sum(
    selectedServices.filter((s) => !s.missingPrice).map((s) => s.cost * s.count)
  );

  const adminService = R.find((s) => s.serviceId === 28, selectedServices);

  const workCost =
    fullCost - (adminService && fullCost > 0 ? adminService.cost : 0);
  const costAfterTaxReduction =
    selectedOccasion.taxReductionDevider === 1
      ? fullCost
      : fullCost - Math.floor(workCost / selectedOccasion.taxReductionDevider);

  return [fullCost, costAfterTaxReduction, workCost];
};

const TotalPrice = (props: { costs: [number, number] }) => {
  const [cost, taxReductionCost] = props.costs;
  if (taxReductionCost > 0) {
    return (
      <TotalPriceContainer>
        <TotalPriceContainer>
          <TotalPriceLabel marginRight>Totalt pris:</TotalPriceLabel>
          <TotalPriceTextMargin taxReductionPrice={false}>
            <TotalPriceNumber>{cost}</TotalPriceNumber>
            kr
          </TotalPriceTextMargin>
        </TotalPriceContainer>
        <TotalPriceContainer>
          <TotalPriceLabel marginRight>Efter RUT:</TotalPriceLabel>
          <TotalPriceTextMargin taxReductionPrice={true}>
            <TotalPriceNumber>{taxReductionCost}</TotalPriceNumber>
            kr
          </TotalPriceTextMargin>
        </TotalPriceContainer>
      </TotalPriceContainer>
    );
  }
  return (
    <PriceContainer>
      <TotalPriceLabel marginRight>Totalt pris:</TotalPriceLabel>
      <TotalPriceText>
        <TotalPriceNumber>{cost}</TotalPriceNumber>
        kr
      </TotalPriceText>
    </PriceContainer>
  );
};

class ComingEventHeader extends Component<
  ComingEventHeaderProps,
  ComingEventHeaderState
> {
  // Init
  zIndexToggleTimeout: any;

  constructor(props: ComingEventHeaderProps) {
    super(props);

    this.state = {
      cardIsCollapsed: false,
      zIndex: -1,
      warningDialogIsOpen: false,
    };
  }

  // Lifecycles

  componentDidUpdate(
    _: ComingEventHeaderProps,
    prevState: ComingEventHeaderState
  ) {
    if (prevState.cardIsCollapsed !== this.state.cardIsCollapsed) {
      this.toggleZindex(prevState.cardIsCollapsed, this.state.cardIsCollapsed);
    }
  }

  componentWillUnmount() {
    if (this.zIndexToggleTimeout !== null) {
      clearTimeout(this.zIndexToggleTimeout);
    }
  }

  // Methods

  toggleZindex = (
    prevCardCollapsed: boolean,
    currentCardCollapsed: boolean
  ) => {
    if (prevCardCollapsed === false && currentCardCollapsed === true) {
      this.setState({ zIndex: 1 });
    }

    if (prevCardCollapsed === true && currentCardCollapsed === false) {
      this.zIndexToggleTimeout = setTimeout(() => {
        this.setState({ zIndex: -1 }, () => {
          this.zIndexToggleTimeout = null;
        });
      }, 250);
    }
  };

  navigateOrOpenDialog = () => {
    const { selectionHasChanged, history } = this.props;
    if (selectionHasChanged) {
      this.setState({ warningDialogIsOpen: true });
    } else {
      history.push(`/timeline${history.location.search}`);
    }
  };

  // Render
  render() {
    const { selectedOccasion, selectedServices, history } = this.props;
    const { cardIsCollapsed, zIndex, warningDialogIsOpen } = this.state;

    const costs = Costs(selectedOccasion, selectedServices);
    const costsWithoutWorkCost = [costs[0], costs[1]] as [number, number];

    return (
      <div>
        <Backdrop
          open={cardIsCollapsed}
          style={{ zIndex }}
          onClick={() => {
            this.setState({ cardIsCollapsed: false });
          }}
        />
        <Header>
          <Row>
            <Column size={2}>
              <Center>
                <IconButton onClick={this.navigateOrOpenDialog}>
                  <ArrowBack color="inherit" style={{ color: white }} />
                </IconButton>
              </Center>
            </Column>
            <Column size={8}>
              <Center>
                <HeaderDescription>
                  Kommande putstillfälle {selectedOccasion!.period}
                </HeaderDescription>
              </Center>
              <Center>
                <HeaderDescription>
                  {getDateLabel(selectedOccasion)}
                </HeaderDescription>
              </Center>
            </Column>
            <Column size={2} />
          </Row>
          <Row>
            <Column size={0.5} />
            <Column size={11}>
              <HeaderCard cardIsCollapsed={cardIsCollapsed}>
                <Row style={{ height: 30 }}>
                  <Column size={12}>
                    <TotalPrice costs={costsWithoutWorkCost} />
                  </Column>
                </Row>

                <Collapse
                  in={cardIsCollapsed}
                  timeout="auto"
                  unmountOnExit
                  style={{
                    marginLeft: -15,
                    marginRight: -15,
                  }}
                >
                  <SummaryContent
                    selectedOccasion={selectedOccasion}
                    services={Array.from(selectedServices)}
                    costs={costs}
                    comingEvent
                  />
                </Collapse>
              </HeaderCard>
            </Column>
            <Column size={0.5} />
          </Row>
          <StyledDialog
            open={warningDialogIsOpen}
            onClose={() => {
              this.setState({ warningDialogIsOpen: false });
            }}
          >
            <DialogContent>
              <DialogMessageWrapper>
                <DialogMessage>
                  Du har osparade ändringar. Är du säker på att du vill gå
                  tillbaka?
                </DialogMessage>
              </DialogMessageWrapper>
              <Row>
                <Column size={6}>
                  <DialogButtonWrapper style={{ paddingRight: 5 }}>
                    <EFButton
                      color={gray}
                      onClick={() => {
                        this.setState({ warningDialogIsOpen: false });
                      }}
                    >
                      AVBRYT
                    </EFButton>
                  </DialogButtonWrapper>
                </Column>
                <Column size={6}>
                  <DialogButtonWrapper style={{ paddingLeft: 5 }}>
                    <EFButton
                      color={teal}
                      onClick={() =>
                        history.push(`/timeline${history.location.search}`)
                      }
                    >
                      JA
                    </EFButton>
                  </DialogButtonWrapper>
                </Column>
              </Row>
            </DialogContent>
          </StyledDialog>
        </Header>
      </div>
    );
  }
}

export default withRouter(ComingEventHeader);
