import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: rgba(96, 165, 97, 1);
  color: white;
  width: 70%;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  animation: save-flash 1.5s infinite alternate;
  z-index: 999;

  @keyframes save-flash {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

type SaveFlashProps = {
  show: boolean;
  message: string;
};

type SaveFlashState = {
  open: boolean;
};

export class SaveFlash extends Component<SaveFlashProps, SaveFlashState> {
  constructor(props: SaveFlashProps) {
    super(props);
    this.state = { open: props.show };
  }

  componentDidUpdate(prevProps: SaveFlashProps) {
    if (this.props.show != prevProps.show) {
      this.setState({ open: this.props.show });

      setTimeout(() => this.setState({ open: false }), 3000);
    }
  }

  render() {
    return this.state.open && <Container>{this.props.message}</Container>;
  }
}
