import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { PrimaryButton } from '../Components/buttons';
import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from '../styles';
import { ApolloError } from 'apollo-boost';
import { parseError } from '../utils/parsers';

const LoginButtonWrapper = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
`;

const TextWrapper = styled.div`
  margin-top: 200px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

type ErrorProps = RouteComponentProps &
  WithStyles<typeof styles> & {
    error: ApolloError | string | null;
    route?: string;
  };

export const Error = withStyles(styles)(
  withRouter((props: ErrorProps) => {
    const { classes, error, location } = props;
    return (
      <div className={classes.root}>
        <TextWrapper>
          <h3>Ett fel har uppstått</h3>
          <div>
            {parseError(
              location.state && location.state.errorMessage
                ? location.state.errorMessage
                : error
            )}
          </div>
        </TextWrapper>
        <LoginButtonWrapper>
          <PrimaryButton
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = `/logout${props.location.search}`;
            }}
          >
            Försök igen
          </PrimaryButton>
        </LoginButtonWrapper>
      </div>
    );
  })
);
