import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { styles } from '../../styles';
import { Header } from '../../Components/Header';
import { svgGray } from '../../utils/constants/colors';
import { PrimaryButton } from '../../Components/buttons';
import { useQuery } from 'react-apollo';
import { SinglePost, SINGLE_POST_QUERY } from '../../Queries/queries';
import { Progress } from '../../Components/Progress';

const NewsItemContainer = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  height: calc(100% - 140px);
  width: 100%;
  background: white;
`;

const CardContainer = styled.div`
  width: 100%;
  padding: 20px 20px 100px 20px;
  @media screen and (min-width: 768px) {
    padding: 40px 40px 100px 40px;
  }
`;

const Headline = styled.span`
  display: flex;
  justify-content: space-between;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-size: 26px;
`;

const NewsItemImage = styled.div`
  height: 200px;
  @media screen and (min-width: 768px) {
    height: 400px;
  }
  background-image: url(${(props: { bg: string }) => props.bg});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  display: ${(props: { bg: string }) =>
    props.bg ? 'block' : 'none'}; /* Do not display in case of empty */
`;

const DateDiv = styled.div`
  color: ${svgGray};
  font-size: 10px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: black;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 900;
  line-height: 30px;
`;

const Body = styled.div`
  color: #333;
  margin: 10px 0 20px 0;
  line-height: 1.8em;
  figure,
  iframe {
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    margin: 20px 0;
  }
  figure[style] {
    max-width: 100% !important; 
  }
  img {
    max-height: 250px !important;
    max-width: 100% !important;
    width: auto;
    height: auto;
  }
`;

type NewsItemProps = RouteComponentProps & {
  postId: string;
};

export const NewsItemDetailsView = (props: NewsItemProps) => {
  
  const { loading, error, data } = useQuery<SinglePost>(SINGLE_POST_QUERY, {
    variables: { postId: props.postId },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    throw error;
  }

  const getMediaItem = () => {
    let img: string = '';
    if (data != undefined && data.singlePost != null) {
      img = data!.singlePost.featuredMediaURL;
    }
    return img;
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <>
      <Header onBack={goBack} />
      <NewsItemContainer>
        {loading || !data ? (
          <Progress message="Laddar nyhet..." />
        ) : !data.singlePost ? (
          <Headline>Hittade inte nyheten</Headline>
        ) : (
          <>
            <NewsItemImage bg={getMediaItem()} />
            <CardContainer>
              <DateDiv>
                {new Date(data.singlePost.date).toLocaleDateString('sv-SE')}
              </DateDiv>
              <Title>{data.singlePost.title}</Title>
              <Body>{ReactHtmlParser(data.singlePost.content)}</Body>
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={goBack}
              >
                Tillbaka
              </PrimaryButton>
            </CardContainer>
          </>
        )}
      </NewsItemContainer>
    </>
  );
};

export const NewsItem = withStyles(styles)(withRouter(NewsItemDetailsView));
