import React, { useState } from 'react';
import styled from 'styled-components';
import {
  mapServicesToUpdate,
  ServiceWithStatus,
  CleaningOccasion,
  CleaningObject,
} from '../../../../Queries/types';
import {
  SET_COLLECT_MUTATION,
  SetCollect,
  SetCollectVariables,
} from '../../../../Queries/mutations';
import { GreenBankIdButton } from '../../../../Components/buttons';
import { useMutation } from 'react-apollo';
import { ConfirmServicesDialog } from '../../../../Components/ConfirmServicesDialog';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export const ButtonWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
`;

type SaveSubscriptionProps = RouteComponentProps & {
  saveButtonActive: boolean;
  originalServices: Array<ServiceWithStatus>;
  selectedCleaningObject: CleaningObject | undefined;
  selectedOccasion: CleaningOccasion;
  allServices: Array<ServiceWithStatus>;
  setSaveSuscirption: (
    saved: boolean,
    originalSelectedServices: Array<ServiceWithStatus> | null,
    continouation: (() => void) | null
  ) => void;
  onError: (errorMessage: string) => void;
};

const SaveSubscription = (props: SaveSubscriptionProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const getSignMessage = () => {
    const {
      allServices: selectedServices,
      selectedOccasion,
      selectedCleaningObject,
    } = props;

    const services = selectedServices.filter((service) => {
      return service.selected && service.visibleInApp;
    });
    const priced = services.filter((s) => !s.missingPrice).map((s) => s.name);
    const notPriced = services.filter((s) => s.missingPrice).map((s) => s.name);

    const notPricedMessage =
      notPriced.length > 0
        ? `\nJag väljer också att inkludera följande icke prisatta tjänster:\n ${notPriced.join(
            '\n '
          )}`
        : '';
    const message = `Jag untertecknar härmed att jag vill ändra putstillfälle ${
      selectedOccasion.period
    } på mitt abonnemang för ${
      selectedCleaningObject!.address
    } till att innehålla följande tillvalstjänster:\n ${priced.join(
      '\n '
    )} ${notPricedMessage}`;

    return message;
  };

  const {
    selectedOccasion,
    originalServices,
    allServices,
    setSaveSuscirption,
    saveButtonActive,
  } = props;

  const { period } = selectedOccasion;
  const additionalServices = props.allServices.filter(
    (s) => s.selected && s.visibleInApp
  );

  const saveServicesForUpdate = () => {
    return setCollect({
      variables: {
        session: null,
        signUpdateRequested: false,
        servicesForUpdate: mapServicesToUpdate(originalServices, allServices),
      },
    });
  };

  const onClick = () => {
    setDialogOpen(false);

    setSaveSuscirption(false, allServices, () => {
      saveServicesForUpdate().then(() =>
        props.history.push({
          pathname: '/updateOccasion',
          search: props.history.location.search,
          state: { signMessage: getSignMessage() },
        })
      );
    });
  };

  return (
    <ButtonWrapper>
      <GreenBankIdButton
        fullWidth={true}
        disabled={!saveButtonActive}
        variant="contained"
        color="primary"
        onClick={() => {
          getSignMessage();
          if (additionalServices.length > 0) {
            setDialogOpen(true);
          } else {
            onClick();
          }
        }}
      >
        SPARA
      </GreenBankIdButton>

      <ConfirmServicesDialog
        open={dialogOpen}
        needsSign={true}
        period={period}
        services={props.allServices.filter((s) => s.selected)}
        onAbort={() => setDialogOpen(false)}
        onConfirm={onClick}
      />
    </ButtonWrapper>
  );
};

export default withRouter(SaveSubscription);
