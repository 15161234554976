import React, { Component } from 'react';
import styled from 'styled-components';
import { Header } from '../../Components/Header';
import { Call, Email, Language, InfoRounded } from '@material-ui/icons';
import { teal } from '../../utils/constants/colors';
import { Title, SubHeader, SubLine, Ingress } from '../../Components/Common';

const ProfileContainer = styled.div`
  height: calc(100% - 140px);
  width: 100%;
  color: black;
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ContactType = styled.div`
  margin: 0 0 20px 0;
`;

const ProfileSection = styled.div`
  padding: 0 20px 20px 20px;
`;

const IndentedDetails = styled.div`
  margin-left: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  margin-left: 5px;
`;

const StyledAnchor = styled.a`
  color: ${teal};
`;

const MAX_TRIES = 600;
const waitUpdate = (selector: string, setter: (el: any) => void) => {
  let tries = 0;
  const checkExists = setInterval(() => {
    const elements = document.getElementsByClassName(selector);
    if (elements.length > 0) {
      setter(elements[0]);
      clearInterval(checkExists);
    } else if (tries > MAX_TRIES) {
      clearInterval(checkExists);
    } else {
      tries++;
    }
  });
};

export class ContactInfo extends Component {
  componentDidMount() {
    this.initEbbot();
  }

  componentWillUnmount() {
    waitUpdate(
      'ebbot-open-button-wrapper',
      (el) => (el.style.display = 'none')
    );
  }

  initEbbot() {
    if (window.innerWidth > 1024) {
      return;
    }

    if (!(window as any).Ebbot) {
      (window as any).Ebbot = {
        botId: '178e57ff-86c7-40e3-b484-48f03db71d53',
      };
    }

    const e = 'init-js-widget';
    if (!document.getElementById(e)) {
      let i = document.createElement('script');
      i.id = e;
      i.src =
        'https://ebbot-v2.storage.googleapis.com/ebbot-web/init.js?t=' +
        Math.random();

      document.querySelector('body')!.appendChild(i);

      waitUpdate('ebbot-open-button-wrapper', (el) => {
        el.style.bottom = '100px';
      });
    } else {
      waitUpdate(
        'ebbot-open-button-wrapper',
        (el) => (el.style.display = 'flex')
      );
      waitUpdate('ebbot-open-button-wrapper', (el) => {
        el.style.bottom = '100px';
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <ProfileContainer>
          <Title>Kontakt</Title>
          <ProfileSection>
            <IndentedDetails>
              <Ingress>
                <InfoRounded
                  color="primary"
                  height="1em"
                  width="1em"
                  fontSize="small"
                ></InfoRounded>
                Låt vår chattbot Erik hjälpa dig om du har några frågor eller
                funderingar. Om du hellre vill komma i direkt kontakt med oss
                finns fler alternativ här nedan.
              </Ingress>
              <ContactType>
                <SubHeader>
                  Webbplats<SubLine>Om du vill söka information själv.</SubLine>
                </SubHeader>
                <InfoWrapper>
                  <Language fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor
                      target="_blank"
                      href="https://eriksfonsterputs.se"
                    >
                      https://eriksfonsterputs.se
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
              </ContactType>
              <ContactType>
                <SubHeader>
                  E-post
                  <SubLine>
                    Om ditt ärende inte är akut, men testa gärna chatten först.
                  </SubLine>
                </SubHeader>
                <InfoWrapper>
                  <Email fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor href="mailto:kundtjanst@eriksfonsterputs.se">
                      kundtjanst@eriksfonsterputs.se
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
              </ContactType>
              <ContactType>
                <SubHeader>
                  Telefon (09-18)
                  <SubLine>
                    Om du inte kan lösa ditt ärende med ovanstående.
                  </SubLine>
                </SubHeader>
                <InfoWrapper>
                  <Call fontSize="small" color="primary" />
                  <Info>
                    <StyledAnchor href="tel:0771424242">
                      0771-42 42 42
                    </StyledAnchor>
                  </Info>
                </InfoWrapper>
              </ContactType>
            </IndentedDetails>
          </ProfileSection>
        </ProfileContainer>
      </>
    );
  }
}
