import React from 'react';
import styled from 'styled-components';
import { styles } from '../styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

const CenteredWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Message = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

type FullScreenProgressProps = WithStyles<typeof styles> & {
  message: string | null;
};

const progress = (props: FullScreenProgressProps) => {
  const { message } = props;
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <CenteredWrapper>
          <Message>{message}</Message>
          <CircularProgress size={75} />
        </CenteredWrapper>
      </Grid>
    </Grid>
  );
};

export const Progress = withStyles(styles)(progress);
