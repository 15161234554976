export class Throttle {

    timeout: number;
    func: any;
    mils: number;

    constructor(fn: any, ms: number) {
        
        this.func = fn;
        this.mils = ms;

        if(this.func == undefined || this.mils == undefined) {
            console.error('callback function and the timeout must be supplied')
        }

        this.timeout = setTimeout(this.exec, this.mils)
    }
    
    exec = () => {
        this.func.apply()
    }
    
    clear = () => {
        this.timeout == undefined ? null : clearTimeout(this.timeout);
    }
    
    clearTimeOut = () => {
        this.clear();
    }
}