import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

type CloseButtonProps = {
  onClick: () => void;
};
export const CloseButton = (props: CloseButtonProps) => {
  return (
    <IconButton onClick={props.onClick}>
      <Close color="inherit" />
    </IconButton>
  );
};
