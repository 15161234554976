import React from 'react';
import * as R from 'ramda';
import { TimelineHeader } from './TimelineHeader';
import EventList from './EventList';
import styled from 'styled-components';
import {
  CLEANING_OBJECTS_QUERY,
  CleaningObjectVariables,
  CleaningObjects,
} from '../../Queries/queries';
import { CleaningObject } from '../../Queries/types';
import { RouteComponentProps, withRouter } from 'react-router';
import ResetCollect from '../../Components/ResetCollect';
import { getCurrentYear } from '../../utils/parsers';
import { withLocalState } from '../../utils/withLocalState';
import { Link } from 'react-router-dom';
import { appWidth } from '../../utils/constants/sizes';
import { useQuery } from 'react-apollo';
import { Progress } from '../../Components/Progress';

type TimelineProps = RouteComponentProps & {
  onError: (error: string) => void;
  selectedLocationId: number | null;
};

const ProgressWrapper = styled.div`
  width: 100%;
  margin: 110px auto 0 auto;
`;

const NoActiveSubscriptions = styled.div`
  position: fixed;
  top: 50%;
  width: ${appWidth}px;
  @media screen and (max-width: 1024px) {
    width: 90%;
    left: 5%;
  }
  text-align: center;
  left: calc(${appWidth / 2})
`;

const getSelectedObject = (
  cleaningObjects: Array<CleaningObject>,
  selectedLocationId: number | null
) => {
  var selected = R.find((l) => l.id === selectedLocationId, cleaningObjects);
  return selected ? selected : R.head(cleaningObjects);
};

const Timeline = (props: TimelineProps) => {
  const thisYear = getCurrentYear();
  const { loading, error, data, refetch } = useQuery<
    CleaningObjects,
    CleaningObjectVariables
  >(CLEANING_OBJECTS_QUERY, {
    variables: { years: [thisYear] },
    fetchPolicy: 'cache-and-network',
  });

  const timelineWithQuery = () => {
    if (error) throw error;

    const cleaningObjects = data ? data.cleaningObjects : [];
    const selectedObject = getSelectedObject(
      cleaningObjects,
      props.selectedLocationId
    );

    setTimeout(function () {
      var nextOccassionElement = document.getElementById('NEXT_OCCASION');

      if (nextOccassionElement) {
        var pos = nextOccassionElement.style.position;
        var top = nextOccassionElement.style.top;
        nextOccassionElement.style.position = 'relative';
        nextOccassionElement.style.top = '-200px';
        nextOccassionElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        nextOccassionElement.style.top = top;
        nextOccassionElement.style.position = pos;
      }
    });
    return (
      <>
        <TimelineHeader
          selectedObjectId={selectedObject ? selectedObject!.id : -1}
          cleaningObjects={cleaningObjects}
        />
        {loading ? (
          <ProgressWrapper>
            <Progress message="Laddar..." />
          </ProgressWrapper>
        ) : cleaningObjects.length > 0 ? (
          <EventList
            reload={() => refetch()}
            cleaningObject={selectedObject}
            onError={props.onError}
          />
        ) : (
          <NoActiveSubscriptions>
            Du har inga aktiva abonnemang, vänligen{' '}
            <Link to="/contactInfo">kontakta kundtjänst</Link> för hjälp med
            bokning.
          </NoActiveSubscriptions>
        )}
      </>
    );
  };

  return <ResetCollect>{timelineWithQuery()}</ResetCollect>;
};

const TimelineWithState = withLocalState<
  { selectedLocationId: number | null },
  TimelineProps
>(Timeline, (state) => ({ selectedLocationId: state.app.selectedLocationId }));

export default withRouter(TimelineWithState);
