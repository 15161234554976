import React, { Component } from 'react';
import { withLocalState } from '../utils/withLocalState';
import {
  SET_COLLECT_MUTATION,
  SetCollect,
  SetCollectVariables
} from '../Queries/mutations';
import { useMutation } from 'react-apollo';

type CallResetState = {
  authRequested: boolean;
};

type CallResetProps = {
  reset: () => void;
  authRequested: boolean;
};

class CallReset extends Component<CallResetProps, CallResetState> {
  componentDidMount() {
    this.props.reset();
  }

  render() {
    return this.props.children;
  }
}

const CallResetWithLocalState = withLocalState<CallResetState, CallResetProps>(
  CallReset,
  state => ({ authRequested: state.user.authRequested })
);

type ResetCollectStateProps = {
  session: string | null;
};

type ResetCollectProps = {
  session: string | null;
  resetSession?: boolean;
};

const ResetCollect: React.StatelessComponent<ResetCollectProps> = props => {
  const [setCollect] = useMutation<SetCollect, SetCollectVariables>(
    SET_COLLECT_MUTATION
  );

  const reset = () =>
    setCollect({
      variables: {
        session: props.resetSession ? null : props.session,
        authRequested: false,
        signUpdateRequested: false,
        signCancelRequested: false,
        signActivateRequested: false,
        servicesForUpdate: []
      }
    });
  return (
    <CallResetWithLocalState reset={reset}>
      {props.children}
    </CallResetWithLocalState>
  );
};

export default withLocalState<ResetCollectStateProps, ResetCollectProps>(
  ResetCollect,
  state => ({ session: state.user.session })
);
