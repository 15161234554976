import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type ColumnProps = {
  size: number;
};

export const Column = styled.div`
  flex-basis: ${(props: ColumnProps) => `${(100 * props.size) / 12}%`};
  max-width: ${(props: ColumnProps) => `${(100 * props.size) / 12}%`};
`;
