import React from 'react';
import styled from 'styled-components';
import { Row, Column } from '../../../Components/grid';
import {
  lineGray,
  white,
  green,
  gradientBlue
} from '../../../utils/constants/colors';
import { CleaningOccasion, Service } from '../../../Queries/types';
import { Link } from 'react-router-dom';
import { getCategories } from '../../../utils/parsers';
import { PrimaryButton } from '../../../Components/buttons';
import { Price } from '../../../Components/Price';

const ServiceLabel = styled.div`
  padding-left: 10px;
  padding-bottom: 1px;
`;

const ServicePrice = styled.div`
  text-align: right;
  padding-bottom: 1px;
`;

type ServiceRowProps = {
  service: { name: string; freeText: string | null; cost: number; missingPrice: boolean };
};

const ServiceRow = ({ service }: ServiceRowProps) => {
  return (
    <Row>
      <Column size={8}>
        <ServiceLabel>{service.name} {service.freeText && <i> - {service.freeText}</i>}</ServiceLabel>
      </Column>
      <Column size={4}>
        <ServicePrice>
          {service.missingPrice ? (
            <span>-</span>
          ) : (
            <Price value={service.cost} decimals={2} />
          )}
        </ServicePrice>
      </Column>
    </Row>
  );
};

const TopShadow = styled.div`
  height: 6px;
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.11);
  margin-top: 14px;
`;

const ContentWrapper = styled.div`
  color: black;
`;

const Container = styled.div`
  height: calc(
    100vh - 145px -
      ${(props: { comingEvent?: boolean }) => (props.comingEvent ? 83 : 0)}px
  );
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Content = styled.div`
  height: calc(
    100vh - 145px -
      ${(props: { comingEvent?: boolean }) => (props.comingEvent ? 83 : 0)}px
  );
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
  margin-top: -2px;
`;

const BottomPadding = styled.div`
  padding-top: 25px;
  height: 80px;
  border: 2px solid transparent;
`;

const CategoryHeader = styled.div`
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 900;
`;

const Divider = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: -30px;
  margin-right: -29px;
  border-bottom: 1px solid ${lineGray};
`;

const TotalPriceLabel = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TotalPriceValue = styled.div`
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TotalPriceWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Headline = styled.span`
  font-size: 26px;
  font-family: Lato Black, Helvetica, Arial, sans-serif;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const TotalPriceText = styled.div`
  font-size: 16px;
  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
  line-height: 24px;
  width: fit-content;
  float: right;
  border-radius: 15px;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  color: ${white};

  background-color: ${(props: { taxReductionPrice?: boolean }) =>
    props.taxReductionPrice ? green : gradientBlue};
`;

export const TotalPriceNumber = styled.span`
  letter-spacing: 0.75px;
  font-size: 16px;
  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
`;

const InformationContent = styled.div`
  text-align: center;
  padding-top: 30px;
  color: lack;
  padding-bottom: 50px;
`;

type CollapsedCardContentProps = {
  selectedOccasion: CleaningOccasion | null;
  services: Array<Service>;
  costs: [number, number, number];
  goToInvoice?: (invoiceNumber: string) => void;
  comingEvent?: boolean;
};

const Category = ({ category }: { category: [string, Array<Service>] }) => {
  const [categoryName, services] = category;
  return (
    <div style={{ paddingBottom: 5 }}>
      <CategoryHeader>{categoryName}</CategoryHeader>
      {services
        .filter(s => s.count > 0)
        .map((service, index) => {
          return (
            <div key={index}>
              <ServiceRow key={index} service={service} />
            </div>
          );
        })}
    </div>
  );
};

export const SummaryContent = ({
  selectedOccasion,
  services,
  costs,
  goToInvoice,
  comingEvent
}: CollapsedCardContentProps) => {
  const [cost, taxReductionCost, workCost] = costs;

  const categories = getCategories(services);

  return (
    <Container comingEvent={comingEvent}>
      {comingEvent && <TopShadow />}
      <ContentWrapper>
        <Content comingEvent={comingEvent}>
          {selectedOccasion &&
            !selectedOccasion.changesAllowed &&
            !selectedOccasion.finished && (
              <Headline>Påbörjad putsperiod</Headline>
            )}
          {selectedOccasion !== null &&
            categories.map((category, index) => (
              <Category category={category} key={index} />
            ))}
          <div style={{ paddingBottom: 20, paddingTop: 10 }}>
            <ServiceRow
              service={{
                name: 'Varav arbetskostnad',
                freeText: null,
                cost: workCost,
                missingPrice: false
              }}
            />
          </div>
          <Divider />
          <Row>
            <Column size={8}>
              <TotalPriceLabel>Totalt</TotalPriceLabel>
            </Column>
            <Column size={4}>
              <TotalPriceWrapper>
                <TotalPriceText taxReductionPrice={false}>
                  <TotalPriceNumber>{cost}</TotalPriceNumber>
                  kr
                </TotalPriceText>
              </TotalPriceWrapper>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <TotalPriceLabel>Avgår prel. skattereduktion</TotalPriceLabel>
            </Column>
            <Column size={4}>
              <TotalPriceValue>
                {' '}
                <Price value={-(cost - taxReductionCost)} decimals={2} />
              </TotalPriceValue>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <TotalPriceLabel>Att betala</TotalPriceLabel>
            </Column>
            <Column size={4}>
              <TotalPriceWrapper>
                <TotalPriceText taxReductionPrice={true}>
                  <TotalPriceNumber>{taxReductionCost}</TotalPriceNumber>
                  kr
                </TotalPriceText>
              </TotalPriceWrapper>
            </Column>
          </Row>

          {selectedOccasion &&
            selectedOccasion.finished &&
            selectedOccasion.invoiceNumber && (
              <>
                {goToInvoice && (
                  <Row>
                    <PrimaryButton
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        goToInvoice(selectedOccasion.invoiceNumber)
                      }
                    >
                      Visa Faktura
                    </PrimaryButton>
                  </Row>
                )}
              </>
            )}
          {selectedOccasion &&
            !selectedOccasion.changesAllowed &&
            !selectedOccasion.finished && (
              <InformationContent>
                Det är för sent att göra ändringar för detta tillfället i appen,
                vill du lägga till eller ta bort en tjänst måste du{' '}
                <Link to="/contactInfo">kontakta kundservice</Link>.
                <BottomPadding />
              </InformationContent>
            )}
          {selectedOccasion && selectedOccasion.finished && (
            <InformationContent>
              Avvikelser mot fakturan kan förekomma. <br />
              <Link to="/contactInfo">Kontakta kundtjänst</Link> vid frågor
              eller hjälp.
              <BottomPadding />
            </InformationContent>
          )}
        </Content>
      </ContentWrapper>
    </Container>
  );
};
