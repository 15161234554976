import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { lightGrayBackground } from '../../../utils/constants/colors';
import CompletedEvent from './CompletedEvent';
import ComingEvent from './ComingEvent/ComingEvent';
import { CleaningOccasion, addStatusToService } from '../../../Queries/types';
import {
  OPTIONAL_SERVICES_QUERY,
  SERVICES_FOR_OCCASION_QUERY,
  CLEANING_OBJECTS_QUERY,
  ServicesForOccasion,
  ServicesForOccasionVariables,
  OptionalServices,
  OptionalServicesVariables,
  CleaningObjects,
  CleaningObjectVariables
} from '../../../Queries/queries';
import { withLocalState } from '../../../utils/withLocalState';
import { useQuery } from 'react-apollo';

type EventInfoProps = {
  selectedOccasion: CleaningOccasion | null;
};

const DrawerContainer = styled.div`
  width: 100vw;
  height: 100%;
  background-color: ${lightGrayBackground};
`;

const CompletedEventWithQuery = ({
  selectedOccasion
}: {
  selectedOccasion: CleaningOccasion;
}) => {
  const { year, cleaningObjectId, period, workOrderId } = selectedOccasion;
  const {
    loading: servicesForOccasionLoading,
    error: servicesForOccasionError,
    data: servicesForOccasionData
  } = useQuery<ServicesForOccasion, ServicesForOccasionVariables>(
    SERVICES_FOR_OCCASION_QUERY,
    {
      variables: { year, cleaningObjectId, period, workOrderId },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (servicesForOccasionError) throw servicesForOccasionError;

  return (
    <CompletedEvent
      services={
        servicesForOccasionData
          ? servicesForOccasionData.servicesForOccasion
          : []
      }
      loading={servicesForOccasionLoading}
      noData={!servicesForOccasionData}
    />
  );
};

const ComingEventWithQueries = ({
  selectedOccasion
}: {
  selectedOccasion: CleaningOccasion;
}) => {
  const { year, cleaningObjectId, period, workOrderId } = selectedOccasion;
  const {
    loading: cleaningObjectsLoading,
    error: cleaningObjectsError,
    data: cleaningObjectsData
  } = useQuery<CleaningObjects, CleaningObjectVariables>(
    CLEANING_OBJECTS_QUERY,
    { variables: { years: [year] }, fetchPolicy: 'cache-and-network' }
  );
  const {
    loading: optionalServicesLoading,
    error: optionalServicesError,
    data: optionalServicesData,
    refetch: optionalServicesRefetch
  } = useQuery<OptionalServices, OptionalServicesVariables>(
    OPTIONAL_SERVICES_QUERY,
    { variables: { year, cleaningObjectId }, fetchPolicy: 'cache-and-network' }
  );
  const {
    loading: servicesForOccasionLoading,
    error: servicesForOccasionError,
    data: servicesForOccasionData,
    refetch: servicesForOccasionRefetch
  } = useQuery<ServicesForOccasion, ServicesForOccasionVariables>(
    SERVICES_FOR_OCCASION_QUERY,
    {
      variables: { year, cleaningObjectId, period, workOrderId },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (cleaningObjectsError) throw cleaningObjectsError;
  if (optionalServicesError) throw optionalServicesError;
  if (servicesForOccasionError) throw servicesForOccasionError;

  const selectedCleaningObject = cleaningObjectsData
    ? R.find(
        c => c.id === cleaningObjectId,
        cleaningObjectsData.cleaningObjects
      )
    : undefined;

  const availibleServices = optionalServicesData
    ? optionalServicesData.optionalServices
    : [];

  const selectedServices = servicesForOccasionData
    ? servicesForOccasionData.servicesForOccasion
    : [];

  const allServices = selectedServices
    .concat(
      availibleServices.filter(
        ser => !R.find(s => s.serviceId === ser.serviceId, selectedServices)
      )
    )
    .map(addStatusToService);

  const reload = () =>
    optionalServicesRefetch().then(() => servicesForOccasionRefetch());

  return (
    <ComingEvent
      loading={
        cleaningObjectsLoading ||
        optionalServicesLoading ||
        servicesForOccasionLoading
      }
      noData={
        !cleaningObjectsData ||
        !optionalServicesData ||
        !servicesForOccasionData
      }
      allServices={allServices}
      selectedCleaningObject={selectedCleaningObject}
      selectedOccasion={selectedOccasion}
      reload={reload}
    />
  );
};

const EventInfo = ({ selectedOccasion }: EventInfoProps) => {
  if (!selectedOccasion) {
    return null;
  }

  const completed =
    selectedOccasion.finished || selectedOccasion.occasionHasPassed;

  return (
    <DrawerContainer>
      {completed === true || !selectedOccasion.changesAllowed ? (
        <CompletedEventWithQuery selectedOccasion={selectedOccasion} />
      ) : (
        <ComingEventWithQueries selectedOccasion={selectedOccasion} />
      )}
    </DrawerContainer>
  );
};

export default withLocalState<
  { selectedOccasion: CleaningOccasion | null },
  EventInfoProps
>(EventInfo, state => ({ selectedOccasion: state.app.selectedOccasion }));
