import React from 'react';
import styled from 'styled-components';
import { Card } from '../../Components/Card';
import { green, accentBlue } from '../../utils/constants/colors';
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery } from 'react-apollo';
import {
  Invoices as InvoicesQuery,
  INVOICES_QUERY,
} from '../../Queries/queries';
import { Progress } from '../../Components/Progress';
import { Invoice } from '../../Queries/types';
import { Header } from '../../Components/Header';
import { Title } from '../../Components/Common';

const Container = styled.div`
  width: 100%;
  margin-top: 70px;
  @media screen and (min-width: 768px) {
    margin-top: 99px;
  }
  height: calc(100% - 140px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ListWrapper = styled.div`
  padding: 0 20px;
`;

const taxReductionApplied = (invoice: Invoice) =>
  invoice.taxReduction > 0 && invoice.taxReductionDecided === null;

const taxReductionDeined = (invoice: Invoice) =>
  invoice.taxReduction > 0 &&
  invoice.taxReductionDenied &&
  invoice.taxReductionDenied > 0;

type Status = 'Betald' | 'Försenad' | 'Ej betald';
type DetailedStatus = '' | 'Inväntar RUT' | 'RUT Nekad';
type StatusAndColor = [Status, DetailedStatus, string];
export const getStatusAndColor = (invoice: Invoice): StatusAndColor => {
  if (invoice.balance >= 0) {
    return ['Betald', '', green];
  } else if (
    invoice.balance < 0 &&
    Math.abs(invoice.balance) < invoice.total &&
    taxReductionApplied(invoice)
  ) {
    return ['Betald', 'Inväntar RUT', green];
  }

  const detailedStatus = taxReductionDeined(invoice) ? 'RUT Nekad' : '';

  if (new Date(invoice.dueDate) < new Date()) {
    return ['Försenad', detailedStatus, 'red'];
  }

  return ['Ej betald', detailedStatus, accentBlue];
};

const StyledCard = styled(Card)`
  height: auto;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 8px ${(props: { color: string }) => props.color} solid;
`;

export const StatusText = styled.span`
  color: ${(props: { color: string }) => props.color};
`;

export const prettyAddress = (address: string) =>
  address && address.length > 0
    ? `${address[0]}${address.substr(1).toLowerCase()}`
    : address;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RowContainerSmallText = styled(RowContainer)`
  font-size: 11px;
`;

const statusOrder = (status: Status) =>
  status === 'Försenad' ? 2 : status === 'Ej betald' ? 1 : 0;

const sortOnStatus = (invoices: Array<Invoice>) => {
  // const res = invoices
  //   .map((i, index) => {
  //     if (index === 0) {
  //       return {
  //         ...i,
  //         dueDate: '2019-12-01',
  //         balance: -i.total,
  //         taxReductionDenied: null,
  //         taxReductionDecided: null
  //       };
  //     }

  //     if (index === 1) {
  //       return {
  //         ...i,
  //         dueDate: '2019-11-25',
  //         balance: -i.total,
  //         taxReductionDenied: null,
  //         taxReductionDecided: null
  //       };
  //     }

  //     if (index === 2) {
  //       return {
  //         ...i,
  //         dueDate: '2019-11-25',
  //         balance: -(i.total / 2),
  //         taxReductionDenied: null,
  //         taxReductionDecided: null
  //       };
  //     }

  //     if (index === 3) {
  //       return {
  //         ...i,
  //         dueDate: '2019-11-25',
  //         balance: -(i.total / 2),
  //         taxReductionDenied: 10,
  //         taxReductionDecided: 100
  //       };
  //     }
  //     return i;
  //   })
  return invoices
    .map((i) => ({ ...i, status: getStatusAndColor(i) }))
    .sort((a, b) => {
      const aOrd = statusOrder(a.status[0]);
      const bOrd = statusOrder(b.status[0]);

      if (aOrd < bOrd) {
        return 1;
      }
      if (aOrd > bOrd) {
        return -1;
      }
      return 0;
    });
};

type InvoicesProps = RouteComponentProps & {};
export const InvoicesView = (props: InvoicesProps) => {
  const { loading, error, data } = useQuery<InvoicesQuery>(INVOICES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    console.log(error);
  }

  return (
    <>
      <Header />
      <Container>
        <Title>Dina fakturor</Title>
        {loading || !data ? (
          <Progress message="Laddar fakturor..." />
        ) : (
          <ListWrapper>
            {sortOnStatus(data!.invoices).map((invoice, index) => {
              const [status, detailedStatus, color] = invoice.status;
              return (
                <StyledCard
                  key={index}
                  color={color}
                  onClick={() =>
                    props.history.push(
                      `/invoices/${invoice.invoiceNumber}${props.location.search}`
                    )
                  }
                >
                  <RowContainer>
                    <span>{prettyAddress(invoice.cleaningObjectName)}</span>
                    <span>{invoice.total} kr</span>
                  </RowContainer>
                  <RowContainerSmallText>
                    <span>Fakturadatum: {invoice.invoiceDate}</span>
                    <StatusText color={color}>{status}</StatusText>
                  </RowContainerSmallText>
                  <RowContainerSmallText>
                    <span>Förfallodatum: {invoice.dueDate}</span>
                    <StatusText color={color}>{detailedStatus}</StatusText>
                  </RowContainerSmallText>
                </StyledCard>
              );
            })}
          </ListWrapper>
        )}
      </Container>
    </>
  );
};

export const Invoices = withRouter(InvoicesView);
