import React, { Component } from 'react';
import ResetCollect from '../Components/ResetCollect';
import { RouteComponentProps, withRouter } from 'react-router';
import { withLocalState } from '../utils/withLocalState';
import { UserState } from '../Queries/types';

type LogOutStateProps = {
  redirect: boolean;
};
type LogOutProps = {
  onLogout: () => void;
};

class CallLogOut extends Component<
  LogOutProps & LogOutStateProps & RouteComponentProps
> {
  componentDidMount() {
    this.props.onLogout();
  }

  render() {
    return <div />;
  }
}

const redirect = (state: UserState) => {
  return !(
    state.authRequested ||
    state.signActivateRequested ||
    state.signCancelRequested ||
    state.signUpdateRequested
  );
};

const CallLogOutWithLocalState = withRouter(
  withLocalState<
    LogOutStateProps,
    LogOutProps & LogOutStateProps & RouteComponentProps
  >(CallLogOut, (state) => ({ redirect: redirect(state.user) }))
);

export class LogOut extends Component<LogOutProps> {
  render() {
    return (
      <ResetCollect resetSession={true}>
        <CallLogOutWithLocalState onLogout={this.props.onLogout} />
      </ResetCollect>
    );
  }
}
