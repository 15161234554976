import { getCookieByName } from '../utils/cookies';
import { baseUrl } from '../endpoints';

export async function sendError(message: any, info: any = '') {
  const token = getCookieByName('token', document.cookie);
  const errorMessage = `${parseError(message)} info: ${parseError(info)}`;

  return fetch(`${baseUrl}/health/logError`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: token ? 'Bearer ' + token : ''
    },
    body: JSON.stringify({
      action: 'Error from client',
      error: errorMessage,
      clientType: 'Web'
    })
  }).catch(err => console.error(err));
}

const parseError = (error: any) => {
  let errorString = '';
  if (error instanceof Object) {
    try {
      errorString = JSON.stringify(error);
    } catch {}
  } else {
    errorString = error;
  }
  return errorString;
};
