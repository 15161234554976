import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { App } from './App';
import 'typeface-lato';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { teal, gradientBlue } from './utils/constants/colors';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, Helvetica, Arial, sans-serif'
  },
  palette: {
    primary: { main: teal },
    secondary: { main: gradientBlue }
  }
});

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
